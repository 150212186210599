export default {
  roles: 'Your roles',
  switchRoles: 'Switch roles',
  tips: 'In some cases it is not suitable to use v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if.',
  create: 'Can create',
  read: 'Can view',
  run: 'Can run',
  write: 'Can edit',
  delete: 'Can delete',
  manage: 'Can manage permissions',
  add: 'Add',
  sharingSettings: 'Sharing settings',
  justMe: 'Just me',
  everyone: 'Everyone',
  selectedUsersAndTeams: 'Selected users and teams',
  visibleTo: 'Visible to:',
  share: 'Share',
  type: 'Type',
  name: 'Name',
  permission: 'Permission',
  team: 'Team',
  user: 'User',
  namePlaceholder: 'Name of a team or a coworker',
  apply: 'Apply',
  cancel: 'Cancel',
  company: 'Entire company',
  permissionAlreadyExists: 'This permission already exists: {action} for {principal}',
  ensureAtLeastOneManage: 'Please ensure the {resource} has at least one manager permission.',
  ensureAtLeastOneManageForBulk: 'Please ensure the new permissions include at least one manager permission.',
  flowPermissions: 'Flow permissions',
  dashboardPermissions: 'Dashboard permissions',
  connectorPermissions: 'Connector permissions',
  embedPermissions: 'Embed permissions',
  internalPublic: 'Global public (for all Locoia customers)',
  visiblityDetails: 'Custom sharing settings:',
}
