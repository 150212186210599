import advancedInput from './modules/advancedInput'
import basicInput from './modules/basicInput'

export default {
  namespaced: true,
  state: () => ({
    variableInputMode: 'basic', // 'basic' | 'advanced'
  }),
  modules: {
    basicInput,
    advancedInput,
  },
  getters: {
    eligibleMode: (_, getters) => (input) => (getters['basicInput/isEligible'](input) ? 'basic' : 'advanced'),
    // Proxy for combining the metadata and variable content into one model.
    // The schema of the requestBody and frontend representation are the same.
    variableInputIsValid: (state, getters) => getters[`${state.variableInputMode}Input/isValid`],
    variableJsonString: (state, getters) => getters[`${state.variableInputMode}Input/jsonString`],
    toggleInputModeDisabled: (state, getters) => {
      // To switch the mode the current input must be valid (or empty, when switching from advanced mode).
      const currentMode = state.variableInputMode
      if (currentMode === 'advanced' && state.advancedInput.input === '') {
        return false
      }
      if (!getters.variableInputIsValid) {
        return true
      }
      const otherMode = state.variableInputMode === 'basic' ? 'advanced' : 'basic'
      return !getters[`${otherMode}Input/isEligible`](getters.variableJsonString)
    },
  },
  actions: {
    async setVariable({ commit }, { mode, input }) {
      commit(`${mode}Input/SET_INPUT`, input)
      commit('SET', { variableInputMode: mode })
    },
    async toggleVariableInputMode({ dispatch, getters }, setAdvancedMode) {
      if (getters.toggleInputModeDisabled) {
        return
      }

      const mode = setAdvancedMode ? 'advanced' : 'basic'
      dispatch('setVariable', { mode, input: getters.variableJsonString })
    },
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    SET: (state, subState) => Object.entries(subState).forEach(([key, value]) => (state[key] = value)),
  },
}
