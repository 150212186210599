export default {
  dialogCreate: 'Transformation erstellen',
  dialogUpdate: 'Transformation bearbeiten',
  notificationStatusCreat: 'Erstellt!',
  notificationStatusUpdate: 'Aktualisiert!',
  notificationMessageCreate: 'Die Transformation wurde erstellt!',
  notificationMessageUpdate: 'Transformation wurde aktualisiert!',
  validation: {
    nameRequired: 'Name ist erforderlich',
    minLength: 'Die Länge sollte mindestens 3 Zeichen betragen',
    lengthRange: 'Die Länge sollte 3 bis 240 Zeichen betragen',
  },
  closeConfirm: 'Willst Du wirklich schließen?',
}
