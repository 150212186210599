export const errorResetTime = 5000
/**
 * The delay in milliseconds before showing the el-tooltip
 */
export const SHOW_TOOLTIP_AFTER_DELAY_MS = 700

/** @enum {string} */
export const DialogStatusEnum = {
  close: 'close',
  create: 'create',
  update: 'update',
}
