<template>
  <div class="lo-layout" :class="classObj">
    <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <navbar />
      <tags-view />
      <loadable-content :is-loading="!hasRoutes" class="app-main-container">
        <app-main />
      </loadable-content>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LoadableContent from '@/ui/components/LoadableContent/index.vue'
import AppMain from './AppMain.vue'
import TagsView from './TagsView.vue'
import Navbar from './Navbar/index.vue'
import Sidebar from './Sidebar/index.vue'
import ResizeMixin from './ResizeHandler'

export default {
  name: 'Layout',
  components: {
    LoadableContent,
    AppMain,
    Navbar,
    Sidebar,
    TagsView,
  },
  mixins: [
    ResizeMixin,
  ],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      allowedRoutes: state => state.permission.routers,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      }
    },
    hasRoutes() {
      return this.allowedRoutes?.length > 0
    },
  },
  created() {
    this.buildUserLayout()
  },
  methods: {
    ...mapActions({
      buildUserLayout: 'user/ConstructUserData',
    }),
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  @import "~@/ui/styles/mixin.scss";
  @import "~@/ui/styles/variables.scss";

  .lo-layout {
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .app-main-container {
    /*84 = navbar + tags-view = 50 +34 */
    height: calc(100vh - 84px);
    width: 100%;
    position: relative;
    overflow: auto;
  }
</style>
