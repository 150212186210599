import { teamApi } from '@/ui/api/team'
import { loadAllResourcePages } from '@/ui/utils/request'

export default {
  namespaced: true,
  state: () => ({
    teams: [],
  }),
  getters: {
    getTeams: (state) => state.teams,
  },
  actions: {
    async fetchTeams({ commit }) {
      const items = []
      const addItems = (newItems) => items.push(...newItems)
      await loadAllResourcePages(teamApi.list, addItems)
      commit('SET_TEAMS', items)
    },
  },
  mutations: {
    SET_TEAMS(state, teams) {
      state.teams = teams
    },
  },
}
