import * as R from 'ramda'

export const isObject = R.both(R.is(Object), R.complement(R.is(Array)))

const trimArray = (array) => array.map(trimEverything)
const trimObject = (object) => R.fromPairs(R.toPairs(object).map(trimEverything))
export const trimEverything = R.cond([
  [R.isNil, R.identity],
  [isObject, trimObject],
  [R.is(Array), trimArray],
  [R.is(String), R.trim],
  [R.T, R.identity],
])
