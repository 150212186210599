import axiosAuthRefresh from 'axios-auth-refresh'
import debounce from 'lodash.debounce'
import { ElNotification } from 'element-plus'
import store from '@/ui/store'
import service from '@/ui/utils/request'
import { errorResetTime } from '@/ui/consts/global'

const ThrottledNotification = debounce(
  (options) => ElNotification(options),
  errorResetTime,
  { leading: true, trailing: false },
)

// request authorization interceptor, add bearer token to each request
service.interceptors.request.use(
  (config) => {
    if (store.getters['user/token']) {
      config.headers.authorization = `Bearer ${store.getters['user/token']}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

/*
 Looks like axios-auth-refresh is not properly built for ESM, default import
 returns the module itself instead of its default export. The default export
 is available via .default property.
*/
// response authorization interceptor, if a request fails with 401, try to refresh our user data and resend the request
axiosAuthRefresh.default(service, async () => {
  await store.dispatch('user/RefreshAccessToken', { skipAuthRefresh: true })
}, {
  statusCodes: [401],
})

// response error notification interceptor
service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.config?.skipErrorAlert) return Promise.reject(error)

    displayErrorNotification({
      hasResponse: !!error.response,
      status: error.response?.status,
      error: error.message,
      message: error.response?.data?.message,
    })

    return Promise.reject(error)
  },
)

const networkError = 'Network Error'

const displayErrorNotification = (context) => {
  const { hasResponse, status, error } = context

  const title = createErrorMessage(context)
  const body = 'If it persists on retry, please contact support!'

  if (!hasResponse || status === 500 || status === 401) {
    ThrottledNotification({
      title,
      message: body,
      type: 'error',
      duration: 5 * 1000,
    })
  } else if (status === 0 || error === networkError) {
    ThrottledNotification({
      title,
      message: body,
      type: 'info',
      duration: 5 * 1000,
    })
  } else if (error === 'Operation canceled by the user.') {
    // Nothing to do, not an error
  } else {
    // We don't want to throttle generic errors, that's why we use Notification directly here
    ElNotification({
      title,
      message: body,
      type: 'error',
      duration: 5 * 1000,
    })
  }
}

const createErrorMessage = (context) => {
  const {
    hasResponse, message, status, error,
  } = context

  if (!hasResponse) {
    return 'Undefined server error'
  }
  if (status === 0 || error === networkError) {
    return 'Network Error'
  }
  if (status === 500) {
    return 'Internal server error (500)'
  }
  return message || `Error status: ${status}`
}
