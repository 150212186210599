export default {
  action: {
    actionDeleted: 'The action previously selected for this step must have been deleted',
    actionDoesNotExist: 'Action does not exists',
    run: 'Run flow step',
    show_last_node_run: 'Show last flow run',
    create_auth: 'Create a new ConnectorAuth for this Connector',
    add_auth_instruction: 'Click "Add" to create a new connector auth, or type a dynamic authentication id',
    // Boolean
    comparison: 'Make boolean-like comparison',
    // Delay Helper
    delaying: 'Delay flow execution',
    // Data Warehouse Helper
    insert_or_update: 'Insert or update data',
    retrieve_from: 'Retrieve data',
    // File Storage
    publish: 'Upload and publish files publicly accessible',
    store_private_file: 'Store a file privately for later use in flows',
    retrieve_private_file: 'Retrieve stored file for use in flows',
    // JSON Helper
    read_json: 'Download and parse JSON file',
    write_json: 'Store a JSON for later use in a flow',
    // Multi flow trigger
    trigger_flows: 'Trigger multiple flows',
    // CSV
    readcsv: 'Read CSV file from URL location',
    writecsv: 'Create CSV file',
    parsecsv: 'Generate CSV from CSV text string',
    // Looper
    iterate_while: 'Iterate While Loop',
    iterate_over: 'Iterate Over Loop',
    run_all_iterations: 'Run all iterations',
    run_iteration: 'Run step with iteration:',
    set_index: 'Set index',
    specify_iteration_index: 'You may also type a specific iteration index directly',
    // FTP
    read_file: 'Read a file',
    upload_file: 'Upload a file',
    delete_file: 'Delete file',
    list_directory: 'List files and directories',
    move_file: 'Move a file',
    // Rest
    rest_client_get: 'GET request',
    rest_client_post: 'POST request',
    rest_client_put: 'PUT request',
    rest_client_patch: 'PATCH request',
    rest_client_delete: 'DELETE request',
    // XML
    dict_to_xml: 'Convert dictionary to XML',
    xml_to_dict: 'Convert XML to dictionary',
    // Dict
    replace_field: 'Replace field in list or dictionary',
    add_field_to_dict: 'Add string to dictionary',
    filter_list: 'Filter a list',
    append_field_to_list: 'Add string to list',
    set_variables: 'Define variables for later use e.g. JSON',
    flatten_list: 'Remove list from lists (`flatten list`)',
    // Select Case
    select_case: 'Select case decision',
    // Zip
    zip: 'Zip file(s)',
    unzip: 'Unzip ZIP file(s)',
    ungzip: 'Unzip gzip file(s)',
    // Scheduler
    schedule_flows: 'Schedule Flows',
    // Mail
    send_email: 'Send e-mail',
    // Spreadsheet Helper,
    group_by: 'Group columns by...',
    sort_by: 'Sort columns by...',
    // SQL
    execute: 'SQL to be executed',
    // Terminate
    termination: 'Terminate Flow branch',
    synchronizeSuperActionFlowCopies: 'Sync flow copies',
  },
  auth: {
    allStepsUpdated: 'All "{connectorName}" steps updated',
    setAllAuths: 'Set all auths of "{connectorName}" in this flow to "{currentAuthName}"',
  },
  connector: 'Connector',
  connectorAuths: 'Connector Auths',
  panel: {
    apply: 'Apply changes!',
    pleaseApplyAndSave: 'Please click "Apply Changes" and save the flow for your changes to take effect.',
  },
}
