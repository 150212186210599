import { createStore } from 'vuex'
import app from './modules/app'
import company from './modules/company'
import connector from './modules/connector'
import envVariableDialog from './modules/envVariableDialog'
import feature from './modules/feature'
import permission from './modules/permission'
import settings from './modules/settings'
import snapshotOverlay from './modules/snapshotOverlay'
import tagsView from './modules/tagsView'
import team from './modules/team'
import user from './modules/user'

export default createStore({
  modules: {
    app,
    company,
    connector,
    envVariableDialog,
    feature,
    permission,
    settings,
    snapshotOverlay,
    tagsView,
    team,
    user,
  },
})
