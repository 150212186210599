<template>
  <div>
    <!-- Must be wrapped in a div so that `v-show` from parent component (App.vue) affects it properly. -->
    <el-color-picker
      v-model="selectedTheme"
      :predefine="['#1890ff', '#304156','#212121','#11a983', '#13c2c2', '#6959CD', '#f5222d', ]"
      popper-class="lo-theme-picker-dropdown"
      @change="updateTheme"
    />
  </div>
</template>

<script>
import { Colors } from '@/ui/consts/colors'

const ORIGINAL_THEME = Colors.defaultPrimaryColor // default color
export default {
  name: 'ThemePicker',
  props: {
    theme: {
      type: String,
      default: null,
    },
    shouldTriggerChange: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'updated',
  ],
  data() {
    return {
      chalk: '', // content of theme-chalk css
      numberOfWatches: 0,
    }
  },
  computed: {
    defaultTheme() {
      return this.$store.state.settings.theme ?? ORIGINAL_THEME
    },
    selectedTheme: {
      get() {
        const currentTheme = this.theme ?? this.defaultTheme
        this.setTheme(currentTheme)
        this.setColorsForSyncfusion(currentTheme)
        return currentTheme
      },
      set(value) {
        this.$emit('updated', value)
      },
    },
  },
  methods: {
    setTheme(companyColor) {
      if (!this.shouldTriggerChange) return
      if (typeof companyColor !== 'string') return
      const themeCluster = this.getThemeCluster(companyColor)
      const style = this.createStyle(themeCluster)

      let styleTag = document.getElementById('lo-theme')
      if (!styleTag) {
        styleTag = document.createElement('style')
        styleTag.setAttribute('id', 'lo-theme')
        document.head.appendChild(styleTag)
      }
      styleTag.innerText = style
    },
    setColorsForSyncfusion(theme = ORIGINAL_THEME) {
      const classesToChangeBackgroundColor = [
        '.e-check',
      ]
      const classesToChangeColor = [
        '.e-icons.e-selected',
        '.e-flat.e-primary',
        '.e-active',
        '.e-input-group.e-input-focus',
        '.e-treeview .e-list-item.e-active > .e-text-content',
        '.e-treeview .e-list-item.e-active > .e-text-content .e-list-text',
      ]
      const classesToChangeBorderColor = [
        '.e-input-group',
      ]
      let innerHTML = ''
      classesToChangeBackgroundColor.forEach((className) => {
        innerHTML += ` ${className} { background-color: ${theme}!important; }`
      })
      classesToChangeColor.forEach((className) => {
        innerHTML += ` ${className} { color: ${theme}!important; }`
      })
      classesToChangeBorderColor.forEach((className) => {
        innerHTML += ` ${className} { border-color: ${theme}!important; }`
      })
      innerHTML += ' .e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-none.e-selected::before, .e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-ascend.e-selected::before, .e-pivotfieldlist-wrapper .e-field-list-container .e-field-table .e-field-header-wrapper .e-sort-descend.e-selected::before { color: unset; }'
      innerHTML += ' icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after { background: unset; }'
      innerHTML += ` .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after { background-color: ${theme}!important; }`
      innerHTML += ' .e-numeric-hidden[validatehidden="true"] { display: none !important; }'
      innerHTML += ` .e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) { border-bottom-color: ${theme} !important; }`
      innerHTML += ` .e-control.e-btn.e-lib.e-flmenu-okbtn.e-primary.e-flat { color: ${theme} !important; }`
      innerHTML += ' a.e-link.e-numericitem.e-spacing.e-currentitem.e-active { background-color: lightgray !important; }'
      const elemetId = 'custom-syncfusion-style'
      if (!document) {
        return
      }
      let style = document.getElementById(elemetId)
      if (!style) {
        style = document.createElement('style')
        style.id = 'custom-syncfusion-style'
      }
      style.type = 'text/css'
      style.innerHTML = innerHTML
      document.getElementsByTagName('head')[0].appendChild(style)
    },
    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        if (tint === 0) { // when primary color is in its rgb space
          return `rgb(${[red, green, blue].join(',')})`
        }
        red += Math.round(tint * (255 - red))
        green += Math.round(tint * (255 - green))
        blue += Math.round(tint * (255 - blue))
        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)
        return `#${red}${green}${blue}`
      }
      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)
        red = Math.round((1 - shade) * red)
        green = Math.round((1 - shade) * green)
        blue = Math.round((1 - shade) * blue)
        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)
        return `#${red}${green}${blue}`
      }

      const cluster = {
        '--el-color-primary': theme,
      }

      theme = theme.replace('#', '')
      for (let i = 0; i <= 9; i++) {
        cluster[`--el-color-primary-light-${i + 1}`] = tintColor(theme, Number((i / 10).toFixed(2)))
      }
      cluster['--el-color-primary-dark-2'] = shadeColor(theme, 0.1)

      return cluster
    },
    createStyle(cluster) {
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      return `:root { ${Object.entries(cluster).map(([key, value]) => `${key}: ${value};`).join(' ')} }`
    },
  },
}
</script>

<style lang="scss">
/** Cannot scope a class passed to an element as popper-class */
  .lo-theme-picker-dropdown {
    z-index: 99999 !important;

    .el-color-dropdown__link-btn {
      display: none;
    }
  }
</style>
