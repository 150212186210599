<template>
  <el-menu-item
    index="FlowMonitoring"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('FlowMonitoring')"
  >
    <icon icon="chart" />
    <template #title>
      {{ generateTitle('flowMonitoring') }}
    </template>
  </el-menu-item>

  <el-menu-item
    index="FlowBuilderOverview"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('Flows')"
  >
    <icon icon="tree" />
    <template #title>
      {{ generateTitle('flows') }}
    </template>
  </el-menu-item>

  <el-menu-item
    index="CommunityLibrary"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('CommunityLibrary')"
  >
    <icon icon="shopping" />
    <template #title>
      {{ generateTitle('communityLibrary') }}
    </template>
  </el-menu-item>

  <el-menu-item
    index="ConnectorAuth"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('ConnectorAuth')"
  >
    <icon icon="password" />
    <template #title>
      {{ generateTitle('connectorAuth') }}
    </template>
  </el-menu-item>

  <el-menu-item
    index="FileUpload"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('FileUpload')"
  >
    <icon icon="table" />
    <template #title>
      {{ generateTitle('fileUploader') }}
    </template>
  </el-menu-item>

  <el-menu-item
    index="EnvVariables"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('EnvVariables')"
  >
    <icon el-icon="ElIconSwitch" />
    <template #title>
      {{ generateTitle('flowEnvVariables') }}
    </template>
  </el-menu-item>

  <el-menu-item
    v-show="$hasPermission(['admin', 'accountAdmin'])"
    index="ActivityLog"
    class="sidebar-menu-item"
    :route="getTagRouteLocation('ActivityLog')"
  >
    <icon icon="excel" />
    <template #title>
      {{ generateTitle('activityLog') }}
    </template>
  </el-menu-item>

  <el-sub-menu
    v-show="$hasPermission(['embed_view'])"
    index="Embedding"
    class="sidebar-menu-item"
    @click="goToRouteByName('Embedding')"
  >
    <template #title>
      <icon el-icon="ElIconGrid" />
      <div class="title-link">
        {{ generateTitle('embed') }}
      </div>
    </template>
    <el-menu-item-group>
      <el-menu-item
        index="Embedding-Configurations"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Embedding')"
      >
        <icon icon="list" />
        <template #title>
          {{ generateTitle('configurations') }}
        </template>
      </el-menu-item>
      <el-menu-item
        index="Embedding-Integrations"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('EmbeddingIntegrations')"
      >
        <icon el-icon="ElIconSetUp" />
        <template #title>
          {{ generateTitle('integrations') }}
        </template>
      </el-menu-item>
      <el-menu-item
        index="Embedding-ConnectorAuth"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('EmbeddingConnectorAuths')"
      >
        <icon icon="password" />
        <template #title>
          {{ generateTitle('connectorAuth') }}
        </template>
      </el-menu-item>
      <el-menu-item
        index="Embedding-ActivityLog"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('EmbeddingActivityLog')"
      >
        <icon icon="excel" />
        <template #title>
          {{ generateTitle('activityLog') }}
        </template>
      </el-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu
    v-show="$hasPermission(['dashboard_view'])"
    index="Dashboards"
    class="sidebar-menu-item"
  >
    <template #title>
      <icon icon="dashboard" />
      <div class="title-link">
        {{ generateTitle('myDashboards') }}
      </div>
    </template>

    <el-menu-item-group>
      <el-menu-item
        index="Dashboards-Transformation"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Transformation')"
      >
        <icon icon="component" />
        <template #title>
          {{ generateTitle('transformation') }}
        </template>
      </el-menu-item>

      <el-menu-item
        index="Dashboards-DataSource"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('DataSource')"
      >
        <icon icon="list" />
        <template #title>
          {{ generateTitle('dataSources') }}
        </template>
      </el-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu
    v-show="$hasPermission(['connector_view', 'admin', 'accountAdmin'])"
    index="Settings"
    class="sidebar-menu-item"
  >
    <template #title>
      <icon icon="form" />
      <div class="title-link">
        {{ generateTitle('settings') }}
      </div>
    </template>

    <el-menu-item-group>
      <el-menu-item
        v-show="$hasPermission(['connector_view'])"
        index="Settings-Connector"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Connector')"
      >
        <icon icon="international" />
        <template #title>
          {{ generateTitle('connector') }}
        </template>
      </el-menu-item>

      <el-menu-item
        v-show="$hasPermission(['admin', 'accountAdmin'])"
        index="Settings-Users"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Users')"
      >
        <icon icon="user" />
        <template #title>
          {{ generateTitle('user') }}
        </template>
      </el-menu-item>

      <el-menu-item
        v-show="$hasPermission(['admin', 'accountAdmin'])"
        index="Settings-Teams"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Teams')"
      >
        <icon icon="peoples" />
        <template #title>
          {{ generateTitle('team') }}
        </template>
      </el-menu-item>

      <el-menu-item
        v-show="$hasPermission(['admin'])"
        index="Settings-Company"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Company')"
      >
        <icon el-icon="ElIconOfficeBuilding" />
        <template #title>
          {{ generateTitle('company') }}
        </template>
      </el-menu-item>

      <el-menu-item
        v-show="$hasPermission(['admin'])"
        index="Settings-Roles"
        class="sidebar-menu-item"
        :route="getTagRouteLocation('Roles')"
      >
        <icon el-icon="ElIconLock" />
        <template #title>
          {{ generateTitle('roles') }}
        </template>
      </el-menu-item>
    </el-menu-item-group>
  </el-sub-menu>

  <el-sub-menu index="Help" class="sidebar-menu-item">
    <template #title>
      <icon icon="example" />
      <div class="title-link">
        {{ generateTitle('help_docs') }}
      </div>
    </template>

    <el-menu-item-group>
      <el-menu-item index="Help-Docs" class="sidebar-menu-item" :route="{}">
        <a href="https://docs.locoia.com/" target="_blank" rel="noopener">
          <icon icon="documentation" />
          {{ generateTitle('documentation') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-YouTube" class="sidebar-menu-item" :route="{}">
        <a href="https://www.youtube.com/channel/UC4WaSwSERFd3K-v7-sik4fw" target="_blank" rel="noopener">
          <icon el-icon="ElIconVideoCamera" />
          {{ generateTitle('YouTube') }}
        </a>
      </el-menu-item>

      <el-menu-item
        index="Help-Shortcuts"
        class="sidebar-menu-item"
        :route="{}"
        @click="$emit('eventClick', 'shortcut')"
      >
        <icon el-icon="ElIconInfoFilled" />
        <template #title>
          {{ generateTitle('Shortcuts') }}
        </template>
      </el-menu-item>

      <el-menu-item index="Help-Slack" class="sidebar-menu-item" :route="{}">
        <a href="https://join.slack.com/t/locoia-automation/shared_invite/zt-xnphvr1d-_eI0E9_1VGCndrBnFyRPzg" target="_blank" rel="noopener">
          <icon icon="peoples" />
          {{ generateTitle('Community Slack') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-About" class="sidebar-menu-item" :route="{}">
        <a href="https://www.locoia.com" target="_blank" rel="noopener">
          <icon el-icon="ElIconOfficeBuilding" />
          {{ generateTitle('About us') }}
        </a>
      </el-menu-item>

      <el-menu-item index="Help-GTCs" class="sidebar-menu-item" :route="{}">
        <a href="https://hubs.ly/Q01bf7tf0" target="_blank" rel="noopener">
          <icon icon="documentation" />
          {{ generateTitle('GTCs') }}
        </a>
      </el-menu-item>
    </el-menu-item-group>
  </el-sub-menu>
</template>

<script>
import { mapState } from 'vuex'
import { useTagsNavigation } from '@/ui/composables/navigation.js'
import { generateTitle } from '@/ui/utils/i18n.js'

export default {
  name: 'SidebarMenu',
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
    indexAbove: {
      type: String,
      default: null,
    },
  },
  emits: [
    'eventClick',
  ],
  setup() {
    const { getTagRouteLocation } = useTagsNavigation()
    return {
      getTagRouteLocation,
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
    }),
  },
  mounted() {
    this.fixBugIniOS()
  },
  methods: {
    generateTitle,
    /** Goes to a route immediately, but only if the sidebar is collapsed.
     * This is required to use on a sub-menu if you want the root menu item to take you to a specific route upon clicking it.
     * It only works when it's collapsed because when it's expanded you can't hover over the item to see the submenus, you NEED to click it. */
    goToRouteByName(name) {
      if (!this.isCollapse) return
      this.$router.push({ name: name })
    },
    // https://github.com/PanJiaChen/vue-element-admin/pull/1152/files
    fixBugIniOS() {
      const $subMenu = this.$refs.subMenu
      if ($subMenu) {
        const { handleMouseleave } = $subMenu
        $subMenu.handleMouseleave = (e) => {
          if (this.device === 'mobile') {
            return
          }
          handleMouseleave(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/ui/styles/variables";

.sidebar-menu-item {
  --el-menu-level: 0;
  --el-menu-level-padding: 0;
  --el-menu-base-level-padding: 0;

  & span {
    user-select: none;
  }
  & a {
    flex-shrink: 0
  }
}
.el-sub-menu__title span,
.sidebar-menu-item {
  font-size: 16px;
}
:deep(.el-sub-menu__icon-arrow) {
  right: 20px !important;
}

.title-link {
  width: calc(100% - 44px);
}
.svg-icon {
  margin: 0 14px !important;
}
.el-icon {
  margin: 0 10px !important;
}

:deep(.el-menu-item-group) {
  background-color: $subMenuBg !important;
  .sidebar-menu-item:hover {
    background-color: $subMenuHover !important;
  }
}
:deep(.el-menu-item-group__title) {
  padding: 0
}
:deep(.el-sub-menu__title) {
  &:hover {
    background-color: $menuHover !important;
  }
}
.el-menu-item :deep( .el-tooltip__trigger) {
  padding: 0
}
</style>
