import store from '@/ui/store'

/**
 * Very simple permission method, provided globally for each component and should be used with v-if directive.
 */
export default {
  install(app) {
    // TODO: separate checking user types from checking view permissions from checking individual permissions
    app.config.globalProperties.$hasPermission = (permissionRoles) => {
      if (!Array.isArray(permissionRoles) || permissionRoles.length === 0) {
        return true
      }

      const roles = store.getters && store.getters['user/roles']
      if (roles.some((role) => permissionRoles.includes(role))) {
        return true
      }

      const viewPermissions = store.getters && store.getters['permission/permissions']
      return permissionRoles.some(role => viewPermissions?.[role]?.[0]?.action === 'read')
    }
  },
}
