import Icon from '@/ui/components/Icon'
import './svg/404.svg'
import './svg/bug.svg'
import './svg/chart.svg'
import './svg/clipboard.svg'
import './svg/component.svg'
import './svg/dashboard.svg'
import './svg/documentation.svg'
import './svg/drag.svg'
import './svg/edit.svg'
import './svg/email.svg'
import './svg/example.svg'
import './svg/excel.svg'
import './svg/exit-fullscreen.svg'
import './svg/eye-open.svg'
import './svg/eye.svg'
import './svg/form.svg'
import './svg/fullscreen.svg'
import './svg/guide 2.svg'
import './svg/guide.svg'
import './svg/icon.svg'
import './svg/international.svg'
import './svg/key.svg'
import './svg/language.svg'
import './svg/letter-t.svg'
import './svg/link.svg'
import './svg/list.svg'
import './svg/lock.svg'
import './svg/message.svg'
import './svg/money.svg'
import './svg/nested.svg'
import './svg/password.svg'
import './svg/pdf.svg'
import './svg/people.svg'
import './svg/peoples.svg'
import './svg/qq.svg'
import './svg/search.svg'
import './svg/shopping.svg'
import './svg/size.svg'
import './svg/star.svg'
import './svg/tab.svg'
import './svg/table.svg'
import './svg/theme.svg'
import './svg/tree.svg'
import './svg/user.svg'
import './svg/wechat.svg'
import './svg/zip.svg'

export default Icon
