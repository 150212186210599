export default {
  loading: 'Lädt...',
  step: {
    backToShowMainFlowRunSteps: 'Zurück zu den Steps des Haupt-Flow Runs',
    backToShowStepsOfStep: 'Zurück zu den Steps von "{stepName}"',
    hide_errors: 'Zurücksetzen',
    run_is_scheduled: 'Der Flow Run wurde geplant und wird in Kürze beginnen',
    history_deleted: 'Step Historie bereits gelöscht - siehe Flow Einstellungen',
    nothing_found: 'Keine Suchergebnisse',
    filtering_not_available: 'Es gibt keine Flow Run Steps, die angezeigt werden können. Daher ist Filtern nicht möglich.',
    run_again: 'Wiederholen',
    cancel_flow: 'Flow Run abbrechen',
    search: 'Suche steps...',
    show_errors: 'Zeige Fehler',
    title: 'Steps',
    downloadFullLog: 'Gesamtes Ergebnis herunterladen',
    downloadFullInput: 'Gesamte Eingabe herunterladen',
    redirectToFlowBuilder: 'Im Flow Builder bearbeiten',
    redirectToSuperActionRun: 'Super Action Details',
    stepIsBeingProcessed: 'Dieser Schritt wird noch verarbeitet',
    stepHasFinished: 'Der Schritt ist abgeschlossen',
    selectAFlowRun: 'Wählen Sie einen Flow Run aus, um Schritte anzuzeigen',
    showIterations: 'Iterationen anzeigen',
    redirectToTriggeredFlowRun: 'Gehe zum Triggered Run',
  },
  run: {
    hide_errors: 'Zurücksetzen',
    show_errors: 'Zeige Fehler',
  },
  flow_run: {
    cancelFlowRunNotPossible: 'Sie können nur einen Flow Run abbrechen, der gerade läuft',
    flowRunNotSelectedOrNoStepsFound: 'Flow Run ist nicht ausgewählt oder es wurden keine Schritte im Flow Run gefunden',
    title: 'Flow Runs',
    noFlowRuns: 'Es gibt noch keine Flow Runs',
    noFlowRunsForSelectedFilter: 'Es gibt keine Flow Runs, die auf den ausgewählten Filtern basieren',
    runAgainTooltip: 'Den derzeit ausgewählten Flow Run erneut ausführen',
  },
}
