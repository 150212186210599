import { createI18n } from 'vue-i18n'
import enLocale from './en/index.js'
import deLocale from './de/index.js'
import * as R from 'ramda'

const Languages = {
  de: 'de',
  en: 'en',
}

export const DefaultLanguage = Languages.en

export function createI18nInstance(lang = DefaultLanguage) {
  return createI18n({
    locale: lang,
    messages: {
      en: enLocale,
      de: deLocale,
    },
    missing(locale, key) {
      console.error('missing translation for: ', key, ' in ', locale)
      const chunks = key.split('.')
      let elm = chunks[chunks.length - 1]
      elm = elm.replace(/[-]|[_]/g, ' ')
      const values = elm.split(' ') || []
      const ret = []
      for (const m of values) {
        const nameCapitalized = m.charAt(0).toUpperCase() + m.slice(1)
        ret.push(nameCapitalized)
      }
      return ret.join(' ')
    },
  })
}

/**
 * A helper returning translation of an item, if it is a `SchemaFieldTranslationItem` or
 * the item itself if it is a string.
 * @param {('en' | 'de')} lang - A language code, we currently support only `en` and `de`
 * @param {SchemaFieldTranslationItem | string} item - to be translated
 * @returns {string|*} The translation of the field property
 */
export function translateSchemaItem(lang, item) {
  if (!R.is(Object, item)) {
    return item
  }
  return lang in item ? item[lang] : item.en
}
