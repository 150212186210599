import { ElNotification } from 'element-plus'
import * as R from 'ramda'
import {
  version as uuidVersion,
  validate as uuidValidate,
} from 'uuid'

export const EmailListValidator = (rule, emailsString, callback) => {
  let faultyMails
  if (emailsString) {
    const filteredEmails = []
    const emails = emailsString.split(',')

    // Check mails
    emails.forEach((m) => {
      const mail = m.trim()
      // eslint-disable-next-line no-useless-escape
      if (!mail.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        faultyMails = new Error(`Email does not match mail validation ${mail}`)
      }
      if (!filteredEmails.includes(mail)) {
        filteredEmails.push(mail)
      } else {
        faultyMails = new Error(`Duplicates in email list: ${mail}`)
      }
    })
  }
  callback(faultyMails)
}

/**
 * Creates an Element Plus form validator for Python identifiers.
 * @returns {Function} Validator function for Element Plus form
 */
export function pythonIdentifierValidator(rule, value, callback) {
  const error = validatePythonIdentifier(value)
  if (error) {
    callback(new Error(error))
  } else {
    callback()
  }
}

/**
 * Validates if a string is a valid Python identifier.
 * @param {string} value
 * @returns {string|null} Error message if invalid, null if valid
 */
export function validatePythonIdentifier(value) {
  if (!startsWithLetter(value)) {
    return 'The identifier must start with a letter'
  }
  if (!hasOnlyValidPythonIdentifierCharacters(value)) {
    return 'The identifier must contain no spaces or special symbols except for underscores'
  }
  return null // Valid
}

/**
 * Checks if the value starts with a letter (uppercase or lowercase).
 * @param {string} value
 * @returns {boolean}
 */
function startsWithLetter(value) {
  return /^[a-zA-Z]/.test(value)
}

/**
 * Checks if the value contains only letters, numbers, or underscores.
 * @param {string} value
 * @returns {boolean}
 */
function hasOnlyValidPythonIdentifierCharacters(value) {
  return /^[a-zA-Z0-9_]*$/.test(value)
}

const handleErrorsAsInvalid = (pureValidator) => R.tryCatch(pureValidator, R.F)

/**
 * validate JSON OBJECT
 * @param JSON
 * @returns {boolean}
 */
export const validJsonObject = handleErrorsAsInvalid((jsonString) => {
  const o = JSON.parse(jsonString)
  return o && typeof o === 'object'
})

/**
 * validate JSON
 * @param JSON
 * @returns {boolean}
 */
export const validJSON = handleErrorsAsInvalid(R.pipe(JSON.parse, R.T))

/**
 * This method receives one or more ids, and checks whether they have uuid format
 * @param {Object} idsObject
 * @returns {boolean} - true if all ids have uuid format; false otherwise
 */
export const validateIdsHaveUuidFormat = handleErrorsAsInvalid((idsObject) => {
  const ids = Object.values(idsObject)
  return !ids.some((id) => !uuidValidate(id) && !uuidVersion(id) === 4)
})

/**
 * This function can be called with an `el-form` component.
 * The form will be validated, and errors will also be reported using a notification.
 */
export const validateForm = (form) => new Promise((resolve, reject) => {
  form.validate((isValid, invalidFields) => {
    if (isValid) {
      resolve(true)
      return
    }

    let i = 0
    const fields = Object.entries(invalidFields)

    const showInvalidFieldNotification = () => {
      if (i >= fields.length) {
        clearInterval(notificationInterval)
        return
      }

      const fieldKey = fields[i][0] ?? fields[i]
      const validationErrorMessage = fields[i][1][0]?.message

      ElNotification({
        message: validationErrorMessage,
        title: getInvalidNotificationTitle(fieldKey),
        type: 'info',
        duration: 2000,
      })
      i++
    }

    const notificationInterval = setInterval(showInvalidFieldNotification, 100)
    reject(new Error('Invalid form'))
  })
})
/**
 * @param {string} fieldKey
 * Usually the `fieldKey` will be something like: 'firstName'.
 * But if the prop of the field is defined from an array it is expected to be something like: 'arrayRules[0].firstName'
 * @returns {string}
 */
function getInvalidNotificationTitle(fieldKey) {
  const arrayItemIndexString = fieldKey.substring(fieldKey.indexOf('[') + 1, fieldKey.indexOf(']'))
  if (!arrayItemIndexString) {
    return `Field '${fieldKey}' invalid!`
  }

  const arrayFieldKey = fieldKey.substring(fieldKey.lastIndexOf('.') + 1)
  return `Field '${arrayFieldKey}' invalid for item ${Number(arrayItemIndexString) + 1} in list!`
}

/**
 * The following function receives a step's reference and checks if it is a system constant
 * @param {string} value
 * @returns {boolean}
 */

export function isASystemConstant(value) {
  const systemConstants = ['date_de_180', 'date_us', 'last_execution', 'date_utc_7', 'date_utc_180',
    'flow_run_start_date', 'time', 'di_user_name', 'date_utc_30', 'date_us_30', 'flow_name', 'date_us_7',
    'flow_run_id', 'last_execution_end_date', 'flow_id', 'last_execution_success', 'date_utc', 'last_execution_success_end_date',
    'date_de_7', 'index', 'last_execution_success_start_date', 'last_execution_start_date', 'flow_run_triggered_by', 'unix_timestamp',
    'di_user_email', 'date_de', 'date', 'datetime', 'last_execution_succeeded', 'date_de_30', 'date_us_180']

  return systemConstants.includes(value)
}
