<template>
  <div class="lo-header-search show">
    <icon class="search-icon" icon="search" @click="onIconClicked" />
    <Transition name="search-transition">
      <el-select
        v-if="isSearchVisible"
        ref="headerSearchSelect"
        v-model="search"
        :remote-method="querySearch"
        filterable
        remote
        placeholder="Search"
        class="header-search-select"
        popper-class="lo-header-search__popper"
        @change="onChange"
        @blur="debouncedCloseSearch"
      >
        <el-option
          v-for="item in options"
          :key="item.item.path"
          :value="item"
          :label="item.item.title.join(' > ')"
        />
      </el-select>
    </Transition>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapState } from 'vuex'

const fuseLoader = () => import(/* webpackChunkName: "fuse-js" */'fuse.js')

export default {
  name: 'HeaderSearch',
  inject: ['i18n'],
  data() {
    return {
      search: null,
      options: [],
      searchPool: [],
      isSearchVisible: false,
      fuse: null,
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.app.language,
    }),
    routers() {
      return this.$store.state.permission.routers
    },
  },
  watch: {
    lang() {
      this.searchPool = this.generateRoutes(this.routers)
    },
    routers() {
      this.searchPool = this.generateRoutes(this.routers)
    },
    searchPool(list) {
      this.initFuse(list)
    },
  },
  mounted() {
    this.searchPool = this.generateRoutes(this.routers)
  },
  methods: {
    onIconClicked() {
      if (!this.isSearchVisible) {
        this.openSearch()
      }
    },
    openSearch() {
      this.isSearchVisible = true
      this.$nextTick(() => {
        this.$refs.headerSearchSelect?.$el.classList.add('expanded')
        this.$refs.headerSearchSelect?.focus()
      })
    },
    /** We need to debounce the search close. Otherwise it triggers before the @change event. */
    debouncedCloseSearch() {
      return debounce(this.closeSearch, 100)()
    },
    closeSearch() {
      this.$refs.headerSearchSelect?.$el.classList.remove('expanded')
      this.$refs.headerSearchSelect?.blur()
      this.options = []
      this.search = ''
      this.isSearchVisible = false
    },
    onChange({ item }) {
      this.closeSearch()
      const isChangingToDifferentPath = this.$route.path !== item.path
      if (isChangingToDifferentPath) {
        this.$router.push(item.path)
      }
    },
    async initFuse(list) {
      const Fuse = (await fuseLoader()).default
      this.fuse = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        keys: [{
          name: 'title',
          weight: 0.7,
        }, {
          name: 'path',
          weight: 0.3,
        }],
      })
    },
    /** Filter out the routes that can be displayed in the sidebar and generate the internationalized title */
    generateRoutes(routers, basePath = '/', prefixTitle = []) {
      let res = []

      for (const router of routers) {
        // skip hidden router
        if (router.meta?.hidden) continue

        const currentPath = router.path.startsWith(basePath) ? router.path.substring(1) : router.path
        // generate internationalized title
        const i18ntitle = router.meta?.title ? this.i18n.t(`route.${router.meta.title}`) : null

        const data = {
          path: basePath + currentPath,
          title: i18ntitle ? [...prefixTitle, i18ntitle] : [...prefixTitle],
        }

        /** only push the routes with title and with redirect enabled */
        const isNavigatableRoute = router.redirect !== 'noredirect' && i18ntitle
        if (isNavigatableRoute) {
          res.push(data)
        }

        if (router.children) {
          res = this.addChildrenRoutes(res, router.children, data)
        }
      }
      return res
    },
    addChildrenRoutes(totalRoutes, childRoutes, currentRoute) {
      const basePath = currentRoute.title?.length > 0 ? currentRoute.path + '/' : currentRoute.path
      const generatedChildRoutes = this.generateRoutes(childRoutes, basePath, currentRoute.title)
      if (generatedChildRoutes.length >= 1) {
        totalRoutes = [...totalRoutes, ...generatedChildRoutes]
      }
      return totalRoutes
    },
    querySearch(query) {
      if (!query) {
        this.options = []
        return
      }

      this.options = this.fuse.search(query)
    },
  },
}
</script>

<style lang="scss">
/** Cannot scope popper-class as it is not rendered inside the current element */
.lo-header-search__popper {
  .el-select-dropdown__item.selected {
    color: var(--el-text-color-regular);
    font-weight: normal;
  }
}
</style>

<style lang="scss" scoped>
.lo-header-search {
  font-size: 0 !important;

  .search-icon {
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
  }

  .header-search-select {
    font-size: 18px;
    margin-left: 10px;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    :deep(.el-input__inner) {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      vertical-align: middle;
    }

    &.expanded {
      width: 210px;
    }
  }
}

.search-transition-enter-active {
  transition: all 0.2s ease-out;
}
.search-transition-leave-active {
  transition: all 0.2s ease-in;
}

.search-transition-enter-to,
.search-transition-leave-from{
  width: 210px;
}

.search-transition-enter-from,
.search-transition-leave-to {
  opacity: 0;
  transform: scale(0);
  width: 0px;
}
</style>
