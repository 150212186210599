import { EditorView } from '@codemirror/view'
import { Extension } from '@codemirror/state'
import { Colors } from '@/ui/consts/colors'

/* https://github.com/codemirror/theme-one-dark as the base for theme, but simplified and modified.
 * If additional implementation of highlighting/autocomplete/etc... required, please take a look there. */

/* Properties */
const contentBackgroundColor = 'white'
const contentColor = 'black'
const borderColor = `var(--el-border-color, ${Colors.grey300})`
const borderColorHighlighted = `var(--el-color-primary)`
const secondaryBackgroundColor = Colors.grey100
const secondaryColor = Colors.grey700
const highlightBackgroundColor = Colors.grey500
const searchMatchBackgroundColor = Colors.blue300Alpha
const searchMatchOutlineColor = Colors.blue500
const searchMatchBackgroundSelectColor = Colors.blue700Alpha
const activeLineColor = '#FCFBFB'
const activeLineGutterColor = '#DDEDFF'
const fontColorRegular = 'var(--el-text-color-regular)'
const fontStyleButtonsAndTextFields = 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif !important'

const commonBaseTheme = {
  '&': {
    color: contentColor,
    backgroundColor: contentBackgroundColor,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: `1px solid ${borderColor}`,
      borderRadius: '5px',
      pointerEvents: 'none',
    },
    height: 'inherit',
  },

  '.cm-scroller': {
    lineHeight: '1.6',
  },

  /* Content */
  '.cm-content': {
    caretColor: contentColor,
    padding: '6px 0px',
    fontSize: '16px',
  },
  '.cm-line': {
    padding: '0 4px 0px 10px',
  },
  '.cm-activeLine': {
    boxShadow: `2px 0 0 0 #72A1D5 inset`,
    backgroundColor: activeLineColor,
  },
  '.cm-selectionMatch': {
    background: Colors.grey300,
  },

  /* Gutters */
  '.cm-gutters': {
    backgroundColor: secondaryBackgroundColor,
    color: secondaryColor,
    borderColor: Colors.grey500,
  },
  '.cm-foldGutter': {
    width: '1em',
    backgroundColor: Colors.grey100,
  },
  '.cm-foldGutter span': {
    width: '100%',
  },
  '.cm-gutterElement': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: fontStyleButtonsAndTextFields,
  },
  '.cm-activeLineGutter': {
    backgroundColor: activeLineGutterColor,
  },
  '.cm-lineNumbers': {
    background: Colors.grey100,
    color: fontColorRegular,
    fontWeight: 500,
  },

  /** On focused */
  '&.cm-focused': {
    '&::after': {
      borderColor: borderColorHighlighted,
    },
    outline: 'none',
  },
  '&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground, .cm-selectionBackground, .cm-content ::selection': {
    backgroundColor: highlightBackgroundColor,
  },
  '&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket': {
    backgroundColor: '#e1e4ea',
  },

  /* Search panel */
  '.cm-panels': {
    backgroundColor: secondaryBackgroundColor,
    color: secondaryColor,
    zIndex: '1',
  },
  '.cm-panels.cm-panels-top': {
    borderBottom: `2px solid ${borderColor}`,
    backgroundColor: '#FCFBFB !important',
  },
  '.cm-panels.cm-panels-bottom': {
    borderTop: `2px solid ${borderColor}`,
  },
  '.cm-searchMatch': {
    backgroundColor: searchMatchBackgroundColor,
    outline: `1px solid ${searchMatchOutlineColor}`,
  },
  '.cm-searchMatch.cm-searchMatch-selected': {
    backgroundColor: searchMatchBackgroundSelectColor,
  },

  /* Buttons */
  '.cm-button': {
    backgroundColor: '#d1e9ff',
    backgroundSize: 0,
    border: '1px solid #74bcff !important',
    color: '#1890FF !important',
    borderRadius: '5px !important',
    fontSize: '11px',
    textTransform: 'capitalize',
    fontFamily: fontStyleButtonsAndTextFields,
  },
  'button[name="close"]': {
    color: '#606266',
    marginRight: '10px !important',
    cursor: 'pointer',
    fontSize: '18px !important',
  },

  /* Text Fields */
  '.cm-textfield': {
    fontFamily: fontStyleButtonsAndTextFields,
    fontSize: '12px !important',
  },

  /* Diagnostics */
  '.cm-diagnosticText': {
    fontFamily: fontStyleButtonsAndTextFields,
  },

  /* Labels */
  '.cm-panel > label': {
    color: '#606266',
    fontFamily: fontStyleButtonsAndTextFields,
  },
  '.cm-panel > label > input[type="checkbox"]': {
    margin: '5px !important',
    paddingTop: '1px !important',
  },
}

/** @type {Extension} */
export const baseTheme = EditorView.theme({
  ...commonBaseTheme,

  '*': {
    fontFamily: 'Courier, monospace',
  },

}, { dark: false })

export const elementPlusInputTheme = EditorView.theme({
  ...commonBaseTheme,

  '&': {
    ...commonBaseTheme['&'],
    color: fontColorRegular,
  },
  '.is-error &::after': {
    border: '1px solid var(--el-color-danger)',
  },

  '.cm-scroller': {
    ...commonBaseTheme['.cm-scroller'],
    fontFamily: 'inherit',
  },

  '.cm-content': {
    ...commonBaseTheme['.cm-content'],
    caretColor: 'inherit',
    fontSize: 'inherit',
    maxHeight: '200px',
  },

}, { dark: false })
