export default {
  tokens: {
    confirmDeletingToken: 'Are you sure you want to delete the token permanently?',
    confirmRegenerateToken: 'Are you sure you want to regenerate the token? Old token will be invalidated.',
    createNewToken: 'Create new personal access token',
    errorCreatingToken: 'Error creating token',
    errorDeletingToken: 'Error deleting personal access token!',
    errorRegeneratingToken: 'Error regenerating token!',
    errorUpdatingToken: 'Error deleting personal access token!',
    expiration: 'Expiration',
    expirationDate: 'Expiration date',
    expirationDaysOptions: {
      one: '1 Day',
      seven: '7 Days',
      thirty: '30 Days',
      sixty: '60 Days',
      none: 'No expiration',
    },
    lastUseDate: 'Last use date',
    note: 'Note',
    onlySeeTokenOnceText: 'You will only see the token once after creation.',
    personalAccessTokenCreated: 'Personal access token created!',
    personalAccessTokenInfo: 'Personal access tokens can grant restricted access to the Locoia api.',
    personalAccessTokenUpdated: 'Personal access token updated!',
    personalAccessTokens: 'Personal Access Tokens',
    regenerate: 'Regenerate',
    regenerateToken: 'Regenerate token',
    regenerateTokenTooltip: 'Regenerate the token',
    saveYourTokenSecurely: 'This is your personal access token with the note: \'{personalAccessTokenNote}\'. Make sure to save it securely as you won\'t be able to see it again',
    scopes: 'Scopes',
    tokenDeleted: 'Personal access token deleted!',
    tokenDeletedSuccessfully: 'Personal access token has been deleted successfully!',
    tokenRegeneratedSuccessfully: 'Personal access token has been regenerated successfully!',
    updateToken: 'Update personal access token',
  },
}
