export default {
  namespaced: true,
  state: () => ({
    visitedViews: [],
    cachedViews: [],
  }),
  getters: {
    visitedViews: (state) => state.visitedViews,
    cachedViews: (state) => state.cachedViews,
    getViewByName: (state) => (name) => state.visitedViews.findLast((view) => view.name === name),
  },
  actions: {
    async addView({ commit, dispatch }, view) {
      await dispatch('addVisitedView', view)
      commit('ADD_CACHED_VIEW', view)
    },
    addVisitedView({ commit }, view) {
      commit('ADD_VISITED_VIEW', view)
    },

    async delView({ commit, dispatch }, view) {
      commit('DEL_VISITED_VIEW', view)
      await dispatch('delCachedView', view)
    },
    delCachedView({ commit }, view) {
      commit('DEL_CACHED_VIEW', view)
    },

    delOthersViews({ commit }, view) {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      commit('DEL_OTHERS_CACHED_VIEWS', view)
    },

    delAllViews({ commit }) {
      commit('DEL_ALL_VISITED_VIEWS')
      commit('DEL_ALL_CACHED_VIEWS')
    },

    updateVisitedView({ commit }, view) {
      commit('UPDATE_VISITED_VIEW', view)
    },
    updateVisitedViewName({ commit }, view) {
      commit('UPDATE_VISITED_VIEW_NAME', view)
    },
  },
  mutations: {
    ADD_VISITED_VIEW: (state, view) => {
      if (state.visitedViews.some((v) => v.path === view.path)) return
      if (!view.meta.title) return // do not add views if they are not supposed to be added (like 404, login, ...)
      const newView = { ...view, title: view.meta.title }
      state.visitedViews = [...state.visitedViews, newView]
    },
    ADD_CACHED_VIEW: (state, view) => {
      if (state.cachedViews.includes(view.name)) return
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name)
      }
    },
    DEL_VISITED_VIEW: (state, view) => {
      state.visitedViews = state.visitedViews.filter((v) => v.path !== view.path)
    },
    DEL_CACHED_VIEW: (state, view) => {
      state.cachedViews = state.cachedViews.filter((v) => v !== view.name)
    },
    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      state.visitedViews = state.visitedViews.filter((v) => v.meta.affix || v.path === view.path)
    },
    DEL_OTHERS_CACHED_VIEWS: (state, view) => {
      state.cachedViews = state.cachedViews.filter((v) => v === view.name)
    },
    DEL_ALL_VISITED_VIEWS: (state) => {
      state.visitedViews = state.visitedViews.filter((tag) => tag.meta.affix)
    },
    DEL_ALL_CACHED_VIEWS: (state) => {
      state.cachedViews = []
    },
    UPDATE_VISITED_VIEW: (state, view) => {
      state.visitedViews = state.visitedViews.map(
        (v) => (v.path === view.path ? { ...v, ...view } : v),
      )
    },
    UPDATE_VISITED_VIEW_NAME: (state, view) => {
      state.visitedViews = state.visitedViews.map((visitedView) => {
        if (visitedView.params.flowid === view.id) {
          visitedView.appendTitle = view.name
          return visitedView
        }
        return visitedView
      })
    },
  },
}
