<template>
  <slot />
  <el-alert
    v-if="isWarningVisible"
    :title="i18n.t('enableRemoteSearchAuthorization')"
    type="warning"
  />
  <div ref="autocompleteList">
    <autocomplete-list
      v-if="isVisible"
      :is-visible="isVisible"
      :container-ref="$refs.autocompleteList"
      :field-ref="currentFieldRef"
      :data="autocompleteSuggestions"
      :remote-search="isMakingRemoteSearch"
      :current-connector="currentConnector"
      @select-autosuggest="handleSelectedItem"
      @close="clearSuggestions"
    />
  </div>
</template>

<script>
import { PropType } from 'vue'
import * as R from 'ramda'
import { ElNotification } from 'element-plus'
import { LooperContextOption, matchAutocompleteSuggestions, useAutocompleteSuggestion } from './functions.js'
import AutocompleteList from './AutocompleteList.vue'
import { ExtendedErrorSeverity } from '@/ui/classes/error'
import { isCodeEditor } from '@/ui/components/Editor/functions.js'

/** The Autocomplete currently will only work with Editor fields. */
export default {
  name: 'Autocomplete',
  components: {
    AutocompleteList,
  },
  inject: {
    i18n: {
      default: /** @type {Object} */ ({}),
    },
  },
  props: {
    currentConnector: {
      type: Object,
      default: null,
    },
    selectedConnectorAuth: {
      type: String,
      default: null,
    },
    remoteSearchParams: {
      type: Object,
      default: null,
    },
    inputRef: {
      type: String,
      default: null,
    },
    flowContext: {
      type: Object,
      default: () => ({}),
    },
    remoteSearchFunction: {
      type: Function,
      default: () => {},
    },
    /** @type {PropType<Array<AutocompleteOption>>} */
    additionalOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      /**
       * Suggestions are what's shown to the user. Options are the entire list.
       * @type {Array<AutocompleteOption>}
      */
      autocompleteSuggestions: [],
      loading: false,
      /** @type {EditorExposed} */
      currentFieldRef: null,
      isWarningVisible: false,
    }
  },
  computed: {
    isVisible() { return this.autocompleteSuggestions?.length > 0 || this.loading },
    isMakingRemoteSearch() { return Boolean(!!this.remoteSearchParams && !this.startsWithJinjaExpression() && this.selectedConnectorAuth) },
    /** @returns {Array<AutocompleteOption>} */
    autocompleteOptions() {
      const contextAutocompleteSuggestions = (Array.isArray(this.flowContext.autocompleteSuggestions) ? this.flowContext.autocompleteSuggestions : [])
      return [...this.additionalOptions, ...contextAutocompleteSuggestions]
    },
  },
  /** @see {AutocompleteExposed} */
  expose: ['searchSuggestions'],
  methods: {
    getCurrentFieldTextInput() { return this.currentFieldRef?.getState()?.input },
    startsWithJinjaExpression() {
      const currentText = this.getCurrentFieldTextInput()
      if (typeof currentText !== 'string') return false
      return currentText?.startsWith('{')
    },
    getIsWarningVisible() {
      return !!this.remoteSearchParams && !this.startsWithJinjaExpression() && !this.selectedConnectorAuth
    },
    /**
     * @param {EditorExposed} editorRef
     * @param {string} [looperContext] @see LooperContextOption
     * @type {AutocompleteExposed.SearchSuggestions}
    */
    async searchSuggestions(editorRef, looperContext = null) {
      if (!editorRef) {
        this.isWarningVisible = false
        return
      }

      if (!isCodeEditor(editorRef)) {
        throw new Error('Unsupported field type')
      }

      this.isWarningVisible = this.getIsWarningVisible()
      this.currentFieldRef = editorRef
      const caretState = editorRef.getState()
      const textInput = caretState.input
      const caretSelection = caretState.selection

      if (!textInput) {
        this.clearSuggestions()
        return
      }

      if (this.isMakingRemoteSearch) {
        this.autocompleteSuggestions = await this.getRemoteSearchSuggestions()
        /*
          If there is only one suggestion and that is equal to the value introduced through
          the input, we clear the suggestions
        */
        if (this.autocompleteSuggestions.length === 1 && this.autocompleteSuggestions[0]?.function_name === textInput) {
          this.clearSuggestions()
        }
        return
      }

      try {
        this.autocompleteSuggestions = matchAutocompleteSuggestions(
          this.currentConnector,
          textInput,
          caretSelection,
          this.autocompleteOptions,
          this.flowContext,
          looperContext,
          this.inputRef,
        )
      } catch (error) {
        const isError = !error.severity || error.severity === ExtendedErrorSeverity.ERROR
        if (isError) {
          throw error
        }
        ElNotification({
          title: this.i18n.t('error.unableToProvideSuggestions'),
          message: error.message,
          type: error.severity ?? 'warning',
          duration: 5000,
        })
      }
    },
    async getRemoteSearchSuggestions() {
      const connectorId = this.currentConnector?.id
      const remoteSearchParams = R.clone(this.remoteSearchParams)
      remoteSearchParams.q = this.getCurrentFieldTextInput() || ''

      this.loading = true
      let data
      try {
        data = (await this.remoteSearchFunction(connectorId, remoteSearchParams))?.data
      } catch (error) {
        console.error(error)
        ElNotification({
          title: this.i18n.t('error.error'),
          message: this.i18n.t('error.fetchingRemoteData'),
          type: 'error',
          duration: 5000,
        })
        data = []
      }
      this.loading = false

      const remoteSuggestions = data.map((suggestion) => ({
        desc: suggestion.match,
        function_name: suggestion.id,
      }))

      if (remoteSuggestions?.length === 0) {
        return [{ desc: '', function_name: `${this.i18n.t('noRemoteSearchResults')}` }]
      }
      return remoteSuggestions
    },
    /**
     * @param {string} name
     */
    handleSelectedItem(name) {
      const isSelectingLooperContext = name === LooperContextOption.INSIDE || name === LooperContextOption.OUTSIDE
      if (isSelectingLooperContext) {
        this.searchSuggestions(this.currentFieldRef, name)
        return
      }

      const fieldState = this.currentFieldRef.getState()

      const result = useAutocompleteSuggestion(fieldState.input, name, fieldState.selection)
      if (!result) {
        return
      }

      this.currentFieldRef.forceFocusEditor()
      this.currentFieldRef.insertText(result.input, result.selection)
      this.clearSuggestions()
    },
    clearSuggestions() {
      this.autocompleteSuggestions = []
    },
  },
}
</script>
