import { watch, App, Ref } from 'vue'
import { I18n } from 'vue-i18n'
import { createI18nInstance, DefaultLanguage } from './lang/index.js'
import SchemaFormRenderer from './components/SchemaFormRenderer.vue'

/**
  * @typedef {Object} SchemaFormRendererPluginOptions - The options object for plugin installation.
  * @property {Ref<string> | String} language - The reference to the language string.
  * The SchemaFormRendererPlugin will be internationalized according to this language.
  * If it's a Ref it will be able to react to its changes.
  * @property {I18n} i18n - The i18n object of the main application.
  * Instead of passing the translated string to the component which is not reactive, we expect a key to the translation.
  * This allows to reactively translate keys using this instance.
*/

const pluginState = {
  i18n: createI18nInstance(DefaultLanguage),
  externalI18n: createI18nInstance(DefaultLanguage),
}

const SchemaFormRendererPlugin = {
  /**
   * @param {App} Vue - The Vue application instance.
   * @param {SchemaFormRendererPluginOptions} [options={}] - The options for the SchemaFormRendererPlugin.
   */
  install(Vue, options = {}) {
    if (options.language) {
      const isReactiveLanguage = typeof options.language === 'object' && 'value' in options.language

      pluginState.i18n = createI18nInstance(isReactiveLanguage ? options.language.value : options.language)
      pluginState.externalI18n = options.i18n ?? pluginState.i18n

      if (isReactiveLanguage) {
        watch(options.language, (newLanguage) => {
          pluginState.i18n.global.locale = newLanguage
          pluginState.externalI18n.global.locale = newLanguage
        })
      }
    }

    Vue.provide('schemaFormRendererI18n', pluginState.i18n.global)
    Vue.provide('externalI18n', pluginState.externalI18n.global)
    Vue.provide('mainApp', Vue) // This is mainly so that we can mainApp.use(ElementTiptapPlugin)
    Vue.component('SchemaFormRenderer', SchemaFormRenderer)
  },
}

export default SchemaFormRendererPlugin
