const noRedirectWhitelist = ['/login', '/auth-redirect']

/**
 * User authentication navigation guard.
 * We check if there's an authenticated user and redirect depending on their navigation route.
 * @param router
 */
export default (router) => {
  router.beforeEach(async (to, from, next) => {
    const isLoggedIn = !!(await router.$store.dispatch('user/getAuthenticatedUserToken'))
    const isNavigatingToLogin = noRedirectWhitelist.includes(to.path)
    if (!isLoggedIn && !isNavigatingToLogin) {
      return next({
        name: 'Login',
        query: {
          redirect: encodeURIComponent(to.fullPath),
        },
      })
    }

    if (isLoggedIn && isNavigatingToLogin) {
      return next({
        name: 'Index',
      })
    }

    return next()
  })
}
