<template>
  <el-form
    ref="form"
    attrs="autocomplete: off"
    class="comp-schema-form-renderer"
    :label-position="labelPosition"
    :label-width="labelWidth"
    :model="ruleModel"
    :rules="validationRules"
    :disabled="isFormDisabled"
    :ref-in-for="true"
  >
    <nested-form-item
      v-for="(value, elementKey) in ruleModel"
      :id="`nestedItem-${elementKey}`"
      :key="elementKey"
      name="top_level_nested_item"
      :element-key="elementKey"
      :rule-model="ruleModel"
      :element="elements[elementKey]"
      :validation-props="validationProps"
      :original-rule-model="ruleModel"
      :selected-connector-auth="getFieldConnectorAuthId(elementKey)"
      :current-connector="getFieldConnector(elementKey)"
      @remove-custom-field="removeCustomField"
      @update-custom-field="$emit('update-custom-field', $event)"
      @rule-model-change="ruleModelChange"
      @field-focused="$emit('fieldFocused', $event)"
    />

    <el-alert
      v-if="showFormError"
      style="margin-bottom: 15px"
      :title="i18n.t('validation.failed')"
      type="error"
    />
    <div v-if="!elementsData.noSubmitButton" class="submit-section">
      <el-button
        v-if="elementsData.submitRegardlessFunction"
        :disabled="disableSubmitButton"
        type="danger"
        size="large"
        class="alignLeft"
        @click="handleSubmit(true)"
      >
        {{ externalI18n.t(elementsData.submitRegardlessTranslationKey ?? 'submitRegardless') }}
      </el-button>

      <el-button
        type="primary"
        size="large"
        :disabled="disableSubmitButton"
        :class="[elementsData.alignButton === 'right' ? 'alignRight' : '']"
        data-test="apply_changes"
        @click="handleSubmit(false)"
      >
        {{ externalI18n.t(elementsData.submitTranslationKey ?? 'submit') }}
      </el-button>
      <span style="margin-left: 5px;">
        <slot name="submit-bar" />
      </span>
    </div>
  </el-form>
</template>

<script>
/* eslint-disable sonarjs/no-duplicate-string */
import * as R from 'ramda'
import set from 'lodash.set'
import get from 'lodash.get'
import { computed } from 'vue'
import { SchemaFormModelTypes } from '../utils/SchemaFormModelTypes'
import { useSchemaFormModelHelper } from '../utils/SchemaFormModelHelper'
import NestedFormItem from '@/ui-libs/schema-form-renderer/components/formItems/NestedFormItem.vue'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

export default {
  name: 'SchemaFormRenderer',
  components: {
    NestedFormItem,
  },
  inject: {
    i18n: 'schemaFormRendererI18n',
    externalI18n: 'externalI18n',
  },
  provide() {
    return {
      flowContext: computed(() => this.elementsData),
      i18n: this.i18n,
      remoteSearchFunction: this.remoteSearchFunction,
    }
  },
  props: {
    elementsData: {
      type: Object,
      required: true,
    },
    isFormDisabled: {
      type: Boolean,
      default: false,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Contains the key that the user specified when declaring the Form Helper and the value.
     * E.g.: { formHelperKey: 'Example' }
     */
    formHelperValue: {
      type: Object,
      default: null,
    },
    /**
     * An api endpoint to call with the specified parameters to retrieve the data for the remote search
     * @param {string} connectorId - UUID of the connector
     * @param {Object} remoteSearchParams - Specific params for the search
     * @returns {Promise<Object>} The promise that resolves to the data object of the remote search for the provided connector id
     * @async
     */
    remoteSearchFunction: {
      type: Function,
      default: async function (connectorId, remoteSearchParams) { return Promise.reject() },
    },
  },
  emits: [
    'change',
    'update-custom-field',
    'remove-custom-field',
    'fieldFocused',
  ],
  setup() {
    const schemaFormModelHelper = useSchemaFormModelHelper()
    return { schemaFormModelHelper }
  },
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
      activeField: null,
      autocompleteSuggestions: [],
      elements: {},
      labelPosition: 'top',
      labelWidth: null,
      ruleModel: {},
      showFormError: false,
      validationRules: {},
      validationProps: {},
      requiredProperties: {},
    }
  },
  watch: {
    schemaFormRendererI18n: {
      handler() {
        this.onValidationRulesChange()
      },
      deep: true,
    },
    async elementsData(newData) {
      if (!newData.uiFormSchema) {
        return
      }

      if (newData.autocompleteSuggestions) {
        this.autocompleteSuggestions = newData.autocompleteSuggestions
      }

      await this.setElements(newData)
    },
    ruleModel: {
      handler(newModel) {
        this.$emit('change', newModel)
        this.onValidationRulesChange()
      },
      deep: true,
    },
  },
  mounted() {
    /**
     * This needs to be invoked for embed otherwise nothing shows because there's nothing that triggers the `elementsData` watcher.
     * This can't be invoked for main application from mounted because it changes `ruleModel` which is being watched and emits a 'change' event
     * which updates `elementsData` in SchemaFormPanel.
     * Ideally we would refactor SchemaFormPanel to supply the correct value in mounted already and reduce reliance on watchers.
     */
    if (this.isEmbed) {
      this.setElements(this.elementsData)
      this.setRuleModelValues(this.formHelperValue)
    }
  },
  methods: {
    /**
      * Updates `this.ruleModel` with values from `formHelperEntries`.
      * `this.ruleModel` is an Object that contains the Form Helper's key and its value (undefined by default)
      * Iterates through each entry in `formHelperEntries` and assigns the corresponding values to `this.ruleModel`.
      * @param {Object} formHelperEntries - An object containing key-value pairs to update `this.ruleModel`.
      * E.g., { name: undefined, address: 'Germany', ... }
    */
    setRuleModelValues(formHelperEntries) {
      if (R.isNil(formHelperEntries)) return

      for (const [key, value] of Object.entries(formHelperEntries)) {
        this.ruleModel[key] = value
      }
    },
    ruleModelChange(newData) {
      this.ruleModel = newData.value || newData
    },
    /** In the case of FormHelper, for example, it can have each field needing remote search for a different connector
     * @param {String} elementKey - The key of the field
     * @returns {String} The UUID of the ConnectorAuth
    */
    getFieldConnectorAuthId(elementKey) {
      const hasSameConnectorAuthForAllFields = !R.isNil(this.elementsData.selectedConnectorAuth) && !R.isEmpty(this.elementsData.selectedConnectorAuth)
      if (hasSameConnectorAuthForAllFields) return this.elementsData.selectedConnectorAuth

      return this.elements[elementKey]?.authId
    },
    /** In the case of FormHelper, for example, it can have each field needing remote search for a different connector
     * @param {String} elementKey - The key of the field
     * @returns {Object} The connector object
    */
    getFieldConnector(elementKey) {
      const hasSameConnectorForAllFields = !R.isNil(this.elementsData.currentConnector) && !R.isEmpty(this.elementsData.currentConnector)
      if (hasSameConnectorForAllFields) return this.elementsData.currentConnector

      return this.elements[elementKey]?.connector
    },
    async setElements({ uiFormSchema, previousUiFormSchema, model, shouldUseDefaultValues }) {
      this.elements = {}

      this.validationRules = {}
      this.validationProps = {}
      this.ruleModel = this.schemaFormModelHelper.createFormModel(uiFormSchema, previousUiFormSchema, model, shouldUseDefaultValues)
      if (!uiFormSchema) return

      if (Array.isArray(uiFormSchema)) {
        uiFormSchema.forEach(item => {
          this.elements[item.name] = this.setElementProps(({ ...item, key: item.name, model: uiFormSchema }))
        })
      } else {
        Object.entries(uiFormSchema).forEach(([key, data]) => {
          this.elements[key] = this.setElementProps({ ...data, key, model: uiFormSchema })
        })
      }

      this.createRulesForSchemaEntry(this.ruleModel)

      await this.$nextTick()
      this.$refs.form.clearValidate()
    },
    setElementProps(data) {
      const fields = {
        [SchemaFormModelTypes.ExpandingList]: 'expanding-list-field',
        [SchemaFormModelTypes.NestedObject]: 'nested-object-field',

        [SchemaFormModelTypes.Select]: 'select-field',
        [SchemaFormModelTypes.Number]: 'number-field',
        [SchemaFormModelTypes.Switch]: 'switch-field',
        [SchemaFormModelTypes.Code]: 'code-field',
        [SchemaFormModelTypes.RichText]: 'rich-text-field',

        [SchemaFormModelTypes.Text]: 'text-field',
        [SchemaFormModelTypes.Password]: 'text-field',
        [SchemaFormModelTypes.Email]: 'text-field',

        [SchemaFormModelTypes.Date]: 'date-field',
        [SchemaFormModelTypes.DateRange]: 'date-field',
        [SchemaFormModelTypes.DateTime]: 'date-field',

        [SchemaFormModelTypes.Upload]: 'upload-field',
      }

      const elementName = fields[data.type]

      if (!elementName) {
        return
      }

      if (data.children || data.nested_schema) {
        const nestedElements = {}
        Object.entries(data.children || data.nested_schema).forEach(([key, childData]) => {
          nestedElements[key] = this.setElementProps({ ...childData, key, model: data.model })
        })

        if (data.children) {
          data.children = nestedElements
        } else {
          data.nested_schema = nestedElements
        }

        // eslint-disable-next-line consistent-return
        return { elementName, ...data, value: data.model[data.key] }
      }

      const path = data.key.split('.')
      // eslint-disable-next-line consistent-return
      return { elementName, ...data, value: R.path(path, data.model) }
    },
    /**
     * The reason why we created this method and did not use the next one (handleSubmit) is that,
     * for shortcuts, we currently check whether the selector is a valid Python identifier or not
     * (see handleApplyChangesShortcut). If it is, we submit the changes; otherwise, we don't.
     * That logic is not implemented in handleSubmit, which is used in multiple places throughout the app.
     * @returns {Promise<Boolean>} - true if the validation of the form is successful, false otherwise
     */
    async validateForm() {
      try {
        this.showFormError = false

        if (!this.isSchemaValid()) throw new Error('Schema is not valid')

        await this.$refs.form.validate()
        return true
      } catch (error) {
        this.showFormError = true
        if (typeof this.elementsData?.validateFailFunction === 'function') {
          this.elementsData?.validateFailFunction()
        }
        return false
      }
    },
    async handleSubmit(submitRegardless) {
      try {
        this.showFormError = false

        if (!this.isSchemaValid()) throw new Error('Schema is not valid')

        await this.$refs.form.validate()

        if (submitRegardless) {
          this.elementsData.submitRegardlessFunction(this.ruleModel)
        } else {
          this.elementsData.submitFunction(this.ruleModel)
        }

        return { 'isValid': true }
      } catch (error) {
        this.showFormError = true
        if (typeof this.elementsData?.validateFailFunction === 'function') {
          this.elementsData?.validateFailFunction()
        }
        return { 'isValid': false, 'error': error }
      }
    },
    isSchemaValid() {
      const submittedData = this.ruleModel
      for (const property of Object.values(this.requiredProperties)) {
        const propertyValue = get(submittedData, property)
        const isPropertySubmitted = !R.isNil(propertyValue)
        if (!isPropertySubmitted) return false
        const isPropertyEmpty = R.isEmpty(propertyValue)
        if (isPropertyEmpty) return false
      }
      return true
    },
    createValidator(path, value) {
      return (rule, newValue, callback) => {
        // eslint-disable-next-line no-restricted-globals
        const minLength = isNaN(value.minLength) ? undefined : parseInt(value.minLength, 10)
        // eslint-disable-next-line no-restricted-globals
        const maxLength = isNaN(value.maxLength) ? undefined : parseInt(value.maxLength, 10)

        newValue = get(this.ruleModel, path)
        if (this.isRequiredRuleError(value, newValue, callback)) {
          callback(new Error(this.i18n.t('validation.isRequired', { property: translateSchemaItem(this.$i18n.locale, value.title) })))
          return
        }

        if (this.isMinLengthRuleError(value, newValue, minLength)) {
          callback(new Error(this.i18n.t('validation.minLength', { property: translateSchemaItem(this.$i18n.locale, value.title), minLength })))
          return
        }

        if (this.isMaxLengthRuleError(value, newValue, maxLength)) {
          callback(new Error(this.i18n.t('validation.maxLength', { property: translateSchemaItem(this.$i18n.locale, value.title), maxLength })))
          return
        }

        if (this.isPatternError(value, newValue)) {
          callback(new Error(`${translateSchemaItem(this.$i18n.locale, value?.regexErrorMessage)}`))
          return
        }

        callback()
      }
    },
    /* eslint-disable-next-line sonarjs/cognitive-complexity */
    createRulesForSchemaEntry(obj, path = '', searchPath = '') {
      if (!obj) return
      for (const [key, value] of Object.entries(obj)) {
        const currentElementPath = searchPath === '' ? `${key}` : `${searchPath}.${key}`
        const currentElementType = get(this.elementsData.uiFormSchema, currentElementPath)?.type

        const isNested = currentElementType === SchemaFormModelTypes.NestedObject
        const isExpand = currentElementType === SchemaFormModelTypes.ExpandingList
        const currentPath = path === '' ? `${key}` : `${path}.${key}`
        let uiFormSchemaPath = searchPath

        if (isExpand) {
          value.forEach((item, index) => {
            const newSearchPath = searchPath === '' ? `${key}.nested_schema` : `${searchPath}.${key}.nested_schema`
            const isPropertyRequired = get(this.elementsData.uiFormSchema, currentElementPath)?.required
            if (isPropertyRequired) this.requiredProperties[key] = currentPath
            this.createRulesForSchemaEntry(item, `${currentPath}[${index}]`, newSearchPath)
          })
        } else if (isNested) {
          const newSearchPath = searchPath === '' ? `${key}.children` : `${searchPath}.${key}.children`
          this.createRulesForSchemaEntry(obj[key], currentPath, newSearchPath)
        } else {
          uiFormSchemaPath = searchPath === '' ? `${key}` : `${searchPath}.${key}`
        }

        const uiElementSchema = get(this.elementsData.uiFormSchema, uiFormSchemaPath)
        const isValuePresent = get(this.validationRules, currentPath)

        if (uiElementSchema && !isNested && !isExpand || !isValuePresent) {
          set(this.validationProps, currentPath, `${currentPath}`)
          set(this.validationRules, currentPath, {
            required: uiElementSchema?.required && !this.shouldIgnoreRequiredRule,
            trigger: 'change',
            validator: this.createValidator(currentPath, uiElementSchema),
          })
        }
      }
    },
    isRequiredRuleError(value, modelValue) {
      if (!value?.required || this.shouldIgnoreRequiredRule) {
        return false
      }
      return (
        (typeof modelValue !== 'number' && !modelValue) ||
        (R.is(Array, modelValue) && R.isEmpty(modelValue))
      )
    },
    isMinLengthRuleError(value, modelValue, minLength) {
      if (!minLength) {
        return false
      }
      if (!modelValue) {
        return true
      }
      return modelValue.length < minLength
    },
    isMaxLengthRuleError(value, modelValue, maxLength) {
      if (!maxLength || !modelValue || modelValue.length === 0) {
        return false
      }
      return modelValue.length > maxLength
    },
    isPatternError(value, modelValue) {
      if (!value.pattern) {
        return false
      }

      const validationPattern = new RegExp(value.pattern.slice(1, -1))
      return !validationPattern.test(modelValue)
    },
    async onValidationRulesChange() {
      this.validationRules = {}
      this.validationProps = {}
      this.createRulesForSchemaEntry(this.ruleModel)
    },

    removeCustomField(elementTitle) {
      this.$emit('remove-custom-field', elementTitle)
    },
  },
}
</script>

<style lang="scss" scoped>

.comp-schema-form-renderer {
  min-width: 320px;
}

.submit-section{
  margin-top:25px;
  display: flex;
  justify-content: flex-start
}

.alignRight {
  margin-left: auto;
}

.alignLeft {
  margin-right: auto;
}

.tooltip-hover {
  margin-left: 5px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
.el-form-item.is-error {
  padding-bottom: 15px;
}
</style>
