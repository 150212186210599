<template>
  <el-alert v-if="!isGraphQlAutocompleteAllowed" title="Enable authorization for GraphQL autocomplete" type="warning" />
  <div ref="codemirrorEditor" class="editor" />
</template>

<script>
import * as Sentry from '@sentry/vue'
import { buildClientSchema, buildSchema, GraphQLNamedType } from 'graphql'
import { EditorState, Extension, EditorSelection, Compartment, Transaction, Text } from '@codemirror/state'
import { EditorView, keymap, lineNumbers, highlightActiveLineGutter, placeholder, highlightActiveLine } from '@codemirror/view'
import { defaultHighlightStyle, syntaxHighlighting, codeFolding, bracketMatching, foldGutter, foldKeymap, StreamLanguage } from '@codemirror/language'
import { defaultKeymap, history, historyKeymap, indentWithTab } from '@codemirror/commands'
import { searchKeymap, search, openSearchPanel, highlightSelectionMatches } from '@codemirror/search'
import { lintKeymap, linter, lintGutter } from '@codemirror/lint'
import { closeBrackets, closeBracketsKeymap } from '@codemirror/autocomplete'
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { python } from '@codemirror/lang-python'
import { sql, PostgreSQL, SQLDialectSpec } from '@codemirror/lang-sql'
import { html } from '@codemirror/lang-html'
import { css } from '@codemirror/lang-css'
import { jinja2 } from '@codemirror/legacy-modes/mode/jinja2'
import { graphql } from 'cm6-graphql'
import { connectorApi } from '@/ui/api/connector'
import { LanguageMode, EDITOR_COMPONENT_NAME } from '@/ui/components/Editor/const'
import { baseTheme, elementPlusInputTheme } from '@/ui/components/Editor/theme'

const Emits = {
  /** Exists for when we only need to react to input change on the parent */
  UPDATE_MODEL_VALUE: 'update:modelValue',
  CARET_UPDATED: 'caretUpdated',
  FOCUS: 'focus',
  BLUR: 'blur',
  EXECUTE_KEYS: 'execute-keys',
  /** Emits language specific additional autocomplete options for suggestions */
  ADDED_AUTOCOMPLETE_OPTION: 'added-autocomplete-options',
}

export default {
  name: EDITOR_COMPONENT_NAME,
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    /** `mode` was used to determine the language used by codemirror v5.
     * Our users have defined `mode` in various input fields (like a Connector Action).
     * We will continue using it and `LanguageMode` as the keyword for language.
     * @see {LanguageMode}
     */
    mode: {
      type: String,
      required: true,
    },
    lint: {
      type: Boolean,
      default: false,
    },
    lineWrapping: {
      type: Boolean,
      default: false,
    },
    /** Whether we should display the code editor similar to element-plus input */
    isInputUi: {
      type: Boolean,
      default: false,
    },
    /** This only disables editing it through events. DOM editing disabling is not implemented. */
    disabled: {
      type: Boolean,
      default: false,
    },
    currentConnector: {
      type: Object,
      default: null,
    },
    selectedConnectorAuth: {
      type: String,
      default: null,
    },
  },
  emits: [
    Emits.UPDATE_MODEL_VALUE,
    Emits.CARET_UPDATED,
    Emits.FOCUS,
    Emits.BLUR,
    Emits.EXECUTE_KEYS,
    Emits.ADDED_AUTOCOMPLETE_OPTION,
  ],
  data() {
    return {
      componentName: this.$options.name,
      graphqlSchema: null,
      /** @type {EditorView} */
      editor: null,
      editorReadOnly: {
        compartment: new Compartment(),
        extension: (disabled) => EditorState.readOnly.of(disabled),
      },
      editorLint: {
        compartment: new Compartment(),
        extension: () => null,
      },
      editorMultilineUi: {
        compartment: new Compartment(),
        extension: (showExtensions) => (showExtensions ? [
          lineNumbers(),
          foldGutter({ openText: '\u25BE', closedText: '\u25B8' }),
          highlightActiveLineGutter(),
        ] : []),
      },
    }
  },
  computed: {
    requiresConnectorAuth() {
      return this.currentConnector?.external_schema_config?.use_connector_auth === true
    },
    isGraphQlAutocompleteAllowed() {
      return !this.requiresConnectorAuth || this.selectedConnectorAuth
    },
  },
  watch: {
    disabled(disabled) {
      this.editor.dispatch({
        effects: this.editorReadOnly.compartment.reconfigure(this.editorReadOnly.extension(disabled)),
      })
    },
    lint(value) {
      this.editor.dispatch({
        effects: this.editorLint.compartment.reconfigure(value ? this.editorLint.extension() : []),
      })
    },
    selectedConnectorAuth() {
      this.initializeEditor()
    },
  },
  mounted() {
    this.initializeEditor()
  },
  /** @see {EditorExposed} */
  expose: ['componentName', 'updateEditor', 'forceFocusEditor', 'triggerSearch', 'insertText', 'getState'],
  methods: {
    /**
     * A null `selection` means we don't care where the caret will be placed at, so we don't update it.
     * A null `input` means we want to reset it to its default value.
     * @type {EditorExposed.UpdateEditor}
     * @param {EditorUpdateModel} updateModel
     */
    updateEditor(updateModel) {
      const normalizedValue = updateModel.input ?? this.defaultValue
      /** @type {Transaction} */
      const transaction = {
        changes: { from: 0, to: this.editor.state.doc.length, insert: normalizedValue },
      }

      if (updateModel.selection) {
        transaction.selection = EditorSelection.cursor(updateModel.selection.to)
      }

      this.editor.dispatch(transaction)
    },
    /**
     * We need to use `setTimeout` to forcefully focus it in case the focus is removed by other events first.
     * For example clicking an autocomplete option with the mouse would focus us out, we want to focus after the click.
     * @type {EditorExposed.ForceFocusEditor}
     */
    forceFocusEditor() {
      setTimeout(() => this.editor.focus(), 0)
    },
    /** @type {EditorExposed.TriggerSearch} */
    triggerSearch() {
      openSearchPanel(this.editor)
    },
    /** @type {EditorExposed.InsertText} */
    insertText(text, selection) {
      const caretPos = this.editor.state.selection.main.head

      const from = selection?.from ?? caretPos
      const to = selection?.to ?? caretPos
      /** @type {Transaction} */
      const transaction = {
        changes: { from, to, insert: text },
        selection: EditorSelection.cursor(from + text.length),
      }

      /**
       We must have insertText in a timeout because otherwise clicking the scrollbar and selecting an option
       results in a `Calls to EditorView.update are not allowed while an update is in progress` error.
      */
      setTimeout(() => {
        this.editor.dispatch(transaction)
      }, 0)
    },
    /** @type {EditorExposed.GetState} */
    getState() {
      const caretPos = this.editor.state.selection.main
      const editorText = this.editor.state.doc.toString()
      return { selection: { from: caretPos.from, to: caretPos.to }, input: editorText }
    },
    async initializeEditor() {
      this.editor?.destroy()

      const initialDoc = Text.of((this.modelValue ?? this.defaultValue).split('\n'))

      this.editor = new EditorView({
        doc: initialDoc,
        extensions: [
          keymap.of([
            ...closeBracketsKeymap,
            ...defaultKeymap,
            ...searchKeymap,
            ...lintKeymap,
            ...historyKeymap,
            ...foldKeymap,
            indentWithTab,
          ]),
          syntaxHighlighting(defaultHighlightStyle),
          bracketMatching(),
          this.isInputUi ? [EditorView.lineWrapping, elementPlusInputTheme] : [
            baseTheme,
            await this.getModeExtensions(this.mode),
            history(),
            codeFolding(),
            search({ top: true }),
            highlightSelectionMatches(),
            highlightActiveLine(),
          ],
          this.editorMultilineUi.compartment.of(this.editorMultilineUi.extension(this.shouldShowMultilineUiExtensions(initialDoc))),
          placeholder(this.placeholder),
          this.lineWrapping ? EditorView.lineWrapping : [],
          closeBrackets(),
          this.myUpdateListener(),
          this.editorReadOnly.compartment.of(this.editorReadOnly.extension(this.disabled)),
        ],
        parent: this.$refs.codemirrorEditor,
      })
    },
    myUpdateListener() {
      return EditorView.updateListener.of(update => {
        if (update.focusChanged) {
          this.$emit(update.view.hasFocus ? Emits.FOCUS : Emits.BLUR)
        }

        const currentCaretPosition = update.state.selection.main.head
        const previousCaretPosition = update.startState.selection.main.head
        const inputValue = update.state.doc.toString()

        if (update.docChanged) {
          this.$emit(Emits.UPDATE_MODEL_VALUE, inputValue)
          this.editor.dispatch({
            effects: this.editorMultilineUi.compartment.reconfigure(
              this.editorMultilineUi.extension(this.shouldShowMultilineUiExtensions(update.state.doc)),
            ),
          })
        }

        const hasCaretPositionChanged = update.docChanged || previousCaretPosition !== currentCaretPosition
        if (hasCaretPositionChanged) {
          this.$emit(Emits.CARET_UPDATED)
        }
      })
    },
    /**
     * If mode is not found defaults to providing extensions for `Jinja2`
     * @param {string} mode - a mode from `LanguageMode`
     * @returns {Promise<Array<Extension>>} Resolves to an array of extensions required by the mode specified.
     */
    async getModeExtensions(mode) {
      if (mode === LanguageMode.GraphQl) {
        const defaultSchema = 'type Query { defaultField: String }'
        const graphQlSchema = await this.getGraphQlSchema() ?? buildSchema(defaultSchema)
        const graphQlAutocompleteOptions = this.getGraphQlAutocompleteOptions(graphQlSchema?.getTypeMap())
        this.$emit(Emits.ADDED_AUTOCOMPLETE_OPTION, graphQlAutocompleteOptions)

        return [graphql(graphQlSchema), lintGutter()]
      }
      if (mode === LanguageMode.Css) return [css()]
      if (mode === LanguageMode.Html) return [html()]
      if (mode === LanguageMode.Python) return [python()]
      if (mode === LanguageMode.Json) {
        this.editorLint.extension = () => linter(jsonParseLinter())
        const defaultLintExtension = this.lint ? this.editorLint.extension() : []
        return [json(), this.editorLint.compartment.of(defaultLintExtension), lintGutter()]
      }
      if (mode === LanguageMode.Sql || mode === LanguageMode.CustomSql) {
        const executeKeymap = keymap.of([
          {
            key: 'Meta-Enter', run: (editor) => {
              this.$emit(Emits.EXECUTE_KEYS)
              return true
            },
          },
        ])

        const SqlAutocompleteOptions = this.getSqlAutocompleteOptions(PostgreSQL.spec)
        this.$emit(Emits.ADDED_AUTOCOMPLETE_OPTION, SqlAutocompleteOptions)

        return [sql({ dialect: PostgreSQL }), executeKeymap]
      }

      return [StreamLanguage.define(jinja2)]
    },
    /** @param {Object.<string, GraphQLNamedType>} graphQlOptions */
    getGraphQlAutocompleteOptions(graphQlOptions) {
      const autocompleteOptions = []
      for (const key in graphQlOptions) {
        if (!Object.hasOwnProperty.call(graphQlOptions, key)) continue
        const graphQlOption = graphQlOptions[key]

        /** @type {AutocompleteOption} */
        const autocompleteOption = {
          function_name: graphQlOption.name,
          icon: 'documentation', // TODO: Make suggestion render different icon according to instance `suggestion.constructor.name`
          desc: graphQlOption.description,
          fields: this.getOptionFields(graphQlOption),
        }
        /* TODO: for each value of `options.getValues()` add it as a path from the current option.
        Original option could be an enum, and the value would be one of the new options. */
        autocompleteOptions.push(autocompleteOption)
      }
      return autocompleteOptions
    },
    /** @param {GraphQLNamedType} option */
    getOptionFields(option) {
      const hasFields = typeof option.getFields === 'function'
      if (!hasFields) return undefined

      const optionFields = []
      const fields = option.getFields()
      for (const key in fields) {
        if (!Object.hasOwnProperty.call(fields, key)) continue
        const field = fields[key]
        /** @type {AutocompleteOptionField} */
        const suggestionField = { name: field.name, desc: field.description }
        optionFields.push(suggestionField)
      }
      return optionFields
    },
    async getGraphQlSchema() {
      if (!this.isGraphQlAutocompleteAllowed) return null

      let data
      try {
        data = (await connectorApi.getGraphqlSchema(this.currentConnector?.id, { connectorAuthId: this.selectedConnectorAuth }))?.data?.data
        if (!data) {
          const err = new Error(`Failed to load schema for connectorId: ${this.currentConnector?.id}`)
          Sentry.captureException(err)
          return null
        }
      } catch (error) {
        // handled globally
      }

      return buildClientSchema(data)
    },
    /** @param {SQLDialectSpec} sqlSpec */
    getSqlAutocompleteOptions(sqlSpec) {
      /** @type {Array<AutocompleteOption>} */
      const keywords = sqlSpec.keywords.split(' ').map(keyword => ({
        function_name: keyword,
        icon: 'key',
        desc: 'SQL Keyword',
        rank: 2,
      }))

      /** @type {Array<AutocompleteOption>} */
      const types = sqlSpec.types.split(' ').map(type => ({
        function_name: type,
        icon: 'letter-t',
        desc: 'SQL Type',
        rank: 1,
      }))

      return types.concat(keywords)
    },
    /**
     * @param {Text} doc
     * @returns {Boolean}
     */
    shouldShowMultilineUiExtensions(doc) { return !this.isInputUi || doc.lines > 1 },
  },
}
</script>

<style scoped lang="scss">
.editor {
  height: 100%;
  width: 100%;
}
</style>
