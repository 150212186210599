import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export function useTagsNavigation() {
  const router = useRouter()
  const store = useStore()

  /**
   * If there is a tag in the TagsView already opened for this route, we reuse the existing query and params for it.
   * @param {String} routeName
   * @returns {Object} Route location object
   */
  const getTagRouteLocation = (routeName) => {
    const existingTag = store.state.tagsView.visitedViews.find(
      tag => tag.name === routeName,
    )

    return {
      name: routeName,
      query: existingTag?.query,
      params: existingTag?.params,
    }
  }

  const navigateToTagRoute = (routeName) => {
    const routeLocation = getTagRouteLocation(routeName)
    router.push(routeLocation)
  }

  return {
    getTagRouteLocation,
    navigateToTagRoute,
  }
}
