export const LanguageMode = {
  Css: 'text/css',
  GraphQl: 'graphql',
  Html: 'text/html',
  Jinja2: '',
  Json: 'application/json',
  Python: 'python',
  Sql: 'sql',
  /**
   * @deprecated The same as `LanguageMode.Sql`, should discourage clients from using this.
   * However, we still need it because it exists in some configurations. */
  CustomSql: 'custom-sql',
}

export const EDITOR_COMPONENT_NAME = 'Editor'
