import { DialogStatusEnum } from '@/ui/consts/global'

const create = {
  title: 'Env Variable erstellen',
  notification: {
    title: 'Erstellt',
    message: 'Env Variable wurde erstellt',
  },
}

const update = {
  title: 'Env Variable editieren',
  notification: {
    title: 'Gespeichert',
    message: 'Env Variable wurde gespeichert',
  },
}

export const envVariableDialog = {
  [DialogStatusEnum.create]: create,
  [DialogStatusEnum.update]: update,
  variable: 'Env Variable als JSON Objekt',
  advancedView: 'Fortgeschrittene Ansicht',
  basicView: {
    addNewLine: 'Neue Werte Hinzufügen',
    textType: 'Text',
    otherType: 'Zahlen und andere',
  },
}
