export default {
  signIn: {
    header: 'Login Locoia',
  },
  signUp: {
    header: 'Sign Up Locoia',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    username: 'Username (Email again)',
    phoneNumber: 'Phone number',
    password: 'Password',

  },
  forgotPassword: {
    header: 'Forgot Password',
  },
  confirmSignIn: {
    confirmSmsCode: 'Confirm SMS Code',
    unknownNumber: 'Unknown number',
    verificationCodeSentTo: 'A message with a verification code has been sent to {phoneNumber}',
  },
  confirmSignUp: {
    header: 'Confirm Sign Up',
  },
}
