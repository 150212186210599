<template>
  <el-tooltip
    ref="tooltip"
    :visible="isVisible"
    effect="light"
    :popper-class="popperClass"
    placement="bottom"
    :teleported="false"
    :offset="20"
  >
    <template #default>
      <span
        ref="markerRef"
        class="caret-marker"
      />
    </template>
    <template #content>
      <div
        v-for="(entry, index) in data"
        :id="`entry_${index}`"
        :key="entry.function_name + index"
        :ref="`entry_${index}`"
        class="autosuggest__entry"
        :class="{ 'focused': index === focusedSuggestionIndex }"
        tabindex="-1"
        :data-test="`autocomplete-suggestion_${index}`"
        @mousedown.left="selectSuggestion(index)"
      >
        <div class="autosuggest__connector-img-wrapper autosuggest__entry-title">
          <icon
            v-if="entry.icon"
            class="autosuggest__connector-svg-icon"
            :icon="entry.icon"
          />
          <img
            v-else
            class="autosuggest__connector-image"
            :src="getImagePath(entry.connectorId)"
            alt="Connector logo"
          >
          <div>
            <b class="autosuggest__entry__break-word">{{ entry.function_name }}</b>
          </div>
        </div>

        <i class="autosuggest__entry-desc">
          {{ entry.desc }}
        </i>

        <div v-if="entry.fields">
          <div
            v-for="(field, fieldIndex) in entry.fields"
            :key="field.name + fieldIndex"
            class="autosuggest__field"
          >
            <div>{{ field.name }}</div>
            <i>{{ field.desc }}</i>
          </div>
        </div>

        <a
          v-if="entry.link"
          target="_blank"
          style="color:blue;"
          :href="entry.link"
          @mousedown.left.stop
        >
          {{ i18n.t('learnMore') }}
        </a>
      </div>
    </template>
  </el-tooltip>
</template>

<script>
/* eslint-disable sonarjs/no-duplicate-string */
import { ComponentPublicInstance, PropType } from 'vue'
import * as R from 'ramda'
import * as VueScrollTo from 'vue-scrollto'
import { mapGetters } from 'vuex'

export default {
  name: 'AutocompleteList',
  inject: ['i18n'],
  props: {
    data: {
      type: Array,
      default: null,
    },
    remoteSearch: {
      type: Boolean,
      default: false,
    },
    currentConnector: {
      type: Object,
      default: () => ({}),
    },
    /** @type {PropType<ComponentPublicInstance>} */
    containerRef: {
      type: Object,
      required: true,
    },
    /** @type {PropType<EditorExposed>} */
    fieldRef: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'select-autosuggest',
    'close',
  ],
  data() {
    const isEmbed = process.env.APPLICATION_TYPE === 'Embed'

    return {
      /** Must be null by default, which is none selected.
       * We don't automatically focus the first option (0) so that "enter" by default can ignore suggestions and move to next line. */
      focusedSuggestionIndex: null,
      popperElementMaxHeight: isEmbed ? 230 : 250,
      isEmbed,
    }
  },
  computed: {
    ...mapGetters('connector', ['getById']),
    popperClass() { return this.isEmbed ? 'autosuggest sfr-embed__auto-complete-list' : 'autosuggest sfr-main__auto-complete-list' },
  },
  watch: {
    data() {
      this.updatePosition()
    },
  },
  async mounted() {
    this.updatePosition()

    // We need to attach listeners to the document so that we listen to clicks outside of the tooltip as well
    document.addEventListener('click', this.handleInternalClick)
    document.addEventListener('keydown', this.handleKeyboardActions, true)
    document.documentElement.style.setProperty('--autocomplete-list-max-height', `${this.popperElementMaxHeight}px`)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleInternalClick)
    document.removeEventListener('keydown', this.handleKeyboardActions, true)
  },
  methods: {
    /**
     * ## Problem
     * The suggestion's tooltip would close when the user clicked on the scrollbar.
     * ## Solution
     * Only close the tooltip if the click is done outside of the tooltip and field.
     */
    handleInternalClick(event) {
      const autosuggest = document.querySelector(`.autosuggest`)
      const isClickedInsideTooltip = !!autosuggest?.contains(event.target)
      const isClickedInsideField = this.fieldRef?.$el?.parentElement?.contains(event.target)

      if (!isClickedInsideTooltip && !isClickedInsideField) {
        event.stopPropagation()
        this.$emit('close')
      }
    },
    closeTooltip() { this.$emit('close') },
    getImagePath(connectorId) {
      if (this.remoteSearch) {
        return this.currentConnector?.image_url
      }
      return this.getById(connectorId)?.image_url || 'https://dicont.s3.amazonaws.com/static/flow-logos/function-logo.svg'
    },
    /**
    * Updates the position of the popup window to be just above, or just below the caret by calculating where the center of the input element is.
    */
    async updatePosition() {
      await this.$nextTick()

      const fieldRefElement = this.fieldRef?.$el || this.fieldRef
      if (R.isNil(fieldRefElement)) return // there is no field selected

      // We get the rect values of the field itself (code editor)
      const containerRefElement = this.containerRef?.$el || this.containerRef
      const containerRect = containerRefElement.getBoundingClientRect()

      const caretNodeDetails = this.getCaretNodeDetails()
      /** Will be greater the more multilines there are in the editor */
      const containerToCaretElementDistance = caretNodeDetails.domRect.top - containerRect.top
      /** We need the center of the element where the caret is placed so that the popup has the same distance when shown at the top or bottom of caret */
      const halfCaretElementSize = caretNodeDetails.domRect.height / 2
      const elementCenter = containerToCaretElementDistance + halfCaretElementSize

      /** The marker is what the tooltip attaches to */
      const markerRef = this.$refs.markerRef
      if (markerRef) {
        markerRef.style.top = `${elementCenter}px`
      }
    },
    /**
    * @typedef {Object} CaretNodeDetails
    * @property {DOMRect} domRect - The DOMRect representing the bounding box of the node.
    * @property {HTMLElement} element - The element where caret is located.
    * @returns {CaretNodeDetails}
    */
    getCaretNodeDetails() {
      const caretRange = document.getSelection().getRangeAt(0)
      const isElement = caretRange.endContainer.nodeType === Node.ELEMENT_NODE
      const endNode = isElement ? caretRange.endContainer : caretRange.endContainer.parentElement
      return { element: endNode, domRect: endNode.getBoundingClientRect() }
    },
    /* eslint-disable sonarjs/cognitive-complexity */
    /**
     * since we're adding listeners to the document, we want to use stopImmediatePropagation where necessary
     * to prioritize handling the event only here and not in other listeners
     */
    handleKeyboardActions(e) {
      const { key } = e

      if (key === 'Escape') {
        e.stopImmediatePropagation()
        e.preventDefault()
        this.$emit('close')
      } else if (key === 'ArrowUp' || key === 'ArrowDown') {
        e.preventDefault()
        const lastIndex = this.data.length - 1
        const cycleBack = this.focusedSuggestionIndex === null || this.focusedSuggestionIndex === (key === 'ArrowUp' ? 0 : lastIndex)

        if (key === 'ArrowUp') {
          this.focusedSuggestionIndex = cycleBack ? lastIndex : this.focusedSuggestionIndex - 1
        }

        if (key === 'ArrowDown') {
          this.focusedSuggestionIndex = cycleBack ? 0 : this.focusedSuggestionIndex + 1
        }

        const refKey = `entry_${this.focusedSuggestionIndex}`
        VueScrollTo.scrollTo(`#${refKey}`, 1, { container: '.el-popper.autosuggest', force: false })
      } else if (key === 'Tab') {
        e.preventDefault()
        const index = this.focusedSuggestionIndex || 0
        this.selectSuggestion(index)
      } else if (key === 'Enter') {
        const hasFocusedOption = this.data[this.focusedSuggestionIndex]?.function_name
        if (hasFocusedOption) {
          e.preventDefault()
          this.selectSuggestion(this.focusedSuggestionIndex)
        }
      }
    },
    /** After selecting, our focus should be reset in case the list doesn't close. This happens for looper selections. */
    selectSuggestion(index) {
      this.$emit('select-autosuggest', this.data[index].function_name)
      this.focusedSuggestionIndex = null
    },
  },
}
</script>

<style lang="scss">
/** `.autosuggest` cannot be scoped as it is passed into el-tooltip which gets rendered outside of this element */
.autosuggest {

  /** Styles that should only apply when using Main application */
  &.sfr-main__auto-complete-list {
    width: 100%;
  }

  /** Styles that should only apply when using Embed application */
  &.sfr-embed__auto-complete-list {
    width: 350px;
  }

  max-height: var(--autocomplete-list-max-height); // Without a height, the tooltip expands idefinitely
  overflow-y: auto;
  box-shadow: var(--el-box-shadow-light);
  color: var(--el-text-color-regular);

  &__connector-img-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }

  &__connector-image {
    height: 28px;
    margin-right: 5px;
  }

  &__connector-svg-icon {
    height: 28px;
    width: 20px;
    margin-right: 9px;
    margin-left: 3px
  }

  &__field {
    padding: 6px 10px;
  }

  &__entry-title,
  &__entry-desc:not(:empty) {
    padding: 2px 5px;
  }

  &__entry.focused {
    background-color: #ecf5ff;
    color: #66b1ff;
  }

  &__entry {
    cursor: pointer;
    border-bottom: 1px solid #E0E0E0;
    padding: 10px 0;
    transition: background-color 0.3s ease-in-out;
    border-radius: 5px;

    &__break-word {
      word-break: break-word;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }

    &:hover {
      .comp-schemarenderer-autosuggest__entry-title {
        background-color: #eee;
      }
    }
  }

  &__entry:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.caret-marker {
  position: relative;
  display: block;
}
</style>
