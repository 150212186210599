/* eslint-disable sonarjs/no-duplicate-string */
export const shortcuts = {
  Mac: {
    copy_flow: {
      name: 'Copy',
      commands: ['Cmd ⌘', 'C'],
    },
    paste: {
      name: 'Paste',
      commands: ['Cmd ⌘', 'V'],
    },
    undo: {
      name: 'Undo',
      commands: ['Cmd ⌘', 'Z'],
    },
    redo: {
      name: 'Redo',
      commands: ['Cmd ⌘', 'Shift ⇧', 'Z'],
    },
    zoom_in: {
      name: 'Zoom in',
      commands: ['Cmd ⌘', '+'],
    },
    zoom_out: {
      name: 'Zoom out',
      commands: ['Cmd ⌘', '-'],
    },
    select_multiple: {
      name: 'Select multiple',
      commands: ['Shift ⇧', 'Select with cursor 🖱'],
    },
    open_connectors_list: {
      name: 'Open/close Connectors List',
      commands: ['Opt ⌥', 'A'],
    },
    close_connector_sidepanel: {
      name: 'Close Connector Sidepanel',
      commands: ['esc'],
    },
    save_flow_step: {
      name: 'Save changes Sidepanel',
      commands: ['Opt ⌥', 'Enter ↵'],
    },
    run_flow_step: {
      name: 'Run Flow Step from Sidepanel',
      commands: ['Cmd ⌘', 'Enter ↵'],
    },
    show_last_flow_run: {
      name: 'Show last Flow Run from Sidepanel',
      commands: ['Shift ⇧', 'Enter ↵'],
    },
    save: {
      name: 'Save Flow changes',
      commands: ['Opt ⌥<', 'Enter ↵'],
    },
    run: {
      name: 'Run Flow',
      commands: ['Cmd ⌘', 'Enter ↵'],
    },
    debug: {
      name: 'Go to Flow Debugger',
      commands: ['Shift ⇧', 'Enter ↵'],
    },
  },
  Windows: {
    copy_flow: {
      name: 'Copy',
      commands: ['Ctrl / Strg ⌃', 'C'],
    },
    paste: {
      name: 'Paste',
      commands: ['Ctrl / Strg ⌃', 'V'],
    },
    undo: {
      name: 'Undo',
      commands: ['Ctrl / Strg ⌃', 'Z'],
    },
    redo: {
      name: 'Redo',
      commands: ['Ctrl / Strg ⌃', 'Shift ⇧', 'Z'],
    },
    zoom_in: {
      name: 'Zoom in',
      commands: ['Ctrl / Strg ⌃', '+'],
    },
    zoom_out: {
      name: 'Zoom out',
      commands: ['Ctrl / Strg ⌃', '-'],
    },
    select_multiple: {
      name: 'Select multiple',
      commands: ['Shift ⇧', 'Select with cursor 🖱'],
    },
    open_connectors_list: {
      name: 'Open/close Connectors List',
      commands: ['Alt ⎇', 'A'],
    },
    close_connector_sidepanel: {
      name: 'Close Connector Sidepanel',
      commands: ['esc'],
    },
    save_flow_step: {
      name: 'Save changes Sidepanel',
      commands: ['Alt ⎇', 'Enter ↵'],
    },
    run_flow_step: {
      name: 'Run Flow Step from Sidepanel',
      commands: ['Ctrl / Strg ⌃', 'Enter ↵'],
    },
    show_last_flow_run: {
      name: 'Show last Flow Run from Sidepanel',
      commands: ['Shift ⇧', 'Enter ↵'],
    },
    save: {
      name: 'Save Flow changes',
      commands: ['Alt ⎇', 'Enter ↵'],
    },
    run: {
      name: 'Run Flow',
      commands: ['Ctrl / Strg ⌃', 'Enter ↵'],
    },
    debug: {
      name: 'Go to Flow Debugger',
      commands: ['Shift ⇧', 'Enter ↵'],
    },
  },
}

export const codeFieldOnly = {
  Mac: {
    search: {
      name: 'Search',
      commands: ['Cmd ⌘', 'F'],
    },
    find_next: {
      name: 'Find next',
      commands: ['Cmd ⌘', 'G'],
    },
    find_previous: {
      name: 'Find previous',
      commands: ['Cmd ⌘', 'Shift ⇧', 'G'],
    },
    go_to_line: {
      name: 'Go to line',
      commands: ['Opt ⌥', 'G'],
    },
    select_next_occurrence: {
      name: 'Select next occurrence of the current selection.',
      commands: ['Cmd ⌘', 'D'],
    },
    cursor_syntax_left: {
      name: 'Move the cursor over the next syntactic element to the left',
      commands: ['Opt ⌥', 'Arrow Left ←'],
    },
    select_syntax_left: {
      name: 'Move the selection head over the next syntactic element to the left',
      commands: ['Shift ⇧', 'Opt ⌥', 'Arrow Left ←'],
    },
    cursor_syntax_right: {
      name: 'Move the cursor over the next syntactic element to the right',
      commands: ['Opt ⌥', 'Arrow Right →'],
    },
    select_syntax_right: {
      name: 'Move the selection head over the next syntactic element to the right',
      commands: ['Shift ⇧', 'Opt ⌥', 'Arrow Right →'],
    },
    move_line_up: {
      name: 'Move the selected lines up one line',
      commands: ['Opt ⌥', 'Arrow Up ↑'],
    },
    move_line_down: {
      name: 'Move the selected lines down one line',
      commands: ['Opt ⌥', 'Arrow Down ↓'],
    },
    copy_line_up: {
      name: 'Create a copy of the selected lines. Keep the selection in the top copy.',
      commands: ['Shift ⇧', 'Opt ⌥', 'Arrow Up ↑'],
    },
    copy_line_down: {
      name: 'Create a copy of the selected lines. Keep the selection in the bottom copy.',
      commands: ['Shift ⇧', 'Opt ⌥', 'Arrow Down ↓'],
    },
    select_line: {
      name: 'Select line.',
      commands: ['Ctrl ⌃', 'L'],
    },
    select_parent_syntax: {
      name: 'Select the next syntactic construct that is larger than the selection.',
      commands: ['Cmd ⌘', 'I'],
    },
    delete_line: {
      name: 'Delete selected lines',
      commands: ['Shift ⇧', 'Cmd ⌘', 'K'],
    },
    open_lint_panel: {
      name: 'Open lint panel',
      commands: ['Cmd ⌘', 'Shift ⇧', 'M'],
    },
    fold_code: {
      name: 'Fold code, if possible.',
      commands: ['Cmd ⌘', 'Opt ⌥', '['],
    },
    unfold_code: {
      name: 'Unfold code, if possible.',
      commands: ['Cmd ⌘', 'Opt ⌥', ']'],
    },
    indent: {
      name: 'Indent',
      commands: ['Tab ⇥'],
    },
    indent_less: {
      name: 'Indent less',
      commands: ['Shift ⇧', 'Tab ⇥'],
    },
  },
  Windows: {
    search: {
      name: 'Search',
      commands: ['Ctrl / Strg ⌃', 'F'],
    },
    find_next: {
      name: 'Find next',
      commands: ['Ctrl / Strg ⌃', 'G'],
    },
    find_previous: {
      name: 'Find previous',
      commands: ['Ctrl / Strg ⌃', 'Shift ⇧', 'G'],
    },
    go_to_line: {
      name: 'Go to line',
      commands: ['Alt ⎇', 'G'],
    },
    select_next_occurrence: {
      name: 'Select next occurrence of the current selection.',
      commands: ['Ctrl / Strg ⌃', 'D'],
    },
    cursor_syntax_left: {
      name: 'Move the cursor over the next syntactic element to the left',
      commands: ['Alt ⎇', 'Arrow Left ←'],
    },
    select_syntax_left: {
      name: 'Move the selection head over the next syntactic element to the left',
      commands: ['Shift ⇧', 'Alt ⎇', 'Arrow Left ←'],
    },
    cursor_syntax_right: {
      name: 'Move the cursor over the next syntactic element to the right',
      commands: ['Alt ⎇', 'Arrow Right →'],
    },
    select_syntax_right: {
      name: 'Move the selection head over the next syntactic element to the right',
      commands: ['Shift ⇧', 'Alt ⎇', 'Arrow Right →'],
    },
    move_line_up: {
      name: 'Move the selected lines up one line',
      commands: ['Alt ⎇', 'Arrow Up ↑'],
    },
    move_line_down: {
      name: 'Move the selected lines down one line',
      commands: ['Alt ⎇', 'Arrow Down ↓'],
    },
    copy_line_up: {
      name: 'Create a copy of the selected lines. Keep the selection in the top copy.',
      commands: ['Shift ⇧', 'Alt ⎇', 'Arrow Up ↑'],
    },
    copy_line_down: {
      name: 'Create a copy of the selected lines. Keep the selection in the bottom copy.',
      commands: ['Shift ⇧', 'Alt ⎇', 'Arrow Down ↓'],
    },
    select_parent_syntax: {
      name: 'Select the next syntactic construct that is larger than the selection.',
      commands: ['Ctrl / Strg ⌃', 'I'],
    },
    delete_line: {
      name: 'Delete selected lines',
      commands: ['Shift ⇧', 'Ctrl / Strg ⌃', 'K'],
    },
    open_lint_panel: {
      name: 'Open lint panel',
      commands: ['Ctrl / Strg ⌃', 'Shift ⇧', 'M'],
    },
    fold_code: {
      name: 'Fold code, if possible.',
      commands: ['Ctrl / Strg ⌃', 'Alt ⎇', '['],
    },
    unfold_code: {
      name: 'Unfold code, if possible.',
      commands: ['Ctrl / Strg ⌃', 'Alt ⎇', ']'],
    },
    indent: {
      name: 'Indent',
      commands: ['Tab ⇥'],
    },
    indent_less: {
      name: 'Indent less',
      commands: ['Shift ⇧', 'Tab ⇥'],
    },
  },
}

export function getOperatingSystem() {
  const userAgentData = window.navigator.userAgent
  let operatingSystem = ''
  if (userAgentData.match(/Windows/i)) {
    operatingSystem = 'Windows'
  } else if (userAgentData.match(/Macintosh/i)) {
    operatingSystem = 'Mac'
  }

  return operatingSystem
}
