export default {
  loading: 'Loading...',
  step: {
    backToShowMainFlowRunSteps: 'Back to show Steps of the main Flow Run',
    backToShowStepsOfStep: 'Back to show Steps of "{stepName}"',
    hide_errors: 'Reset',
    run_is_scheduled: 'Flow run has been scheduled and will start shortly',
    history_deleted: 'Step history already deleted - as per Flow settings',
    nothing_found: 'No steps found',
    filtering_not_available: 'There are no Flow Run Steps to show, thus filtering is not available.',
    run_again: 'Run again',
    cancel_flow: 'Cancel Flow Run',
    search: 'Search steps...',
    show_errors: 'See Errors',
    title: 'Steps',
    downloadFullLog: 'Download entire result',
    downloadFullInput: 'Download entire input',
    redirectToFlowBuilder: 'Edit in Flow Builder',
    redirectToSuperActionRun: 'Super Action details',
    stepIsBeingProcessed: 'This step is still being processed',
    stepHasFinished: 'The step has finished running',
    selectAFlowRun: 'Select a Flow Run to show Steps',
    showIterations: 'Show iterations',
    redirectToTriggeredFlowRun: 'Go to Triggered run',
  },
  run: {
    hide_errors: 'Reset',
    show_errors: 'See Errors',
  },
  flow_run: {
    cancelFlowRunNotPossible: 'You can only cancel a Flow Run that is currently running',
    flowRunNotSelectedOrNoStepsFound: 'Flow Run is not selected or no steps found in Flow Run',
    title: 'Flow Runs',
    noFlowRuns: 'There are no Flow Runs yet',
    noFlowRunsForSelectedFilter: 'There are no Flow Runs based on the selected filters',
    runAgainTooltip: 'Run the currently selected Flow Run again',
  },
}
