<template>
  <el-form-item class="lo-expand-list-form-item" :class="isEmbed ? 'sfr-embed__expand-list-form-item' : 'sfr-main__expand-list-form-item'">
    <template #label>
      <label>
        {{ elementTitle }}
        <el-tooltip
          v-if="element.info"
          placement="bottom-end"
          :content="elementInfo"
        >
          <icon el-icon="ElIconInfoFilled" class="tooltip-hover" />
        </el-tooltip>
      </label>
    </template>
    <div v-if="isEmbed" class="item-divider" />

    <el-form-item
      v-for="(item, index) in model[element.key]"
      :key="index"
      class="inner-form-item"
    >
      <el-row :gutter="isEmbed ? 5 : 15" class="expand-list-item-row">
        <el-col :span="isEmbed ? 19: 21">
          <form-item
            v-for="(innerValue, innerKey) in item"
            :key="innerKey"
            :expand-list-index="index"
            class="expandListItem"
            :element-key="innerKey"
            :rule-model="model[element.key][index]"
            :validation-props="validationProps[element.key][index]"
            :element="element.nested_schema[innerKey]"
            :original-rule-model="originalRuleModel"
            :selected-connector-auth="selectedConnectorAuth"
            :current-connector="currentConnector"
            @rule-model-change="onRuleChange"
            @field-focused="$emit('fieldFocused', $event)"
          />
        </el-col>

        <el-col v-if="isEmbed" :span="5" class="button-delete">
          <el-button @click="onDeleteEntry(index)">{{ i18n.t('delete') }}</el-button>
        </el-col>
        <el-col v-else :span="3" class="button-delete">
          <el-button
            @click="onDeleteEntry(index)"
          >
            <template #icon>
              <icon el-icon-raw="ElIconDelete" />
            </template>
          </el-button>
        </el-col>
      </el-row>
      <div class="item-divider" />
    </el-form-item>

    <el-button v-if="isEmbed" class="button-add" @click="onCreateEntry">
      <span>{{ i18n.t('add') }}</span>
    </el-button>
    <el-button v-else class="button-add" @click="onCreateEntry">
      <template #icon>
        <icon el-icon-raw="ElIconPlus" />
      </template>
    </el-button>
  </el-form-item>
</template>

<script>
import * as R from 'ramda'
import { useSchemaFormModelHelper } from '../../utils/SchemaFormModelHelper'
import FormItem from '@/ui-libs/schema-form-renderer/components/formItems/FormItem.vue'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

/* eslint-disable vue/require-prop-types */
export default {
  name: 'ExpandListFormItem',
  components: {
    FormItem,
  },
  inject: ['i18n'],
  props: [
    'element',
    'ruleModel',
    'elementKey',
    'validationProps',
    'originalRuleModel',
    'selectedConnectorAuth',
    'currentConnector',
  ],
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  setup() {
    const schemaFormModelHelper = useSchemaFormModelHelper()
    return { schemaFormModelHelper }
  },
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
    }
  },
  computed: {
    elementTitle() {
      return translateSchemaItem(this.$i18n.locale, this.element.title)
    },
    elementInfo() {
      return translateSchemaItem(this.$i18n.locale, this.element.info)
    },
    model: {
      get() {
        return this.ruleModel
      },
      set(newData) {
        const data = newData.value || newData
        this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey })
      },
    },
  },
  methods: {
    onRuleChange({ value, elementKey, expandListIndex }) {
      const updatedModel = R.clone(this.model)
      updatedModel[this.elementKey][expandListIndex][elementKey] = value[elementKey]
      this.model = updatedModel
    },
    onCreateEntry() {
      const newModel = this.schemaFormModelHelper.createFormModel(this.element.nested_schema, null, null, true)
      this.model[this.element.key].push(newModel)
    },
    onDeleteEntry(index) {
      const cloneOfModel = R.clone(this.model)
      cloneOfModel[this.element.key].splice(index, 1)
      this.model = cloneOfModel
    },
  },

}
</script>

<style lang="scss" scoped>
.lo-expand-list-form-item {
  .expandListItem {
    margin-bottom: 10px;
  }

  .button-delete {
    padding-top: 2px;
    text-align: center;
  }

  .button-add {
    margin-left: 10px;
    margin-top: 5px;
  }

  .item-divider {
    padding: 5px 0;
    display: block;
    width: 100%;
    border-bottom: 1px solid lightgray;
  }
}

/** Styles that should only apply when using Main application */
.sfr-main__expand-list-form-item {
  :deep(.el-form-item__content) {
    display: block;
  }

  .inner-form-item {
    padding: 0 15px;
  }

  .button-delete {
    padding-top: 30px;
    text-align: left;
  }

  .button-add {
    margin-left: 30px;
    margin-top: 10px;
  }
}

/** Styles that should only apply when using Embed application */
.sfr-embed__expand-list-form-item {
  :deep(.el-form-item__label) {
    float: none;
  }

  :deep(> .el-form-item__label:first-of-type) {
    width: 100%;
    font-weight: bold;
  }

  .el-form-item {
    display: flex;
  }

  .expand-list-item-row {
    width: 100%;
    padding-left: 5px;
  }

  .item-divider {
    margin-bottom: 5px;
  }
}
</style>
