/** Sometimes we might want to deal with errors in a specific way depending on the severity of the error.
 * This functions exactly as a normal `Error` but allows specifying its severity
 */
export class ExtendedError extends Error {
  /**
   * @param {string} message - The usual `Error` object message
   * @param {ExtendedErrorSeverity} severity - The severity of the error
   */
  constructor(message, severity) {
    super(message)

    this.name = 'ExtendedError'
    this.severity = severity || ExtendedErrorSeverity.ERROR
  }
}

export const ExtendedErrorSeverity = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}
