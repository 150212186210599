import * as R from 'ramda'
import { v4 as uuidv4 } from 'uuid'
import { isObject } from '@/ui/utils/object'

const convertFrom = R.cond([
  [R.is(Array), (list) => ({ value: list.map(String).join(', '), type: 'other' })],
  [R.is(Object), () => { throw Error('Cannot handle nested objects') }],
  [R.is(String), (value) => ({ value: String(value), type: 'string' })],
  [R.T, (value) => ({ value: String(value), type: 'other' })],
])

// Attempt to parse the user's string input by best effort.
const convertTo = (type, value) => {
  if (type === 'string') {
    return value
  }

  // Everything that has a comma will be treated as a list of strings.
  // To input lists of other types use the advanced view.
  if (value.includes(',')) {
    return value.split(',').map((i) => i.trim())
  }

  // Check differently cased bool values.
  const valueAsLower = value.toLowerCase()
  if (['true', 't'].includes(valueAsLower)) {
    return true
  }
  if (['false', 'f'].includes(valueAsLower)) {
    return false
  }

  // Check null/bottom values.
  if (['null', 'none', 'undefined'].includes(valueAsLower) || value === '') {
    return null
  }

  const valueAsNumber = parseFloat(value)
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(valueAsNumber)) {
    return valueAsNumber
  }

  // Any other parsing failed, so let's use the value as a string.
  return value
}

const isFlat = R.pipe(R.values, R.all(R.complement(isObject)))
const isFlatObject = R.both(isObject, isFlat)
const isFlatJsonObjectString = R.tryCatch(R.pipe(JSON.parse, isFlatObject), R.F)

const rowsContainEmptyKey = R.pipe(R.values, R.any(R.propEq('', 'key')))

const pushNewRow = (state, row) => {
  const id = uuidv4()
  state.inputRows.push(id)
  state.inputRowValues[id] = row
  state.rowErrorValues[id] = undefined
}
const recomputeErrors = (state) => {
  const pairs = Object.entries(state.inputRowValues).map(([id, { key }]) => ({ id, key }))
  const groupedByKey = R.map(R.pluck('id'), R.groupBy(R.prop('key'), pairs))
  const isSingleton = R.pipe(R.length, R.equals(1))
  const withMultipleOccurrences = R.unnest(R.values(R.reject(isSingleton, groupedByKey)))

  const resolvedRows = R.difference(Object.keys(state.rowErrorValues), withMultipleOccurrences)
  resolvedRows.forEach((id) => { state.rowErrorValues[id] = undefined })
  withMultipleOccurrences.forEach((id) => { state.rowErrorValues[id] = 'Duplicate row' })
}

export default {
  namespaced: true,
  state: () => ({
    keySearchString: '',
    inputRows: [],
    inputRowValues: {},
    rowErrorValues: {},
    sortField: null,
    sortOrder: null,
  }),
  getters: {
    isEligible: () => R.either(isFlatJsonObjectString, R.equals('')),
    containsEmptyKey: R.propSatisfies(rowsContainEmptyKey, 'inputRowValues'),
    isValid: R.propSatisfies(R.pipe(R.values, R.all(R.isNil)), 'rowErrorValues'),
    jsonString: R.pipe(
      R.prop('inputRowValues'),
      R.values,
      R.map(({ key, value, type }) => [key, convertTo(type, value)]),
      R.fromPairs,
      JSON.stringify,
    ),
    allowSorting: (state) => state.inputRows.length > 1,
    isSortedBy: ({ sortField, sortOrder }) => (field, order) => sortField === field && sortOrder === order,
  },
  mutations: {
    SET_INPUT: (state, data) => {
      state.inputRows = []
      state.inputRowValues = {}
      state.rowErrorValues = {}
      if (data === '') {
        return
      }
      const parsed = JSON.parse(data)
      const insertRow = ([key, value]) => pushNewRow(state, { key, ...convertFrom(value) })
      Object.entries(parsed).forEach(insertRow)
      recomputeErrors(state)
    },
    ADD_ROW: (state) => {
      pushNewRow(state, { key: '', value: '', type: 'string' })
      recomputeErrors(state)
    },
    UPDATE_ROW: (state, { id, field, value }) => {
      state.inputRowValues[id][field] = value
      recomputeErrors(state)
    },
    SORT_BY: (state, { field, order }) => {
      if (order === 'toggle') {
        const reverse = state.sortOrder === 'asc' ? 'desc' : 'asc'
        state.sortOrder = field === state.sortField ? reverse : 'asc'
      } else {
        state.sortOrder = order
      }
      state.sortField = field

      const comparator = (state.sortOrder === 'asc' ? R.ascend : R.descend)(R.prop(state.sortField))
      const wrappedComparator = (id1, id2) => comparator(state.inputRowValues[id1], state.inputRowValues[id2])
      state.inputRows = R.sort(wrappedComparator, state.inputRows)
    },
    REMOVE_ROW: (state, id) => {
      const indexOfRowId = state.inputRows.indexOf(id)
      if (indexOfRowId !== -1) {
        state.inputRows.splice(indexOfRowId, 1)
      }
      delete state.inputRowValues[id]
      delete state.rowErrorValues[id]
      recomputeErrors(state)
    },
  },
}
