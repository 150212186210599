export default {
  all: 'Alle',
  company: 'Firma',
  valueNotSet: 'N/A',
  date_format: 'DD.MM.YYYY',
  date_time_format: 'DD.MM.YYYY HH:mm:ss',
  button: {
    ok: 'Ok',
    submit: 'Bestätigen',
    accept: 'Akzeptieren',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    details: 'Details',
    editName: 'Name editieren',
    stay_here: 'Hierbleiben',
    create: 'Erstellen',
    save: 'Speichern',
    delete: 'Löschen',
    remove: 'Entfernen',
    update: 'Aktualisieren',
    save_and_leave: 'Speichern und verlassen',
    discardChanges: 'Verlassen ohne speichern',
    discardAndLeave: 'Verwerfen und verlassen',
    neverAskAgain: 'Nicht mehr zeigen',
    sync: 'Sync',
  },
  dialog: {
    confirmClose: 'Möchtest du wirklich schließen?',
    success: 'Erfolg',
  },
  no: 'Nein',
  preposition: {
    to: 'zu',
  },
  refresh: 'Aktualisierung',
  settings: 'Einstellungen',
  user: 'Nutzer',
  yes: 'Ja',
}
