import {
  Aim, // -aim
  ArrowDownBold, // -down-big
  Back, // -back
  Calendar, // material-icon calendar_month
  CaretBottom, // -caret-bottom
  CaretTop, // -caret-top
  Check, // -check
  CircleCheck, // -circle-check-outline
  CircleCloseFilled, // -error
  Close, // -close
  CopyDocument, // -copy-document
  DArrowLeft, // -d-arrow-left
  DArrowRight, // -d-arrow-right
  DCaret, // -d-caret
  Delete, // -delete
  Document, // material-icon title
  DocumentAdd, // -document-add
  DocumentCopy, // -document-copy
  Download, // -download
  Edit, // -edit
  Files, // -files
  Filter, // -filter
  FolderOpened, // -folder-opened
  Grid, // -grid
  Help, // -help
  Hide,
  InfoFilled, // -info
  Link, // -link
  Lock, // -lock
  Notebook, // -notebook-2
  Minus, // -minus
  OfficeBuilding, // -office-building
  Operation, // -s-operation
  Picture, // -picture-outline
  Plus, // -plus
  Refresh, // -refresh
  RefreshLeft, // -refresh-left
  RefreshRight, // -refresh-right
  Remove, // -remove
  Search, // -search
  Setting, // material-icon settings
  SetUp, // material-icon calculate
  Share, // -share
  Switch, // -switch
  SwitchButton, // -switch-button
  Tickets, // material-icon functions
  TrendCharts, // -s-marketing
  UploadFilled, // -upload
  User, // -user
  VideoPlay, // -video-play
  VideoCamera, // -video-camera
  View, // -view
  Failed, // -failed
} from '@element-plus/icons-vue'

export default {
  install: (app) => {
    app.config.globalProperties.$icons = {
      Aim,
      ArrowDownBold,
      Back,
      Calendar,
      CaretBottom,
      CaretTop,
      Check,
      CircleCheck,
      CircleCloseFilled,
      Close,
      CopyDocument,
      DArrowLeft,
      DArrowRight,
      DCaret,
      Delete,
      Document,
      DocumentAdd,
      DocumentCopy,
      Download,
      Edit,
      Files,
      Filter,
      FolderOpened,
      Grid,
      Help,
      Hide,
      InfoFilled,
      Link,
      Lock,
      Notebook,
      Minus,
      OfficeBuilding,
      Operation,
      Picture,
      Plus,
      Refresh,
      RefreshLeft,
      RefreshRight,
      Remove,
      Search,
      Setting,
      SetUp,
      Share,
      Switch,
      SwitchButton,
      Tickets,
      TrendCharts,
      UploadFilled,
      User,
      VideoPlay,
      VideoCamera,
      View,
      Failed,
    }

    app.component('ElIconAim', Aim)
    app.component('ElIconArrowDownBold', ArrowDownBold)
    app.component('ElIconBack', Back)
    app.component('ElIconCalendar', Calendar)
    app.component('ElIconCaretBottom', CaretBottom)
    app.component('ElIconCaretTop', CaretTop)
    app.component('ElIconCheck', Check)
    app.component('ElIconCircleCheck', CircleCheck)
    app.component('ElIconCircleCloseFilled', CircleCloseFilled)
    app.component('ElIconClose', Close)
    app.component('ElIconCopyDocument', CopyDocument)
    app.component('ElIconDArrowLeft', DArrowLeft)
    app.component('ElIconDArrowRight', DArrowRight)
    app.component('ElIconDCaret', DCaret)
    app.component('ElIconDelete', Delete)
    app.component('ElIconDocument', Document)
    app.component('ElIconDocumentAdd', DocumentAdd)
    app.component('ElIconDocumentCopy', DocumentCopy)
    app.component('ElIconDownload', Download)
    app.component('ElIconEdit', Edit)
    app.component('ElIconFiles', Files)
    app.component('ElIconFilter', Filter)
    app.component('ElIconFolderOpened', FolderOpened)
    app.component('ElIconGrid', Grid)
    app.component('ElIconHelp', Help)
    app.component('ElIconHide', Hide)
    app.component('ElIconInfoFilled', InfoFilled)
    app.component('ElIconLink', Link)
    app.component('ElIconLock', Lock)
    app.component('ElIconNotebook', Notebook)
    app.component('ElIconMinus', Minus)
    app.component('ElIconOfficeBuilding', OfficeBuilding)
    app.component('ElIconOperation', Operation)
    app.component('ElIconPicture', Picture)
    app.component('ElIconPlus', Plus)
    app.component('ElIconRefresh', Refresh)
    app.component('ElIconRefreshLeft', RefreshLeft)
    app.component('ElIconRefreshRight', RefreshRight)
    app.component('ElIconRemove', Remove)
    app.component('ElIconSearch', Search)
    app.component('ElIconSetting', Setting)
    app.component('ElIconSetUp', SetUp)
    app.component('ElIconShare', Share)
    app.component('ElIconSwitch', Switch)
    app.component('ElIconSwitchButton', SwitchButton)
    app.component('ElIconTickets', Tickets)
    app.component('ElIconTrendCharts', TrendCharts)
    app.component('ElIconUploadFilled', UploadFilled)
    app.component('ElIconUser', User)
    app.component('ElIconVideoPlay', VideoPlay)
    app.component('ElIconVideoCamera', VideoCamera)
    app.component('ElIconView', View)
    app.component('ElIconFailed', Failed)
  },
}
