import * as R from 'ramda'

const hasSomeInCommon = R.pipe(R.intersection, R.isEmpty, R.not)
const isReadPermission = R.propEq('read', 'action')

export default (roles, viewPermissions, requiredAccessRights) => {
  const { roles: requiredRoles, permissionName: viewEntityName } = requiredAccessRights || {}
  const roleRequired = !R.isNil(requiredRoles)
  const permissionRequired = !R.isNil(viewEntityName)
  const hasNoRestriction = !roleRequired && !permissionRequired
  const rolesMatch = roleRequired && hasSomeInCommon(roles, [...requiredRoles, 'admin'])
  const permissionsMatch = permissionRequired && viewPermissions[viewEntityName] &&
    viewPermissions[viewEntityName].some(isReadPermission)
  return hasNoRestriction || rolesMatch || permissionsMatch
}
