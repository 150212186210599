/* eslint-disable sonarjs/no-duplicate-string */
export default {
  accountSecrets: {
    accountId: 'Konto Id',
    defaultGlobalAccount: 'Standard Globales Konto',
    helpfulInformation: 'Wenn mehrere Kunden dieses Einbettung nutzen und ihre eigenen JWT-Token generieren, können Sie unterschiedliche Geheimnisse für bestimmte Kunden erzeugen. Dies ist nützlich, um zu verhindern, dass Kunde A dasselbe Geheimnis verwendet, um auf Einbettungsdaten von Kunde B zuzugreifen.',
    relevantForExternalClients: 'Nur relevant für externe Kunden, die ihre eigenen JWT-Token generieren',
    secret: 'Geheimnis',
    secretGeneratedAfterSaving: 'Ihr Geheimnis wird nach dem Speichern generiert',
    title: 'Konto Geheimnisse',
  },
  actions: 'Aktionen',
  activityLog: 'Activity Log',
  allowedDomains: 'Erlaubte Domains',
  created: 'Erstellt',
  customCSS: 'Benutzerdefiniertes CSS',
  displayOrder: 'Reihenfolge der Anzeige',
  embedGroups: {
    flows: 'Flows',
    addFlow: 'Flow hinzufügen',
    deleteFlow: 'Flow entfernen',
    addGroup: 'Gruppe hinzufügen',
    deleteGroup: 'Gruppe entfernen',
    groupName: 'Gruppen Name',
    group: 'Gruppe',
    groups: 'Gruppen',
    highlights: 'Hervorgehobene Konnektoren',
    highlightedConnector: 'Hervorgehobener Connector',
    sharingSettings: 'Freigabeeinstellungen für Gruppenintegration',
    sharingSettingsInfo: 'Berechtigungen erstrecken sich auf sowohl aktuelle als auch zukünftige {integrations} für diese {group}. Dies beinhaltet die "Kann anzeigen" Berechtigung für {connectorAuths}, die erstellt oder noch erstellt werden {inEmbeddedIframe} für diese Integrationen.',
    syncTooltip: 'Synchronisiert die Flow-Änderungen mit allen bestehenden und zukünftigen Flow-Kopien',
    goToFlow: 'Gehe zum Flow',
  },
  embedPartners: {
    partner: 'Partner',
    partners: 'Partners',
    category: 'Kategorie',
    sharingSettings: 'Freigabeeinstellungen für Partnerintegration',
    sharingSettingsInfo: 'Berechtigungen erstrecken sich auf sowohl aktuelle als auch zukünftige {integrations} für diesen {partner}. Dies beinhaltet die "Kann anzeigen" Berechtigung für {connectorAuths}, die erstellt oder noch erstellt werden {inEmbeddedIframe} für diese Integrationen.',
    useCases: 'Anwendungsfälle',
  },
  inEmbeddedIframe: 'im eingebetteten iframe',
  iframeCode: 'IFrame Code',
  defaultConnector: 'Proprietärer Connector',
  lastUpdated: 'Zuletzt aktualisiert',
  name: 'Name',
  search: 'Suche',
  secretToken: 'Secret token',
  validationFailed: 'Validierung fehlgeschlagen',
  consentScreenEnabled: 'Zustimmung einfordern',
  consentTextDe: 'Benutzerdefinierter Zustimmungstext - Deutsch',
  consentTextEn: 'Benutzerdefinierter Zustimmungstext - Englisch',
  sharingLevel: 'Berechtigungsebene',
  success: 'Erfolg',
  syncSuccess: 'Der Flow wurde erfolgreich synchronisiert',
  runsBasedOnTrigger: 'Wird sofort nach Auslösung ausgeführt',
  integrations: {
    text: 'Integrationen',
    filters: {
      searchPlaceholder: 'Suche mit Name, User ID, Account ID',
      byFlowPlaceholder: 'Embed Flow',
      byConfigNamePlaceholder: 'Konfiguration Name',
      byGroupNamePlaceholder: 'Gruppen Name',
      byPartnerNamePlaceholder: 'Partner Name',
      byUseCaseNamePlaceholder: 'Use Case Name',
    },
    columns: {
      integration: 'Integration',
      status: 'Status',
      latestRunStatus: 'Lezter Ausfürungsstatus',
      nextRunDate: 'Nächste Ausfürung',
      embedFlow: 'Embed Flow',
      configurationId: 'Konfiguration ID',
      configurationName: 'Konfiguration Name',
      groupName: 'Gruppen Name',
      partnerName: 'Partner Name',
      useCaseName: 'Use Case Name',
      userId: 'User ID',
      userName: 'User Name',
      accountId: 'Account ID',
      accountName: 'Account Name',
      sharingLevel: 'Berechtigungsebene',
      actions: 'Aktionen',
      created_date: 'Erstellungsdatum',
      updated_date: 'Zuletzt aktualisiert',
    },
    actions: {
      openInBuilder: 'Zum Flow Builder',
      openInDebugger: 'Zum Flow Debugger',
      rerun: 'Den letzten Run wiederholen',
      disable: 'Deaktiviren',
    },
    messages: {
      rerunSuccess: 'Die Integration wurde erfolgreich wiederholt',
      disableSuccess: 'Die Integration wurde erfolgreich deaktiviert',
      rerunSuccessInBulk: 'Die Integrationen wurden erfolgreich erneut durchgeführt',
      disableSuccessInBulk: 'Die Integrationen wurden erfolgreich deaktiviert',
    },
  },
}
