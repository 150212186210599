<template>
  <el-switch
    v-model="model"
    :placeholder="placeholder"
  />
</template>

<script>
/* eslint-disable vue/require-prop-types */

import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

export default {
  name: 'SwitchField',
  props: [
    'entry',
    'ruleModel',
    'elementKey',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
  ],
  computed: {
    placeholder() {
      return translateSchemaItem(this.$i18n.locale, this.entry.placeholder) || ''
    },
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData,
          expandListIndex: this.expandListIndex,
        })
      },
    },
  },
}
</script>
