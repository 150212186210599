export default {
  accountSecrets: {
    accountId: 'Account Id',
    defaultGlobalAccount: 'Default global account',
    helpfulInformation: 'If multiple clients use this embed and they generate their own JWT tokens, you can generate different secrets for specific clients. This is useful to prevent client A from using the same secret to access embed data from client B.',
    relevantForExternalClients: 'Only relevant for external clients generating their own JWT tokens',
    secret: 'Secret',
    secretGeneratedAfterSaving: 'Your secret will be generated after saving',
    title: 'Account Secrets',
  },
  actions: 'Actions',
  activityLog: 'Activity Log',
  allowedDomains: 'Allowed domains',
  created: 'Created',
  customCSS: 'Custom CSS',
  displayOrder: 'Display order',
  embedGroups: {
    flows: 'Flows',
    addFlow: 'Add Flow',
    deleteFlow: 'Remove Flow',
    addGroup: 'Add Group',
    deleteGroup: 'Remove Group',
    groupName: 'Group Name',
    group: 'Group',
    groups: 'Groups',
    highlights: 'Highlighted connectors',
    highlightedConnector: 'Highlighted connector',
    sharingSettings: 'Sharing Settings for Group Integration',
    sharingSettingsInfo: 'Permissions extend to both current and future {integrations} for this {group}. This includes "Can view" permission for {connectorAuths} created or yet to be created {inEmbeddedIframe} for these Integrations.',
    syncTooltip: 'Syncs the Flow changes to all existing and future flow copies',
    goToFlow: 'Go to Flow',
  },
  embedPartners: {
    partner: 'Partner',
    partners: 'Partners',
    category: 'Category',
    sharingSettings: 'Sharing Settings for Partner Integration',
    sharingSettingsInfo: 'Permissions extend to both current and future {integrations} for this {partner}. This includes "Can view" permission for {connectorAuths} created or yet to be created {inEmbeddedIframe} for these Integrations.',
    useCases: 'Use cases',
  },
  inEmbeddedIframe: 'in the embedded iframe',
  iframeCode: 'IFrame code',
  defaultConnector: 'Proprietary connector',
  lastUpdated: 'Last Updated',
  name: 'Name',
  search: 'Search',
  secretToken: 'Secret token',
  validationFailed: 'Validation failed',
  consentScreenEnabled: 'Ask for consent',
  consentTextDe: 'Custom Consent Text - German',
  consentTextEn: 'Custom Consent Text - English',
  sharingLevel: 'Sharing level',
  success: 'Success',
  syncSuccess: 'The Flow has been synced successfully',
  runsBasedOnTrigger: 'Runs instantly based on trigger',
  integrations: {
    text: 'Integrations',
    filters: {
      searchPlaceholder: 'Search by name, user id, account id',
      byFlowPlaceholder: 'Embed Flow',
      byConfigNamePlaceholder: 'Configuration name',
      byGroupNamePlaceholder: 'Group name',
      byPartnerNamePlaceholder: 'Partner name',
      byUseCaseNamePlaceholder: 'Use case name',
    },
    columns: {
      integration: 'Integration',
      status: 'Status',
      latestRunStatus: 'Latest run status',
      nextRunDate: 'Next run',
      embedFlow: 'Embed Flow',
      configurationId: 'Configuration ID',
      configurationName: 'Configuration name',
      groupName: 'Group name',
      partnerName: 'Partner name',
      useCaseName: 'Use case name',
      userId: 'User Id',
      userName: 'User name',
      accountId: 'Account Id',
      accountName: 'Account name',
      sharingLevel: 'Sharing level',
      actions: 'Actions',
      created_date: 'Created',
      updated_date: 'Last updated',
    },
    actions: {
      openInBuilder: 'Go to Flow Builder',
      openInDebugger: 'Go to Flow Debugger',
      rerun: 'Rerun the latest run',
      disable: 'Disable',
    },
    messages: {
      rerunSuccess: 'The integration was successfully reran',
      disableSuccess: 'The integration was successfully disabled',
      rerunSuccessInBulk: 'The integrations were successfully reran',
      disableSuccessInBulk: 'The integrations were successfully disabled',
    },
  },
}
