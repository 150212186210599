import Layout from '@/ui/layout'

export default {
  path: '/embed',
  component: Layout,
  redirect: { name: 'Embedding' },
  meta: {
    title: 'embed',
    permissionName: 'embed_view',
  },
  children: [
    {
      name: 'Embedding',
      path: 'configurations',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding'),
      meta: {
        title: 'configurations',
        noCache: true,
        permissionName: 'embed_view',
      },
    },
    {
      name: 'EmbeddingIntegrations',
      path: 'integrations',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingIntegrations'),
      meta: {
        title: 'integrations',
        noCache: true,
        permissionName: 'embed_view',
      },
    },
    {
      name: 'EmbeddingConnectorAuths',
      path: 'connector-auth',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingConnectorAuths'),
      meta: {
        title: 'connectorAuth',
        noCache: true,
        permissionName: 'embed_view',
      },
    },
    /** Previously this was the path of activity-log, we want to deprecate this eventually... */
    {
      path: '/embedding/embedding/activity-log',
      redirect: { name: 'EmbeddingActivityLog' },
    },
    {
      name: 'EmbeddingActivityLog',
      path: 'activity-log',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingActivityLog'),
      meta: {
        title: 'activityLog',
        noCache: true,
        permissionName: 'embed_view',
      },
    },
    {
      name: 'EmbedGroups',
      path: ':embedId/groups',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups'),
      meta: {
        title: 'Groups',
        noCache: true,
        permissionName: 'embed_view',
        hidden: true,
      },
      children: [
        {
          name: 'EmbedGroupFlows',
          path: ':groupId/flows',
          component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups/Flows'),
          meta: {
            title: 'Embed Flows',
            noCache: true,
            permissionName: 'embed_view',
            hidden: true,
          },
        },
      ],
    },
    {
      name: 'EmbedPartners',
      path: ':embedId/partners',
      component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Partners'),
      meta: {
        title: 'Partners',
        noCache: true,
        permissionName: 'embed_view',
        hidden: true,
      },
      children: [
        {
          name: 'EmbedUseCases',
          path: ':partnerId/use-cases',
          component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Partners/UseCases'),
          meta: {
            title: 'Use Cases',
            noCache: true,
            permissionName: 'embed_view',
            hidden: true,
          },
          children: [
            {
              name: 'EmbedUseCaseFlows',
              path: ':useCaseId/flows',
              component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups/Flows'),
              meta: {
                title: 'Embed Flows',
                noCache: true,
                permissionName: 'embed_view',
                hidden: true,
              },
            },
          ],
        },
      ],
    },
    // Redirects /embedding/* to /embed/*, preserving the rest of the path
    {
      path: '/embedding/:pathMatch(.*)*',
      redirect: (to) => to.fullPath.replace('/embedding', '/embed'),
    },
  ],
}
