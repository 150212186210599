export default {
  tokens: {
    confirmDeletingToken: 'Sind Sie sicher, dass Sie das Token dauerhaft löschen möchten?',
    confirmRegenerateToken: 'Sind Sie sicher, dass Sie das Token erneuern möchten? Das alte Token wird ungültig.',
    createNewToken: 'Erstelle neues persönliches Zugriffstoken',
    errorCreatingToken: 'Fehler beim Erstellen des Tokens',
    errorDeletingToken: 'Fehler beim Löschen des persönlichen Zugriffstokens!',
    errorRegeneratingToken: 'Fehler beim Erneuern des Tokens!',
    errorUpdatingToken: 'Fehler beim Aktualisieren des persönlichen Zugriffsschlüssels!',
    expiration: 'Verfall',
    expirationDate: 'Verfallsdatum',
    expirationDaysOptions: {
      one: 'Einen Tag',
      seven: 'Sieben Tage',
      thirty: 'Dreißig Tage',
      sixty: 'Sechzig Tage',
      none: 'Kein Ablauf',
    },
    lastUseDate: 'Letztes Verwendungsdatum',
    note: 'Vermerken',
    onlySeeTokenOnceText: 'Sie sehen das Token nur einmal nach der Erstellung.',
    personalAccessTokenCreated: 'Persönliches Zugriffstoken erstellt!',
    personalAccessTokenInfo: 'Persönliche Zugriffstoken können beschränkten Zugriff auf die Locoia-API gewähren.',
    personalAccessTokenUpdated: 'Persönliches Zugriffstoken aktualisiert!',
    personalAccessTokens: 'Persönliche Zugriffstoken',
    regenerate: 'Regenerieren',
    regenerateToken: 'Token regenerieren',
    regenerateTokenTooltip: 'Das Token regenerieren',
    saveYourTokenSecurely: 'Dies ist Ihr persönliches Zugriffstoken mit der Notiz: \'{personalAccessTokenNote}\'. Stellen Sie sicher, dass Sie es sicher speichern, da Sie es nicht mehr einsehen können.',
    scopes: 'Berechtigungen',
    tokenDeleted: 'Persönliches Zugriffstoken gelöscht!',
    tokenDeletedSuccessfully: 'Das persönliche Zugriffstoken wurde erfolgreich gelöscht!',
    tokenRegeneratedSuccessfully: 'Das persönliche Zugriffstoken wurde erfolgreich neu generiert!',
    updateToken: 'Persönliches Zugriffstoken aktualisieren',
  },
}
