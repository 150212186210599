<template>
  <svg v-if="icon" class="svg-icon" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
  <el-icon v-else-if="elIcon"><component :is="elIcon" /></el-icon>
  <component :is="elIconRaw" v-else-if="elIconRaw" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      default: null,
    },
    elIcon: {
      type: String,
      default: null,
    },
    elIconRaw: {
      type: String,
      default: null,
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.icon}`
    },
  },
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
