<template>
  <nested-form-item
    v-if="isExpandOrNested"
    name="deep_nested_item"
    :element-key="elementKey"
    :rule-model="ruleModel"
    :element="element"
    :validation-props="validationProps"
    :original-rule-model="originalRuleModel"
    :selected-connector-auth="selectedConnectorAuth"
    :current-connector="currentConnector"
    :expand-list-index="expandListIndex"
    :class="isEmbed ? 'sfr-embed__form-item': 'sfr-main__form-item'"
    @update-custom-field="$emit('update-custom-field', $event)"
    @rule-model-change="onNestedRuleChange"
    @field-focused="$emit('fieldFocused', $event)"
  />
  <el-form-item
    v-else-if="element && elementName && !isExpandOrNested"
    :ref="element.key"
    :label="elementTitle"
    :prop="validationProps[element.key]"
    :class="isEmbed ? 'sfr-embed__form-item': 'sfr-main__form-item'"
    ref-in-for="true"
  >
    <template #label>
      <label>
        {{ elementTitle }}
        <el-tooltip
          v-if="shouldShowTooltip"
          placement="bottom-end"
        >
          <el-icon class="tooltip-hover"><InfoFilled /></el-icon>
          <template #content>
            <div v-for="tooltipInfo of tooltipInfoObject" :key="tooltipInfo">
              {{ tooltipInfo }}
            </div>
          </template>
        </el-tooltip>
      </label>
      <label v-if="element.custom">
        <el-icon
          style="cursor: pointer;"
          @click="removeCustomField"
        >
          <Delete />
        </el-icon>
        <el-icon
          v-if="element.type !== 'code'"
          style="cursor: pointer;"
          @click="$emit('update-custom-field', elementKey )"
        >
          <Edit />
        </el-icon>
      </label>
    </template>

    <component
      :is="elementName"
      v-bind="$props"
      :expand-list-index="expandListIndex"
      :entry="element"
      :rule-model="ruleModel"
      :element-key="elementKey"
      :element="element"
      :original-rule-model="originalRuleModel"
      :selected-connector-auth="selectedConnectorAuth"
      :current-connector="currentConnector"
      @rule-model-change="onRuleChange"
      @field-focused="$emit('fieldFocused', $event)"
    />
  </el-form-item>
</template>

<script>
import { Delete, Edit, InfoFilled } from '@element-plus/icons-vue'
import { defineAsyncComponent, PropType } from 'vue'
import DateField from '../fields/DateField.vue'
import NumberField from '../fields/NumberField.vue'
import SelectField from '../fields/SelectField.vue'
import SwitchField from '../fields/SwitchField.vue'
import TextField from '../fields/TextField.vue'
import UploadField from '../fields/UploadField.vue'
import * as R from 'ramda'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

/* eslint-disable vue/require-prop-types */
export default {
  name: 'FormItem',
  components: {
    Edit,
    Delete,
    InfoFilled,
    NestedFormItem: defineAsyncComponent(() => import('./NestedFormItem.vue')),
    TextField,
    DateField,
    SelectField,
    SwitchField,
    NumberField,
    UploadField,
    /** Dynamically adds components only if it's NOT an Embed application. */
    ...(() => {
      if (process.env.APPLICATION_TYPE !== 'Embed') {
        return {
          CodeField: defineAsyncComponent(() => import(/* webpackChunkName: "codeField" */'../fields/CodeField.vue')),
          RichTextField: defineAsyncComponent(() => import(/* webpackChunkName: "richTextField" */'../fields/RichTextField.vue')),
        }
      }
      return {}
    })(),
  },
  inject: ['i18n'],
  props: {
    /** @type {PropType<SchemaField>} */
    element: {
      type: Object,
      required: true,
    },
    ruleModel: {
      type: Object,
      required: true,
    },
    elementKey: {
      type: String,
      required: true,
    },
    validationProps: {
      type: Object,
      required: true,
    },
    originalRuleModel: {
      type: Object,
      required: true,
    },
    currentConnector: {
      type: Object,
      required: true,
    },
    selectedConnectorAuth: {
      type: String,
      default: undefined,
    },
    expandListIndex: {
      type: Number,
      default: undefined,
    },
  },
  emits: [
    'ruleModelChange',
    'update-custom-field',
    'remove-custom-field',
    'fieldFocused',
  ],
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
    }
  },
  computed: {
    elementTitle() {
      return translateSchemaItem(this.i18n.locale, this.element.title)
    },
    isExpandOrNested() {
      return this.elementName === 'expanding-list-field' || this.elementName === 'nested-object-field'
    },
    elementName() {
      return this?.element?.elementName
    },
    isExpandedTooltip() {
      return !R.isNil(this.element.info) && Object.hasOwn(this.element.info, 'fieldInfo')
    },
    shouldShowTooltip() {
      return this.isExpandedTooltip ? Object.values(this.element.info).some(infoValue => infoValue) : !!this.element.info
    },
    tooltipInfoObject() {
      const elementInfo = this.isExpandedTooltip ? this.element.info : { fieldInfo: this.element.info }

      const tooltipInfo = {}
      for (const [key, value] of Object.entries(elementInfo)) {
        tooltipInfo[key] = translateSchemaItem(this.i18n.locale, value)
      }

      return tooltipInfo
    },
  },
  methods: {
    removeCustomField() {
      this.$emit('remove-custom-field', this.element.title)
    },
    onRuleChange(newData) {
      const updatedModel = R.clone(this.ruleModel)
      updatedModel[this.elementKey] = newData.value
      this.$emit('ruleModelChange', { value: updatedModel, elementKey: this.elementKey, expandListIndex: this.expandListIndex })
    },
    onNestedRuleChange(newData) {
      const data = newData.value
      this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey, expandListIndex: this.expandListIndex })
    },
  },
}
</script>

<style lang="scss" scoped>

/** Only applied when using the main application */
.sfr-main__form-item {
  :deep(.el-form-item__content) {
    display: block;
  }
}

/** Only applied when using the embed application */
.sfr-embed__form-item {
  :deep(.cm-content) {
    padding: 10px 0px;
  }
}
</style>
