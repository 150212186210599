export const SchemaFormModelTypes = {
  ExpandingList: 'expanding_list',
  NestedObject: 'nested_object',

  Select: 'select',
  Number: 'number',
  Switch: 'switch',

  Text: 'text',
  Password: 'password',
  Email: 'email',

  Date: 'date',
  DateRange: 'daterange',
  DateTime: 'datetime',

  Code: 'code',
  RichText: 'rich_text',

  Upload: 'upload',
}
