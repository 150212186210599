export default {
  add: 'Add',
  clickToUpload: 'Click to upload',
  delete: 'Delete',
  enableRemoteSearchAuthorization: 'Enable authorization for remote search',
  error: {
    error: 'Error',
    expandListSchema: 'There was a problem with the expand_list schema.',
    fetchingRemoteData: 'There was an error fetching remote data',
    unableToProvideSuggestions: 'Unable to provide suggestions',
  },
  form: {
    endDate: 'End date',
    startDate: 'Start date',
    toPreposition: 'To',
  },
  learnMore: 'Learn more...',
  noRemoteSearchResults: 'No results have been found for your search query',
  submit: 'Submit',
  submitRegardless: 'Submit regardless',
  uploadLimit: 'The upload limit is only 1 file per reference.',
  validation: {
    failed: 'Validation failed',
    isRequired: '{property} is required',
    maxLength: '{property} should not have length more than {maxLength}',
    minLength: '{property} should at least have a length of {minLength}',
  },
}
