import { Auth } from '@aws-amplify/auth'
import * as Sentry from '@sentry/vue'
import { userApi } from '@/ui/api/user'
import router from '@/ui/router'
import { loadAllResourcePages } from '@/ui/utils/request'
import refreshSession from './refreshSession'
import fetchUserByEmail from './fetchUserByEmail'
import { permissionApi } from '@/ui/api/permission'
import constructUserName from './constructUserName'

export default {
  namespaced: true,
  state: () => ({
    user: '',
    token: undefined,
    name: '',
    initials: '',
    avatar: '',
    userType: '',
    companyId: '',
    roles: [],
    customData: {},
    companyUsers: [],
  }),
  getters: {
    companyUsers: (state) => state.companyUsers,
    companyUser: (state) => (userId) => state.companyUsers.find((user) => user.id === userId) || {},
    user: (state) => state,
    token: (state) => state.token,
    avatar: (state) => state.avatar,
    userType: (state) => state.userType,
    companyId: (state) => state.companyId,
    name: (state) => state.name,
    status: (state) => state.status,
    roles: (state) => state.roles,
    customUserData: (state) => state.customData,
    initials: (state) => state.initials,
    getShowFunctionReferencePanel: (state) => state.customData.show_reference_panel,
  },
  actions: {
    SetUserCustomData({ commit }, data) {
      commit('SET_USER_CUSTOM_DATA', data)
    },

    async LogOut({ commit, dispatch }) {
      commit('SET_TOKEN', '')
      await dispatch('SetRoles', { roles: [] })
      try {
        await Auth.signOut()
        // Cognito-Amplify logout; AWS is working on it.
        // https://github.com/aws-amplify/amplify-js/issues/3435
      } catch (error) {
        console.log('Error signing out ', error)
      }

      if (router.currentRoute.value.name !== 'Login') {
        await router.replace({
          name: 'Login',
          query: { redirect: router.currentRoute.value.fullPath },
        })
      }
    },

    async SetRoles({ commit, dispatch }, { roles, viewPermissions }) {
      commit('SET_ROLES', roles)
      await dispatch('permission/GenerateRoutes', { roles, viewPermissions }, { root: true })
    },

    async getAuthenticatedUserToken({ dispatch, getters }) {
      if (getters.token) return getters.token

      try {
        const cognitoUser = await Auth.currentAuthenticatedUser()

        await dispatch('SetUser', cognitoUser)
        return getters.token
      } catch (error) {
        // User not authenticated
      }

      return null
    },

    async ConstructUserData({ dispatch, commit, state }) {
      try {
        const isUserLoggedIn = !!(await dispatch('getAuthenticatedUserToken'))
        if (!isUserLoggedIn) return

        await Promise.all([
          permissionApi.getViewsPermissions().then(async response => {
            const viewPermissions = response?.data
            commit('permission/SET_PERMISSIONS', { viewPermissions }, { root: true })
            await dispatch('SetRoles', { roles: [state.userType], viewPermissions })
          }),
          dispatch('company/fetchCompany', state.companyId, { root: true }),
        ])
      } catch (e) {
        Sentry.captureException(e)
        await dispatch('LogOut')
      }
    },

    async SetUser({ commit }, cognitoUser) {
      commit('SET_TOKEN', cognitoUser.signInUserSession.accessToken.jwtToken)
      const name = constructUserName(cognitoUser.attributes)
      const user = await fetchUserByEmail(cognitoUser.username)
      commit('SET_CONSTRUCTED_DATA', {
        ...user,
        name,
      })
      commit('SET_AVATAR', user.image_url)
      commit('SET_USER_TYPE', user.user_type)
      commit('SET_COMPANY_ID', user.company_id)
    },

    async RefreshAccessToken({ dispatch, commit }) {
      try {
        const { tokenData } = await refreshSession()
        commit('SET_TOKEN', tokenData.accessToken.jwtToken)
      } catch (e) {
        Sentry.captureException(e)
        await dispatch('LogOut')
      }
    },

    async fetchCompanyUsers({ dispatch, getters }, userIds) {
      if (!userIds) {
        await dispatch('fetchNewCompanyUsers')
        return
      }

      const uniqueUserIds = new Set(userIds)
      const companyUserIds = new Set(getters.companyUsers.map(user => user.id))
      const newUserIds = [...uniqueUserIds].filter(userId => userId && !companyUserIds.has(userId))

      if (newUserIds.length === 0) return
      await dispatch('fetchNewCompanyUsers', newUserIds)
    },
    async fetchNewCompanyUsers({ commit }, userIds) {
      const items = []
      const addItems = (newItems) => items.push(...newItems)
      const options = { filters: [] }
      if (userIds) {
        options.filters = [['id', 'in', userIds]]
      }
      await loadAllResourcePages(userApi.list, addItems, options)
      commit('SET_COMPANY_USERS', items)
    },

    SetShowFunctionReferencePanel({ commit }, show) {
      commit('SET_SHOW_FUNCTION_REFERENCE_PANEL', show)
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_USER_TYPE: (state, userType) => {
      state.userType = userType
    },
    SET_COMPANY_ID: (state, companyId) => {
      state.companyId = companyId
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_CUSTOM_DATA: (state, model) => {
      state.customData = model
    },
    SET_CONSTRUCTED_DATA: (state, userData) => { // TODO: check if SET_USER_CUSTOM_DATA can be replaced with this
      state.customData = userData
      state.initials = userData.first_name[0] + userData.last_name[0]

      if (userData.name) state.name = userData.name
      if (userData.image_url) state.avatar = userData.image_url
    },
    SET_COMPANY_USERS: (state, users) => {
      const filteredUsers = users.filter((user) => (
        !state.companyUsers.some((companyUser) => companyUser.id === user.id)
      ))

      state.companyUsers = [...state.companyUsers, ...filteredUsers]
    },
    SET_SHOW_FUNCTION_REFERENCE_PANEL: (state, show) => {
      state.customData.show_reference_panel = show
    },
  },
}
