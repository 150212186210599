import { featureApi } from '@/ui/api/feature'

const GET_COMPANY = 'company/getCompany'

export default {
  namespaced: true,
  state: () => ({
    isEmbedV2Enabled: null,
  }),
  getters: {
    isEmbedV2Enabled: (state) => state.isEmbedV2Enabled,
  },
  actions: {
    async fetchEmbedV2EnabledState({ commit, dispatch, rootGetters }) {
      const isCompanyFetched = !!rootGetters[GET_COMPANY]
      if (!isCompanyFetched) {
        await ensureCompanyIsFetched(
          () => rootGetters['company/isFetchingCompany'],
          () => rootGetters['user/companyId'],
          dispatch,
        )
      }

      const currentCompanyId = rootGetters[GET_COMPANY].id
      const isEmbedV2Enabled = await featureApi.isEnabled('embed.v2', { company_id: currentCompanyId })
      commit('SET_EMBED_V2_ENABLED', isEmbedV2Enabled)
    },
    async toggleV2EmbedDefaultStrategy({ commit }, isEnabled) {
      const defaultStrategy = { name: 'default', parameters: { enabled: isEnabled }}
      await featureApi.toggle('embed.v2', [defaultStrategy])
      commit('SET_EMBED_V2_ENABLED', isEnabled)
    },
    async toggleV2EmbedPerCompanyStrategy({ commit, dispatch, rootGetters }, { companyIds, isInverted = false }) {
      const perCompanyStrategy = { name: 'per_company', parameters: { company_ids: companyIds, inverted: isInverted }}
      await featureApi.toggle('embed.v2', [perCompanyStrategy])

      const isCompanyFetched = !!rootGetters[GET_COMPANY]
      if (!isCompanyFetched) {
        await ensureCompanyIsFetched(
          () => rootGetters['company/isFetchingCompany'],
          () => rootGetters['user/companyId'],
          dispatch,
        )
      }

      const currentCompanyId = rootGetters[GET_COMPANY].id
      const isEnabledForCurrentCompany = companyIds.some(companyId => companyId === currentCompanyId)
      commit('SET_EMBED_V2_ENABLED', isEnabledForCurrentCompany)
    },
  },
  mutations: {
    SET_EMBED_V2_ENABLED: (state, isEnabled) => {
      state.isEmbedV2Enabled = isEnabled
    },
  },
}

async function ensureCompanyIsFetched(getIsFetchingCompany, getCompanyId, dispatch) {
  const isFetchingCompany = getIsFetchingCompany()
  if (isFetchingCompany) {
    return waitForCompanyFetch(getIsFetchingCompany)
  }
  return dispatch('company/fetchCompany', getCompanyId(), { root: true })
}

async function waitForCompanyFetch(getIsFetchingCompany) {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      const isFetching = getIsFetchingCompany()
      if (!isFetching) {
        clearInterval(interval)
        resolve()
      }
    }, 100)
  })
}
