import auth from './auth'
import basic from './basic'
import route from './route'
import table from './table'
import flow from './flow'
import connector from './connector'
import dataSource from './dataSource.js'
import flowdebugger from './flowdebugger'
import permission from './permission'
import profile from './profile'
import embedded from './embedded'
import transformation from './transformation'
import { envVariableList } from './envVariableList'
import { envVariableDialog } from './envVariableDialog'

export default {
  auth,
  basic,
  route,
  table,
  flow,
  connector,
  dataSource,
  flowdebugger,
  permission,
  profile,
  embedded,
  transformation,
  entityAction: {
    add: 'Hinzufügen',
    select: 'Auswählen',
    select_tags: 'Tags auswählen',
    select_status: 'Status auswählen',
    search: 'Suchen...',
    open_filters: 'Filter öffnen',
    reset_filters: 'Filter Zurücksetzen',
  },
  envVariableList,
  envVariableDialog,
  show_description: 'Beschreibung',
  company: {
    color: 'Firmenfarben',
    companyNotInStripe: 'Ihr Unternehmen ist nicht in unserem Stripe-Portal registriert. Bitte wenden Sie sich an den Support.',
    contactToActivate: 'Bitte kontaktieren Sie uns, um Ihr Unternehmen für Zahlungen zu aktivieren.',
    details_h1: 'Firmen-Details',
    goToBilling: 'Zum Zahlungsportal gehen',
    logo_upload: 'Firmenlogo hochladen',
    primary_color: 'Primärfarbe',
    primary_color_desc: 'Dies ist die im Firma verwendete Primärfarbe.',
    updatedSuccessfully: 'Firmeninformationen wurden erfolgreich aktualisiert',
  },
  conauth: {
    connector: 'Connector',
    name: 'Name',
    status: 'Status',
    authtype: 'Auth Type',
    authtoken: 'Auth Token',
    authdetails: 'Auth Details',
    apiendpoint: 'Base Domain - Nur zum Überschreiben von Standardendpunkten verwenden',
    apiendpoint_tooltip: 'Beim SQL und FTP Connector wird hier der Host oder DB-Pfad eingegeben',
    credentials_error: 'Die Anmeldedaten konnten nicht erfolgreich verifiziert werden. Bitte überprüfen Sie, ob Sie alles korrekt und in Übereinstimmung mit der verlinkten Dokumentation eingegeben haben',
    timed_out: 'Die Validierung wurde abgebrochen. Dies geschieht in der Regel, wenn ein Ordnerpfad in der URL angegeben wurde oder wenn der Port falsch ist oder fehlt.',
    authorizationCanceled: 'Wir konnten keine Verbindung zu {connectorName} herstellen, da der Autorisierungsprozess abgebrochen wurde. Bitte versuche es erneut.',
    moreDetails: 'Mehr Details',
    forceSave: 'Trotzdem speichern',
    editCredentials: 'Anmeldaten bearbeiten',
  },
  navbar: {
    logOut: 'Logout',
    dashboard: 'Dashboard',
    user: 'Benutzer',
    support: 'Support Center',
    whatsnew: 'What\'s new 🚀',
    systemstatus: 'System-Status',
    theme: 'Theme',
    size: 'Globale Größe',
  },
  login: {
    title: 'Login Formular',
    logIn: 'Login',
    username: 'Username',
    password: 'Passwort',
    any: 'irgendwas',
    thirdparty: 'Third Party',
    thirdpartyTips: 'Sie können nicht lokal simuliert werden, kombinieren Sie also Ihre eigene Simulartionen.',
  },
  documentation: {
    documentation: 'Dokumentation',
    github: 'Repository Github',
    visitForMoreInformation: 'Besuchen Sie die Dokumentation für weitere Informationen',
  },
  components: {
    documentation: 'Documentación',
    tinymceTips: 'Der Rich-Text-Editor ist Teil des Verwaltungssystems, gleichzeitig aber auch ein Ort mit vielen Problemen. Bei der Auswahl von Rich-Texten bin ich auch viele Umwege gegangen. Grundsätzlich werden die gängigen Rich-Text-Editoren auf dem Markt verwendet, und schließlich entschied man sich für Tinymce. In der Dokumentation finden Sie detailliertere Vergleiche und Einführungen im Rich-Text-Editor.',
    dropzoneTips: 'Da mein Unternehmen besondere Bedürfnisse hat und Sie Bilder an mich hochladen müssen, habe ich mich dafür entschieden, sie anstelle eines Dritten einzuschließen. Es ist sehr einfach, Sie können den Detailcode in sehen @/ui/components/Dropzone.',
    stickyTips: 'Wenn die Seite bis zur voreingestellten Position gescrollt wird, bleibt sie oben.',
  },
  errorLog: {
    tips: 'Bitte klicken Sie auf das Bug-Symbol in der oberen rechten Ecke',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Dokumentation',
  },
  excel: {
    export: 'Exportieren',
    selectedExport: 'Auswahl exportieren',
    placeholder: 'Bitte wählen Sie einen Dateinamen',
  },
  zip: {
    export: 'Exportieren',
    placeholder: 'Bitte wählen Sie einen Dateinamen',
  },
  pdf: {
    tips: 'Wir nutzen window.print() um PDFs herunter zu laden.',
  },
  theme: {
    change: 'Theme wechseln',
    documentation: 'Dokumentation des Themas',
    tips: 'Tipps: Es unterscheidet sich von der Motivauswahl auf der Navigationsleiste durch zwei verschiedene Skinning-Methoden mit jeweils unterschiedlichen Anwendungsszenarien. Weitere Informationen finden Sie in der Dokumentation.',
  },
  tagsView: {
    close: 'Schließen',
    closeOthers: 'Andere schließen',
    closeAll: 'Alle schließen',
  },
  settings: {
    title: 'Seiten Style Einstellung',
    theme: 'Theme Farbe',
    tagsView: 'Öffne die Tags-View',
    fixedHeader: 'Sticky Header',
    sidebarLogo: 'Seiten-Menü Logo',
  },
  versioning: {
    title: 'Historie von ',
  },
  user: {
    changeEmail: 'Um die Email-Adresse zu ändern, kontatkieren Sie bitte den Support.',
  },
  autosuggest: {
    noRemoteSearchResults: 'Es wurden keine Ergebnisse für Ihren Suchenbegriff gefunden',
  },
  notifications: {
    errorPersists: 'Wenn das Problem beim erneuten Versuch weiterhin besteht, kontaktieren Sie bitte den Support!',
  },
}
