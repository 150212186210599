const LANGUAGE_KEY = 'language'
const LANGUAGES = {
  de: 'de',
  en: 'en',
}

export {
  LANGUAGE_KEY,
  LANGUAGES,
}
