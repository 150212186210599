import axios from 'axios'

// create an axios instance
const createService = () => axios.create({
  baseURL: process.env.BASE_API,
  timeout: 1000 * 60,
})

export const service = createService()

export const requestGet = (url, {
  withFields = [],
  withTags = [],
  filters = [],
  embed = [],
  page = 1,
  perPage = 20,
  sort = [],
  extra = {},
} = {}) => {
  const params = { page, per_page: perPage }
  if (withFields.length > 0) {
    params.fields = withFields.join(',')
  }
  // TODO: update backend to accept a json serialized array of tags to be included,
  //       just like a regular filter
  if (withTags.length > 0) {
    withTags.forEach(tag => {
      filters.push(['tags', 'includes', tag])
    })
  }
  if (filters.length > 0) {
    const validFilters = filters.filter(
      ([field, op, value]) => field === 'parent_step_id' || (value !== null && value !== undefined),
    ).map(filter => {
      const [field, op, value] = filter
      if (op !== 'like') return filter
      // For the 'like' operation to function properly, we must include %% around the value,
      // otherwise it will be an exact search. TODO: consider moving this logic to the backend.
      return [field, op, `%${value}%`]
    })
    if (validFilters.length > 0) {
      params.filter = JSON.stringify(validFilters)
    }
  }
  if (embed.length > 0) {
    params.embed = embed.join(',')
  }
  if (sort.length > 0) {
    params.sort = sort.map(item => item.join(',')).join(';')
  }
  return service.get(url, { params: { ...params, ...extra }})
}

const withMethod = (method) => (url, requestInfo = {}) => service({ url, method, ...(requestInfo || {}) })
export const requestPost = withMethod('post')
export const requestPut = withMethod('put')
export const requestDelete = withMethod('delete')

export const buildApi = (collectionPath) => ({
  list: options => requestGet(collectionPath, options),
  get: (id, options) => requestGet(`${collectionPath}/${id}`, options),
  create: (data, params = {}) => requestPost(collectionPath, { data, params }),
  update: (data, params = {}) => requestPut(`${collectionPath}/${data.id}`, { data, params }),
  copy: ({ id }, params) => requestPut(`${collectionPath}/${id}/copy`, { params }),
  delete: ({ id }) => requestDelete(`${collectionPath}/${id}`),
})

export async function loadAllResourcePages(fetchFunction, addItems, extraOptions = {}, page = 1) {
  const options = { page, perPage: 200, ...extraOptions }
  let data
  try {
    data = (await fetchFunction(options))?.data || {}
  } catch (error) {
    // Caught globally
  }
  if (!data) return

  addItems(data.data)
  if (data.meta.next_url) {
    await loadAllResourcePages(fetchFunction, addItems, extraOptions, page + 1)
  }
}

export const cancelSourceFactory = {
  create: () => axios.CancelToken.source(),
}

export default service
