<template>
  <el-select
    v-if="options.length"
    v-model="model"
    :placeholder="placeholder"
    :multiple="multiple"
    :filterable="filterable"
    :clearable="clearable"
    :allow-create="allowCreate"
    :default-first-option="defaultFirstOption"
    :reserve-keyword="false"
    :size="isEmbed ? 'large' : 'default'"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import * as R from 'ramda'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

export default {
  name: 'SelectField',
  props: [
    'entry',
    'ruleModel',
    'elementKey',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
  ],
  data() {
    return {
      options: [],
      multiple: false,
      filterable: null,
      clearable: null,
      allowCreate: null,
      defaultFirstOption: null,
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
    }
  },
  computed: {
    placeholder() {
      const { placeholder, enum: selectEnum } = this.entry
      return translateSchemaItem(this.$i18n.locale, placeholder) || selectEnum[0] || ''
    },
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData,
          elementKey: this.elementKey,
          expandListIndex: this.expandListIndex,
        })
      },
    },
  },
  mounted() {
    this.setProps()
  },
  methods: {
    setProps() {
      const { enum: selectEnum, appendableEnum, toLabel, multiple, allowCreate } = this.entry

      if (appendableEnum) {
        this.allowCreate = true
        this.defaultFirstOption = true
        this.multiple = true
      }
      if (selectEnum && selectEnum.length) {
        this.filterable = true
        this.clearable = true
        selectEnum.forEach(option => {
          const label = toLabel ? toLabel(option, this) : option
          this.options.push({ label, value: option })
        })
      }
      if (multiple) {
        this.multiple = true
      }
      if (allowCreate) {
        this.allowCreate = true
      }
    },
    onChangeHandler(value) {
      const updatedModel = R.clone(this.model)
      updatedModel[this.elementKey] = value
      this.model = updatedModel
    },
  },
}
</script>
