<template>
  <el-breadcrumb class="lo-breadcrumb" separator=">">
    <el-breadcrumb-item v-for="(item,index) in breadcrumbs" :key="item.path">
      <span v-if="item.redirect==='noredirect'||index==breadcrumbs.length-1" class="no-redirect">
        <!-- Using an el-tooltip instead of title so users can copy the breadcrumb value should they want to -->
        <el-tooltip
          placement="bottom-start"
          popper-class="lo-breadcrumb-tooltip__popper"
          :content="generateTitle(item.meta.title)"
          :disabled="!item.meta.isDynamicBreadcrumb"
        >
          {{ generateTitle(item.meta.title) }}
        </el-tooltip>
      </span>
      <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { generateTitle } from '@/ui/utils/i18n'
import * as R from 'ramda'

export default {
  name: 'Breadcrumb',
  data() {
    return {
      /** @type {Array<object>} */
      breadcrumbs: [],
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    dynamicBreadcrumbName() {
      return this.$store.state.app.dynamicBreadcrumbName
    },
  },
  watch: {
    $route(newRoute, previousRoute) {
      const hasChangedPage = previousRoute.name !== newRoute.name
      if (hasChangedPage) {
        this.$store.dispatch('app/setDynamicBreadcrumbName', undefined)
        this.setBreadcrumbs()
      }
    },
    dynamicBreadcrumbName(newName) {
      const hasName = !R.isNil(newName)
      if (hasName) {
        this.addDynamicBreadcrumb()
      }
    },
  },
  created() {
    this.setBreadcrumbs()
  },
  methods: {
    generateTitle,
    setBreadcrumbs() {
      // only show routes with meta.title
      const matchedRoutes = this.$route.matched.filter((item) => item.meta && item.meta.title)

      this.breadcrumbs = matchedRoutes.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    /**
     * A dynamic breadcrumb has a different name for the same page. It changes depending on the resource data.
     * We currently only allow a single dynamic breadcrumb per page.
     * */
    addDynamicBreadcrumb() {
      /** We only display the tooltip if it is a dynamic breadcrumb */
      const breadcrumbValue = { meta: { title: this.dynamicBreadcrumbName, isDynamicBreadcrumb: true }}

      const currentDynamicBreadcrumbIndex = this.breadcrumbs.findIndex(breadcrumb => breadcrumb.meta?.isDynamicBreadcrumb)
      const hasCurrentDynamicBreadcrumb = currentDynamicBreadcrumbIndex > -1
      if (hasCurrentDynamicBreadcrumb) {
        this.breadcrumbs[currentDynamicBreadcrumbIndex] = breadcrumbValue
        return
      }

      this.breadcrumbs.push(breadcrumbValue)
    },
    handleLink(item) {
      this.$router.push(this.getBreadcrumbRouteLocation(item))
    },
    getBreadcrumbRouteLocation(breadcrumbItem) {
      if (breadcrumbItem.redirect) {
        breadcrumbItem = breadcrumbItem.redirect
      }
      const { name, path } = breadcrumbItem

      const previouslyVisitedRoute = this.getPreviouslyVisitedRoute(name, path)
      return { name, params: this.$route.params, query: previouslyVisitedRoute?.query }
    },
    /**
     * @param {string} routeName - Route name of clicked breadcrumb, ex: "UseCases"
     * @param {string} routePath - Route path of clicked breadcrumb, ex: "/embed/:embedId/partners/:partnerId/use-cases". Empty by default.
     * @returns Route of a previously visited page by the user, or `undefined` if the route has not been visited yet.
     */
    getPreviouslyVisitedRoute(routeName, routePath = '') {
      /** "/embed/:embedId/partners/:partnerId/use-cases" matches -> `[':embedId', ':partnerId']` */
      const routeParamMatches = routePath.match(/:(\w+)/g) || []
      /** Remove colons to get the keys -> `['embedId', 'partnerId']` */
      const paramKeys = routeParamMatches.map(match => match.slice(1))

      const params = {}
      paramKeys.forEach(key => {
        params[key] = this.$route.params[key]
      })

      return this.visitedViews.find(viewRoute => {
        const hasSameParams = Object.keys(params).every(key => params[key] === viewRoute.params[key])
        return hasSameParams && viewRoute.name === routeName
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .lo-breadcrumb.el-breadcrumb {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    font-weight: 500 !important;
    line-height: 50px;
    margin-left: 8px;
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;

    .no-redirect {
      cursor: text;
    }

    .el-breadcrumb__item {
      max-width: 100%;
    }

    :deep(.el-breadcrumb__inner) {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>

<style>
  /**
    Element-Plus uses an external library called popper.js to generate the tooltip (including its content and the arrow).
    Hence, it is impossible to modify the tooltip's styling using CSS inside the scoped block.
    More information can be found here: https://element-plus.org/en-US/component/tooltip.html#attributes
    There are some cases in which the breadcrumb name can extend up to 512 characters. To prevent the tooltip from taking up
    the entire screen width, we set a max-width, which causes the tooltip content to wrap onto multiple lines,
    ensuring readability.
    In this case, we have set a max-width of 35%. This value can be changed in the future should we want to modify its size.
    Also, since we are modifying the width of the tooltip, we need to manually set the position of the arrow
    (setting the placement to bottom-start is not enough). By default, the arrow will be at the end of the tooltip.
    By using transform, we set it at the beginning of the tooltip with a padding-left of 6px.
  */
  .lo-breadcrumb-tooltip__popper {
    max-width: 35%;
    .el-popper__arrow {
      transform: translate(6px, 0px) !important;
    }
  }
</style>
