export const Colors = {
  defaultPrimaryColor: '#409EFF',
  blue500: '#0044FF',
  blue300Alpha: '#86aeff57',
  blue700Alpha: '#1d80cbb0',
  green500: '#0b0',
  grey100: '#f7f7f7',
  grey300: '#e1e4ea',
  grey500: '#d6d6d6',
  grey700: '#999',
}
