import { setPageTitle } from '@/ui/utils/route'

/**
 * Page title navigation guard.
 * Set our page title after navigation. Page title can be defined per route via `meta`
 * and also later changed by calling setPageTitle() again.
 *
 * @param router
 */
export default (router) => {
  router.afterEach((to) => {
    setPageTitle(to)
  })
}
