import { requestPost } from '@/ui/utils/request'

export const featureApi = {
  isEnabled: async (name, context = {}) => {
    let isEnabled = false
    try {
      const response = await requestPost(`/_internal/feature/${name}/is_enabled`, { data: context })
      isEnabled = response.data.enabled
    } catch (error) {
      // caught globally
    }
    return isEnabled
  },
  /**
   * @typedef {Object} DefaultStrategyParameters
   * @property {boolean} enabled - Whether the strategy is enabled
   * @typedef {Object} CompanyStrategyParameters
   * @property {Array<String>} company_ids - All company ids to which the feature toggle should be enabled
   * @property {boolean} invert - inverts the company ids, i.e. the feature toggle is disabled for all listed company ids (optional)
   *
   * @typedef {Object} Strategy
   * @property {string} name - Name of strategy is either 'default' or 'per_company'
   * @property {DefaultStrategyParameters | CompanyStrategyParameters} parameters - The strategies to be used for this feature
   *
   * @param {String} featureName - The name of the feature
   * @param {Array<Strategy>} strategies - Different strategies to be used
   */
  toggle: async (featureName, strategies) => {
    const postDataObject = {
      name: featureName,
      strategies: strategies,
    }

    const response = await requestPost(`/_internal/feature`, { data: postDataObject })
    return response.data
  },
}
