<template>
  <editor
    ref="editorRef"
    v-model="value"
    v-bind="$attrs"
    :is-input-ui="true"
    :mode="LanguageMode.Json"
  />
</template>

<script>
import Editor from '@/ui/components/Editor/index.vue'
import { LanguageMode } from '@/ui/components/Editor/const'

export default {
  name: 'EditorInput',
  components: {
    Editor,
  },
  inject: ['i18n'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      LanguageMode,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
