export default {
  dialogCreate: 'Create Transformation',
  dialogUpdate: 'Edit Transformation',
  notificationStatusCreat: 'Created!',
  notificationStatusUpdate: 'Updated!',
  notificationMessageCreate: 'Transformation has been created!',
  notificationMessageUpdate: 'Transformation has been updated!',
  validation: {
    nameRequired: 'Name is required',
    lengthRange: 'Length should be 3 to 240',
    minLength: 'Length should be at least 3',
  },
  closeConfirm: 'Do you want to really close?',
}
