import Auth from '@aws-amplify/auth'

export default async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser()
  const { refreshToken } = cognitoUser.signInUserSession
  // eslint-disable-next-line no-return-await
  return await new Promise((resolve, reject) => {
    // Docs say it will only refresh if it is in fact outdated
    cognitoUser.refreshSession(refreshToken, async (err, tokenData) => {
      if (err || !tokenData || !tokenData.accessToken) {
        const error = err || (tokenData
          ? new Error('No valid accessToken in tokenData')
          : new Error('No tokenData received'))

        await Auth.signOut()
        reject(error)
        return
      }
      resolve({ tokenData, cognitoUser })
    })
  })
}
