import i18n from '@/ui/lang'

// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  const hasKey = i18n.global.te(`route.${title}`)

  if (hasKey) {
    return i18n.global.t(`route.${title}`)
  }
  return title
}
