<template>
  <el-input-number
    v-model="model"
    :min="min"
    :max="max"
    :step="step"
    :controls="controls"
    :size="isEmbed ? 'large' : 'default'"
  />
</template>

<script>
/* eslint-disable vue/require-prop-types */

export default {
  name: 'NumberField',
  props: [
    'entry',
    'ruleModel',
    'elementKey',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
  ],
  data() {
    return {
      min: -Infinity,
      max: Infinity,
      step: 1,
      controls: true,
    }
  },
  computed: {
    autocomplete() {
      return this.entry.autocomplete || null
    },
    placeholder() {
      return this.entry.placeholder || ''
    },
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData ?? null,
          expandListIndex: this.expandListIndex,
        })
      },
    },
    isEmbed() {
      return process.env.APPLICATION_TYPE === 'Embed'
    },
  },
  mounted() {
    this.setProps()
  },
  methods: {
    setProps() {
      const { min, max, step } = this.entry

      // eslint-disable-next-line no-restricted-globals
      const parsedMin = isNaN(min) ? null : parseFloat(min)
      // eslint-disable-next-line no-restricted-globals
      const parsedMax = isNaN(max) ? null : parseFloat(max)
      // eslint-disable-next-line no-restricted-globals
      const parsedStep = isNaN(step) ? null : parseFloat(step)

      if (parsedMin) {
        this.min = parsedMin
      }
      if (parsedMax) {
        this.max = parsedMax
      }
      if (parsedStep === 0 || parsedStep === null) {
        this.controls = false
      } else {
        this.step = parsedStep
      }
    },
  },
}
</script>

<style scoped lang="scss">
.el-input {
  height: var(--el-input-height)
}
</style>
