<template>
  <el-collapse
    :model-value="collapseKey"
    class="nested-object-wrapper"
    accordion
  >
    <el-collapse-item :name="element.title" :title="element.title">
      <form-item
        v-for="(value, nestedElementKey) in model[element.key]"
        :key="nestedElementKey"
        :element-key="nestedElementKey"
        :rule-model="model[element.key]"
        :validation-props="validationProps[element.key]"
        :element="element.children[nestedElementKey]"
        :original-rule-model="originalRuleModel"
        :selected-connector-auth="selectedConnectorAuth"
        :current-connector="currentConnector"
        @rule-model-change="onRuleChange"
        @field-focused="$emit('fieldFocused', $event)"
      />
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import * as R from 'ramda'
import FormItem from '@/ui-libs/schema-form-renderer/components/formItems/FormItem.vue'

/* eslint-disable vue/require-prop-types */
export default {
  name: 'CollapseFormItem',
  components: {
    FormItem,
  },
  props: [
    'element',
    'ruleModel',
    'elementKey',
    'validationProps',
    'originalRuleModel',
    'selectedConnectorAuth',
    'currentConnector',
    'expandListIndex',
  ],
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  data() {
    return {
      areaModel: this.element.model[this.elementKey],
    }
  },
  computed: {
    model: {
      get() {
        return this.ruleModel
      },
      set(newData) {
        const data = newData.value || newData
        this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey, expandListIndex: this.expandListIndex })
      },
    },
    collapseKey() {
      return this.elementKey && this.elementKey === 'area' && (this.areaModel?.start || this.areaModel?.end) ? this.element.title : ''
    },
  },
  methods: {
    onRuleChange({ value, elementKey }) {
      const updatedModel = R.clone(this.model)
      updatedModel[this.elementKey][elementKey] = value[elementKey]
      this.model = updatedModel
    },
  },
}
</script>

<style lang="scss" scoped>
.nested-object-wrapper {
  $offset: 15px;

  :deep {
    label {
      font-size: 14px
    }

    .nested-object-fields {
      padding-left: $offset;
    }

    div[role="tabpanel"] {
      margin-left: $offset;

      div[role="tabpanel"] {
        margin-left: calc(2*#{$offset});

        div[role="tabpanel"] {
          margin-left: calc(3*#{$offset});
        }
      }
    }
  }
}
</style>
