import * as R from 'ramda'
import i18n from '@/ui/lang'

export const setPageTitle = (route, { appendTitle } = {}) => {
  let title = ''
  if (appendTitle || route?.meta?.appendTitle) {
    title += `${appendTitle || route.meta.appendTitle} - `
  }
  if (route?.meta?.title) {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    title += `${i18n.global.t(`route.${route.meta.title}`)} @ `
  }
  title += 'Locoia'

  document.title = title
}

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const queryParamsToRequestParams = (queryParams, requestParam) => {
  const newParams = R.clone(queryParams)

  if (newParams.nameSearchString === '') {
    newParams.nameSearchString = null
  }

  if (newParams.searchStepQuery === '') {
    newParams.searchStepQuery = null
  }

  if (newParams.includeEmbedded && typeof newParams.includeEmbedded === 'string') {
    newParams.includeEmbedded = newParams.includeEmbedded === 'true'
  }

  if (newParams.page) {
    newParams.page = Number(newParams.page) || requestParam.page
  }

  if (newParams.perPage) {
    newParams.perPage = Number(newParams.perPage) || requestParam.perPage
  }

  if (newParams.flowRunsPage) {
    newParams.flowRunsPage = Number(newParams.flowRunsPage) || requestParam.flowRunsPage
  }

  if (newParams.flowRunsStepPage) {
    newParams.flowRunsStepPage = Number(newParams.flowRunsStepPage) || requestParam.flowRunsStepPage
  }

  if (newParams.tags) {
    newParams.selectedTags = newParams.tags.split(',')
  }

  const hasflowRunStepsParentsPages = !R.isNil(newParams.flowRunStepsParentsPages) && !R.isEmpty(newParams.flowRunStepsParentsPages)
  if (hasflowRunStepsParentsPages) {
    newParams.flowRunStepsParentsPages = newParams.flowRunStepsParentsPages.split(',').map(Number)
  }

  if (newParams.sortProp || newParams.sortOrder) {
    newParams.sort = {}

    if (newParams.sortProp) {
      newParams.sort.prop = newParams.sortProp
    }

    if (newParams.sortOrder) {
      newParams.sort.order = newParams.sortOrder
    }
  }

  const hasflowRunStatuses = !R.isNil(newParams.flowRunsStatuses) && !R.isEmpty(newParams.flowRunsStatuses)
  if (hasflowRunStatuses) {
    newParams.flowRunsStatuses = newParams.flowRunsStatuses.split(',')
  }

  const hasflowRunStepStatuses = !R.isNil(newParams.flowRunStepsStatuses) && !R.isEmpty(newParams.flowRunStepsStatuses)
  if (hasflowRunStepStatuses) {
    newParams.flowRunStepsStatuses = newParams.flowRunStepsStatuses.split(',')
  }

  const hasFlowRunsStartDateInterval = !R.isNil(newParams.flowRunsStartDateInterval) && !R.isEmpty(newParams.flowRunsStartDateInterval)
  if (hasFlowRunsStartDateInterval) {
    newParams.flowRunsStartDateInterval = newParams.flowRunsStartDateInterval.split(',').map(date => new Date(date))
  }

  const hasflowRunStepsStartDateInterval = !R.isNil(newParams.flowRunStepsStartDateInterval) && !R.isEmpty(newParams.flowRunStepsStartDateInterval)
  if (hasflowRunStepsStartDateInterval) {
    newParams.flowRunStepsStartDateInterval = newParams.flowRunStepsStartDateInterval.split(',').map(date => new Date(date))
  }

  return R.omit(
    [
      'tags',
      'sortProp',
      'sortOrder',
    ],
    { ...requestParam, ...newParams },
  )
}

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const requestParamsToQueryParams = (requestParam) => {
  const queryParams = R.clone(requestParam)

  if (requestParam.nameSearchString === '') {
    delete queryParams.nameSearchString
  }

  if (requestParam.searchStepQuery === '') {
    delete queryParams.searchStepQuery
  }

  if (requestParam.selectedTags && !R.isEmpty(requestParam.selectedTags)) {
    queryParams.tags = requestParam?.selectedTags.join(',')
  }

  if (requestParam.flowRunStepsParentsPages && !R.isEmpty(requestParam.flowRunStepsParentsPages)) {
    queryParams.flowRunStepsParentsPages = requestParam?.flowRunStepsParentsPages.join(',')
  }

  if (R.has('includeEmbedded')(requestParam)) {
    queryParams.includeEmbedded = String(requestParam.includeEmbedded)
  }

  if (requestParam.sort) {
    queryParams.sortProp = requestParam.sort?.prop
    queryParams.sortOrder = requestParam.sort?.order
  }

  if (requestParam.page) {
    queryParams.page = String(requestParam.page) || requestParam.page
  }

  if (requestParam.perPage) {
    queryParams.perPage = String(requestParam.perPage) || requestParam.perPage
  }

  if (requestParam.flowRunsPage) {
    queryParams.flowRunsPage = String(requestParam.flowRunsPage) || requestParam.flowRunsPage
  }

  if (requestParam.flowRunsStepPage) {
    queryParams.flowRunsStepPage = String(requestParam.flowRunsStepPage) || requestParam.flowRunsStepPage
  }

  const hasflowRunsStatuses = !R.isNil(requestParam.flowRunsStatuses) && !R.isEmpty(requestParam.flowRunsStatuses)
  if (hasflowRunsStatuses) {
    queryParams.flowRunsStatuses = requestParam?.flowRunsStatuses.join(',')
  }

  const hasflowRunStepsStatuses = !R.isNil(requestParam.flowRunStepsStatuses) && !R.isEmpty(requestParam.flowRunStepsStatuses)
  if (hasflowRunStepsStatuses) {
    queryParams.flowRunStepsStatuses = requestParam?.flowRunStepsStatuses.join(',')
  }

  const hasFlowRunsStartDateInterval = !R.isNil(requestParam.flowRunsStartDateInterval) && !R.isEmpty(requestParam.flowRunsStartDateInterval)
  if (hasFlowRunsStartDateInterval) {
    queryParams.flowRunsStartDateInterval = requestParam.flowRunsStartDateInterval.join(',')
  }

  const hasFlowRunStepsStartDateInterval = !R.isNil(requestParam.flowRunStepsStartDateInterval) && !R.isEmpty(requestParam.flowRunStepsStartDateInterval)
  if (hasFlowRunStepsStartDateInterval) {
    queryParams.flowRunStepsStartDateInterval = requestParam.flowRunStepsStartDateInterval.join(',')
  }

  return R.omit(['selectedTags', 'sort'], queryParams)
}
