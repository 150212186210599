import * as R from 'ramda'
import { constantRouterMap } from '@/ui/router'
import hasAccess from '@/ui/store/modules/permission/hasAccess'

const filterChildrenRecursively = (roles, viewPermissions) => R.when(
  R.propIs(Array, 'children'),
  route => ({ ...route, children: filterRoutesRecursively(roles, viewPermissions, route.children) }),
)

const filterRoutesRecursively = (roles, viewPermissions, routes) => {
  const accessibleRoutes = routes.filter(route => hasAccess(roles, viewPermissions, route.meta))
  return accessibleRoutes.map(filterChildrenRecursively(roles, viewPermissions))
}

export default (roles, viewPermissions) => R.map(R.partial(filterRoutesRecursively, [roles, viewPermissions]), { constRoutes: constantRouterMap })
