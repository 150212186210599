<template>
  <div class="shortcuts">
    <el-dialog
      :model-value="true"
      :z-index="1000"
      title="Shortcuts"
      :append-to-body="appendToBody"
      class="shortcuts__el-dialog"
      @close="onClose()"
    >
      <div v-if="isAValidOS">
        <div class="shortcuts__div-table">
          <table>
            <tr>
              <th>Shortcut</th>
              <th>{{ operatingSystem }}</th>
            </tr>
            <th colspan="2" class="text-center">Flow Builder</th>
            <tr v-for="shortcut in shortcutsData" :key="shortcut.name">
              <td>{{ shortcut.name }}</td>
              <td>
                <span v-for="command, index in shortcut.commands" :key="command">
                  <code>{{ command }}</code>
                  <span v-if="index !== shortcut.commands.length - 1"> + </span>
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-center">Code Field only</th>
            </tr>
            <tr v-for="codeField in codeFieldData" :key="codeField.name">
              <td>{{ codeField.name }}</td>
              <td>
                <span v-for="command, index in codeField.commands" :key="command">
                  <code>{{ command }}</code>
                  <span v-if="index !== codeField.commands.length - 1"> + </span>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <h2 class="second-header">Further Resources</h2>
      <ul>
        <li><el-link href="https://www.locoia.com/connector/connector-functions" target="_blank">Functions and Spreadsheet-like Formulas...</el-link></li>
        <li><el-link href="https://docs.locoia.com" target="_blank">Help Center ↵</el-link></li>
        <li><el-link href="https://www.youtube.com/channel/UC4WaSwSERFd3K-v7-sik4fw" target="_blank">Youtube How to Video Gallery</el-link></li>
      </ul>
      <template #footer>
        <div>
          <el-button
            data-test="cancel-test"
            size="large"
            @click="onClose()"
          >
            {{ i18n.t('basic.button.close') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { shortcuts, codeFieldOnly, getOperatingSystem } from '../shortcuts'
export default {
  name: 'FlowInfoDialog',
  inject: ['i18n'],
  props: {
    onClose: {
      type: Function,
      required: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogTitle: 'Info',
      operatingSystem: '',
      isAValidOS: false,
      shortcutsData: [],
      codeFieldData: [],
    }
  },
  beforeMount() {
    this.operatingSystem = getOperatingSystem()
    this.isAValidOS = this.operatingSystem === 'Windows' || this.operatingSystem === 'Mac'
    this.shortcutsData = shortcuts[this.operatingSystem]
    this.codeFieldData = codeFieldOnly[this.operatingSystem]
  },
}
</script>

<style lang="scss" scoped>
.shortcuts {
  :deep(.shortcuts__el-dialog) {
    margin-top: 7vh;

    &>.el-dialog__body {
      padding-bottom: 0px;
      padding-top: 10px;
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table th {
  text-align: left;
  padding: 10px;
  border-collapse: collapse;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;

  &[colspan="2"] {
    text-align: center;
  }
}

table td {
  padding: 5px;
  border-collapse: collapse;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  word-wrap: break-word
}

.one-column-th {
  border-top: 0px;
  border-bottom: 0px;
  padding: 10px;
  text-align: center ↵;
}

code {
  display: inline;
  padding: 5px;
}

.second-header {
  margin-top: 25px;
}

.el-link {
  font-size: 15px;
}

.shortcuts__div-table {
  height: 52vh;
  overflow-y: scroll;
}
</style>
