import * as R from 'ramda'
import { validJSON } from '@/ui/utils/validate'

export default {
  namespaced: true,
  state: () => ({
    input: '',
  }),
  getters: {
    isEligible: () => R.T,
    isValid: R.propSatisfies(validJSON, 'input'),
    jsonString: R.prop('input'),
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    SET: (state, subState) => Object.entries(subState).forEach(([key, value]) => (state[key] = value)),
    SET_INPUT: (state, data) => { state.input = data },
  },
}
