<template>
  <el-config-provider :locale="locale">
    <router-view />
    <theme-picker
      v-show="false"
      :theme="companyColor"
      should-trigger-change
    />
  </el-config-provider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { LANGUAGES } from '@/ui/consts'
/* https://github.com/element-plus/element-plus/commit/3ab31b4d607d97355d5acf5887f37d3da0b8ca99
* TODO: This was a breaking change. Go back to using this once the new element-plus version includes an update to this.
import en from 'element-plus/lib/locale/lang/en'
import de from 'element-plus/lib/locale/lang/de'
*/
import en from '../../node_modules/element-plus/lib/locale/lang/en'
import de from '../../node_modules/element-plus/lib/locale/lang/de'
import ThemePicker from '@/ui/views/CompanyPreference/ThemePicker'

export default {
  name: 'App',
  components: {
    ThemePicker,
  },
  computed: {
    ...mapState({
      language: (state) => state.app.language,
    }),
    ...mapGetters({
      company: 'company/getCompany',
    }),
    locale() {
      return this.language === LANGUAGES.de ? de : en
    },
    companyColor() {
      return this.company?.styles?.primaryColor
    },
  },
}
</script>
