export default {
  dashboard: 'Dashboard',
  grid: 'Grid',
  insight: 'Insights',
  transformation: 'Transformations',
  activityLog: 'Activity Log',
  introduction: 'Introduction',
  documentation: 'Documentation',
  help_docs: 'Help & Docs',
  user: 'Users',
  company: 'Company',
  companyProfile: 'Company Profile',
  connector: 'Connector',
  connectorActions: 'Connector Actions',
  connectorAuth: 'Connector Auth',
  flowEnvVariables: 'Flow Env Variables',
  flowBuilder: 'Flow Builder',
  profile: 'Profile',
  permission: 'Permission',
  pagePermission: 'Page Permission',
  directivePermission: 'Directive Permission',
  icons: 'Icons',
  settings: 'Settings',
  dataSources: 'Data Sources',
  myDashboards: 'Dashboards',
  flowMonitoring: 'Flow Monitoring',
  flows: 'Flows',
  flowdebuggerResult: 'Flow Debug Result',
  debugging: 'Debugging',
  flowbuilder: 'Flow Builder',
  flowdebugger: 'Flow Debugger',
  communityLibrary: 'Community Library',
  components: 'Components',
  componentIndex: 'Introduction',
  markdown: 'Markdown',
  jsonEditor: 'JSON Editor',
  dndList: 'Dnd List',
  splitPane: 'SplitPane',
  avatarUpload: 'Avatar Upload',
  dropzone: 'Dropzone',
  sticky: 'Sticky',
  countTo: 'CountTo',
  componentMixin: 'Mixin',
  backToTop: 'BackToTop',
  dragDialog: 'Drag Dialog',
  dragSelect: 'Drag Select',
  dragKanban: 'Drag Kanban',
  example: 'Example',
  nested: 'Nested Routes',
  Table: 'Table',
  dynamicTable: 'Dynamic Table',
  dragTable: 'Drag Table',
  inlineEditTable: 'Inline Edit',
  complexTable: 'Complex Table',
  treeTable: 'Tree Table',
  customTreeTable: 'Custom TreeTable',
  tab: 'Tab',
  form: 'Form',
  createArticle: 'Create Article',
  editArticle: 'Edit Article',
  articleList: 'Article List',
  errorPages: 'Error Pages',
  page401: '401',
  page404: '404',
  errorLog: 'Error Log',
  excel: 'Excel',
  exportExcel: 'Export Excel',
  selectExcel: 'Export Selected',
  uploadExcel: 'Upload Excel',
  zip: 'Zip',
  pdf: 'PDF',
  exportZip: 'Export Zip',
  theme: 'Theme',
  clipboardDemo: 'Clipboard',
  i18n: 'I18n',
  support: 'Support Center',
  fileUploader: 'File Uploader',
  team: 'Team',
  roles: 'Roles',
  embed: 'Embed',
  configurations: 'Configurations',
  integrations: 'Integrations',
}
