export default {
  signIn: {
    header: 'Anmelden bei Locoia',
  },
  signUp: {
    header: 'Registrieren bei Locoia',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    username: 'Nutzername (nochmal Ihre E-Mail)',
    phoneNumber: 'Telefonnummer',
    password: 'Passwort',

  },
  forgotPassword: {
    header: 'Passwort vergessen',
  },
  confirmSignIn: {
    confirmSmsCode: 'SMS-Code bestätigen',
    unknownNumber: 'Unbekannte Nummer',
    verificationCodeSentTo: 'Eine Nachricht mit einem Verifizierungscode wurde an {phoneNumber} gesendet',
  },
  confirmSignUp: {
    header: 'Registrierung bestätigen',
  },
}
