import { snapshotApi } from '@/ui/api/snapshot'

const DefaultPage = 1
const DefaultPerPage = 10

export default {
  namespaced: true,
  state: () => ({
    visible: false,
    resourceModel: null,
    resourceName: null,
    resourceId: null,
    snapshots: [],
    totalSnapshots: 0,
    requestParams: {
      page: DefaultPage,
      perPage: DefaultPerPage,
    },
    requestInProgress: false,
    newlyCreatedCopyId: null,
  }),
  actions: {
    async open({ commit, state, dispatch }, { resourceModel, resourceId, resourceName }) {
      commit('SET_REQUEST_IN_PROGRESS', true)

      const options = {
        withFields: ['created_date', 'id', 'user_id'],
        sort: [['created_date', 'desc']],
        page: state.requestParams.page,
        perPage: state.requestParams.perPage,
      }
      let data
      try {
        data = (await snapshotApi.list(resourceModel, resourceId, options))?.data
      } catch (error) {
        // caught globally
      }
      const userIds = data?.data?.map(version => version.user_id)
      await dispatch('user/fetchCompanyUsers', userIds, { root: true })
      commit('SET', {
        visible: true,
        resourceModel,
        resourceId,
        resourceName,
        requestParams: {
          page: data.meta.page,
          perPage: data.meta.per_page,
        },
        snapshots: data.data,
        totalSnapshots: data.meta.total,
      })

      commit('ADD_VERSION_NUMBER_TO_SNAPSHOTS')
      commit('SET_REQUEST_IN_PROGRESS', false)
    },
    async recreateResource({ commit }, snapshot) {
      try {
        commit('SET_REQUEST_IN_PROGRESS', true)
        const { data: { id }} = await snapshotApi.recreateFrom(snapshot)
        commit('SET_NEWLY_CREATED_COPY_ID', id)
      } catch {
        // caught globally
      } finally {
        commit('SET_REQUEST_IN_PROGRESS', false)
      }
    },
    async restoreResource({ commit }, snapshot) {
      try {
        commit('SET_REQUEST_IN_PROGRESS', true)
        await snapshotApi.restoreTo(snapshot)
      } catch {
        // caught globally
      } finally {
        commit('SET_REQUEST_IN_PROGRESS', false)
      }
    },
    async updateRequestParams({ commit, dispatch, state }, requestParams) {
      /* We do not check for 'page' changes because UI Element ElPagination automatically sets the requestParams 'page' to be the updated value. */

      const hasPerPageChanged = requestParams?.perPage !== state.requestParams.perPage
      if (hasPerPageChanged) {
        commit('SET_PER_PAGE', requestParams.perPage)
      }

      await dispatch('open', { resourceModel: state.resourceModel, resourceId: state.resourceId })
    },
    close({ commit }) {
      commit('SET_VISIBLE', false)
      commit('SET_PAGE', DefaultPage)
      commit('SET_PER_PAGE', DefaultPerPage)
    },
  },
  mutations: {
    // eslint-disable-next-line no-return-assign
    SET: (state, subState) => Object.entries(subState).forEach(([key, value]) => (state[key] = value)),
    SET_REQUEST_IN_PROGRESS(state, value) {
      state.requestInProgress = value
    },
    SET_VISIBLE(state, value) {
      state.visible = value
    },
    SET_NEWLY_CREATED_COPY_ID(state, value) {
      state.newlyCreatedCopyId = value
    },
    SET_PAGE(state, value) {
      state.requestParams.page = value
    },
    SET_PER_PAGE(state, value) {
      state.requestParams.perPage = value
    },
    ADD_VERSION_NUMBER_TO_SNAPSHOTS(state) {
      for (let index = 0; index < state.snapshots.length; index++) {
        const snapshot = state.snapshots[index]
        const snapshotIndex = state.totalSnapshots - index
        const pagesOffset = ((state.requestParams.page - 1) * state.requestParams.perPage)
        snapshot.versionNumber = snapshotIndex - pagesOffset
      }
    },
  },
}
