/**
 * Hash navigation guard.
 * We switched from hash-based navigation to history (/flowbuilder instead of /#/flowbuilder).
 * This navigation guard will allow us to still support hash links and redirect them to history ones.
 *
 * @param router
 */
export default (router) => {
  router.beforeEach((to, from, next) => {
    let redirect

    if (to.hash) {
      const path = to.fullPath.split('#')
      path.shift()
      redirect = {
        path: path.join('#'),
        query: to.query,
        replace: true,
      }
    }

    next(redirect)
  })
}
