<template>
  <div class="loadable-container">
    <div v-if="isLoading" v-loading="isLoading" class="loadable-container">
      <slot name="placeholder" />
    </div>
    <slot v-else>
      <span>The loader expects content to display when loading is finished</span>
    </slot>
  </div>
</template>

<script>
/** Wrapped in a div in case the LoadableContent component is used as top level element.
Top level elements of a router view cannot have a `v-if`, otherwise the routing gets messed up. */
export default {
  name: 'LoadableContent',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.loadable-container {
  height: inherit;
}
</style>
